import React from "react";

function MontraApp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="#20336B" rx="4"></rect>
      <path
        fill="#4ABCEB"
        stroke="#4ABCEB"
        d="M11.283 15.98l.172-.783 1.104-.163-.205.945h-1.071zM17.003 15.967l.325-1.587 1.08-.128-.399 1.715h-1.006z"
      ></path>
      <mask id="path-4-inside-1_3809_13483" fill="#fff">
        <path d="M5.004 16.404l2.257-.763-.165.826H5.004v-.063z"></path>
      </mask>
      <path
        fill="#4ABCEB"
        d="M5.004 16.404l2.257-.763-.165.826H5.004v-.063z"
      ></path>
      <path
        fill="#4ABCEB"
        d="M5.004 16.404l-.32-.948-.68.23v.718h1zm2.257-.763l.98.196.339-1.697-1.64.554.32.947zm-.165.826v1h.82l.16-.804-.98-.196zm-2.092 0h-1v1h1v-1zm.32.884l2.257-.762-.64-1.895-2.257.762.64 1.895zm.956-1.906l-.165.826 1.962.392.164-.826-1.961-.392zm.816.022H5.004v2h2.092v-2zm-1.092 1v-.063h-2v.063h2z"
        mask="url(#path-4-inside-1_3809_13483)"
      ></path>
      <mask
        id="mask0_3809_13483"
        style={{ maskType: "luminance" }}
        width="15"
        height="10"
        x="5"
        y="7"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M19.048 13.695c-.728.077-1.441.158-2.14.243l.67-3.145c.07-.31.106-.59.106-.841 0-.894-.54-1.341-1.621-1.341h-1.622l-1.243 5.836c-.751.117-1.473.24-2.164.37l1.32-6.206H9.288c-.368 0-.587.179-.659.536l-1.38 6.532c-.852.24-1.606.497-2.249.77l1.669-7.838c.118-.56.32-.97.605-1.227C7.56 7.128 8.004 7 8.611 7h8.004c.986 0 1.774.197 2.362.59.589.394.883 1.002.883 1.826 0 .346-.024.62-.072.822l-.74 3.457z"
        ></path>
        <path
          stroke="#fff"
          d="M19.048 13.695c-.728.077-1.441.158-2.14.243l.67-3.145c.07-.31.106-.59.106-.841 0-.894-.54-1.341-1.621-1.341h-1.622l-1.243 5.836c-.751.117-1.473.24-2.164.37l1.32-6.206H9.288c-.368 0-.587.179-.659.536l-1.38 6.532c-.852.24-1.606.497-2.249.77l1.669-7.838c.118-.56.32-.97.605-1.227C7.56 7.128 8.004 7 8.611 7h8.004c.986 0 1.774.197 2.362.59.589.394.883 1.002.883 1.826 0 .346-.024.62-.072.822l-.74 3.457z"
        ></path>
      </mask>
      <g mask="url(#mask0_3809_13483)">
        <path fill="#fff" d="M67.886 22.845H2.84V4.713h65.046v18.132z"></path>
        <path
          stroke="#4ABCEB"
          d="M67.886 22.845H2.84V4.713h65.046v18.132z"
        ></path>
      </g>
    </svg>
  );
}

export default MontraApp;
