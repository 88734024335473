import React from 'react'

const Body = () => {
  return (
    <>
   <div className="personal-page">
    <div className='chat-body'>
    <div className="payment-body-section-5">
            <h1>Send and receive money during your chat</h1>
            <div>
              <div className="row">
                <div className="col">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="33" viewBox="0 0 36 33" fill="none">
                    <g clip-path="url(#clip0_638_17592)">
                        <mask id="mask0_638_17592"  maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="33">
                        <path d="M36 0H0V33H36V0Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_638_17592)">
                        <path d="M2.33594 13.5005C3.37866 9.81493 6.5812 7.35172 10.1369 7.50053H14.2907C15.6366 7.50053 17.0599 8.25053 17.0599 9.75053V10.5005C17.0599 12.0005 15.6753 12.7505 14.2907 12.7505H11.5215C11.5215 12.7505 10.1369 18.0005 7.36763 18.0005" stroke="#20336B"/>
                        <path d="M19.136 30H8.75142C6.89265 30.0009 5.11207 29.1897 3.8125 27.75" stroke="#20336B"/>
                        <path d="M15.6719 21H24.6719C25.8189 21 26.7488 22.0074 26.7488 23.25C26.7488 24.4926 25.8189 25.5 24.6719 25.5H19.1334" stroke="#20336B"/>
                        <path d="M15.6719 25.5H19.1334C20.2804 25.5 21.2103 26.5074 21.2103 27.75C21.2103 28.9926 20.2804 30 19.1334 30H17.0565" stroke="#20336B"/>
                        <path d="M33.6734 9H16.9141" stroke="#20336B"/>
                        <path d="M8.75 7.617V5.25C8.75 4.00736 9.67987 3 10.8269 3H31.5962C32.7432 3 33.6731 4.00736 33.6731 5.25V18.75C33.6731 19.9926 32.7432 21 31.5962 21H10.8269C9.67987 21 8.75 19.9926 8.75 18.75V17.532" stroke="#20336B"/>
                        <path d="M30.9038 12H26.75" stroke="#00BAF2"/>
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_638_17592">
                        <rect width="36" height="33" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                  <h6>Make Payment</h6>
                  <p>Make payment to the person you are chatting.</p>
                </div>
                <div className="col">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <g clip-path="url(#clip0_639_17621)">
                    <mask id="mask0_639_17621"  maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
                    <path d="M36 0H0V36H36V0Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_639_17621)">
                    <path d="M30.6333 29.6912C30.6333 31.906 28.9394 33.7015 26.8499 33.7015H5.28356C3.19395 33.7015 1.5 31.906 1.5 29.6912V11.645C1.5 9.43022 3.19395 7.63477 5.28356 7.63477" stroke="#20336B"/>
                    <path d="M30.5391 17.5039L30.6349 29.0998" stroke="#20336B" stroke-linecap="square" stroke-linejoin="round"/>
                    <path d="M5.33594 7.63477H20.6693" stroke="#20336B" stroke-linecap="square"/>
                    <path d="M30.6351 13.5043L30.2812 2.22461" stroke="#20336B" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M26.4141 8.8125L30.3429 13.3789C30.4156 13.4634 30.5203 13.5117 30.6304 13.5117C30.7404 13.5117 30.8451 13.4634 30.9178 13.3789L34.8474 8.8125" stroke="#20336B" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.25 18.75H23.25" stroke="#00BAF2" stroke-linecap="square"/>
                    <path d="M8.25 21.75H23.25" stroke="#00BAF2" stroke-linecap="square"/>
                    <path d="M20.25 26.25H18.75L12.75 14.25H11.1562V26.25" stroke="#00BAF2" stroke-linecap="square"/>
                    <path d="M20.3438 26.25V14.25" stroke="#00BAF2" stroke-linecap="square"/>
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_639_17621">
                    <rect width="36" height="36" fill="white"/>
                    </clipPath>
                </defs>
                </svg>
                  <h6>Instant Loan Disbursements</h6>
                  <p>Request Payment from the person you are chatting with.</p>
                </div>
                
              </div>
              <div className="row">
                <div className="col">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M27 18.774V19.329V24.3645L27.516 24.7185C27.7314 24.9058 27.9957 25.0277 28.2779 25.07C28.5602 25.1123 28.8487 25.0732 29.1095 24.9573C29.3703 24.8414 29.5926 24.6535 29.7503 24.4156C29.9081 24.1778 29.9947 23.8999 30 23.6145V20.25C30.7956 20.25 31.5587 19.9339 32.1213 19.3713C32.6839 18.8087 33 18.0456 33 17.25V6.75C33 5.95435 32.6839 5.19129 32.1213 4.62868C31.5587 4.06607 30.7956 3.75 30 3.75H13.5C12.7043 3.75 11.9413 4.06607 11.3787 4.62868C10.8161 5.19129 10.5 5.95435 10.5 6.75V8.25H13.5C13.5 7.85217 13.658 7.47064 13.9393 7.18934C14.2206 6.90803 14.6022 6.75 15 6.75H28.5C28.8978 6.75 29.2794 6.90803 29.5607 7.18934C29.842 7.47064 30 7.85217 30 8.25V15.75C30 16.56 29.3565 17.223 28.5 17.25C27.885 17.274 27.4395 17.3745 27.234 17.589C27.0458 17.786 27.0306 18.1155 27.0018 18.7356L27 18.774Z" fill="#00BAF2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27 18.774C26.9952 18.959 26.9952 19.144 27 19.329V18.774Z" fill="black"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.484 30.7185C8.26863 30.9058 8.0043 31.0277 7.72205 31.07C7.4398 31.1123 7.15134 31.0732 6.89053 30.9573C6.62973 30.8414 6.4074 30.6535 6.24966 30.4156C6.09192 30.1778 6.00531 29.8999 6 29.6145V26.25C5.20435 26.25 4.44129 25.9339 3.87868 25.3713C3.31607 24.8087 3 24.0456 3 23.25V12.75C3 11.9543 3.31607 11.1913 3.87868 10.6287C4.44129 10.0661 5.20435 9.75 6 9.75H22.5C23.2956 9.75 24.0587 10.0661 24.6213 10.6287C25.1839 11.1913 25.5 11.9543 25.5 12.75V23.25C25.5 24.0456 25.1839 24.8087 24.6213 25.3713C24.0587 25.9339 23.2956 26.25 22.5 26.25H15.0045L8.484 30.7185ZM10.14 25.9755C10.0209 26.0426 9.88668 26.0782 9.75 26.079C9.55109 26.079 9.36032 26 9.21967 25.8593C9.07902 25.7187 9 25.5279 9 25.329C9.0045 25.0395 9.0045 24.8685 9 24.774L8.99707 24.7137C8.96765 24.1077 8.95193 23.7836 8.766 23.589C8.5605 23.3745 8.115 23.274 7.5 23.25C6.6435 23.223 4.5 24.3645 6 21.75V14.25C6 13.8522 6.15803 13.4706 6.43934 13.1893C6.72064 12.908 7.10217 12.75 7.5 12.75H21C21.3978 12.75 21.7794 12.908 22.0607 13.1893C22.342 13.4706 22.5 13.8522 22.5 14.25V21.75C22.5 22.1478 22.342 22.5294 22.0607 22.8107C21.7794 23.092 21.3978 23.25 21 23.25H14.529C14.2228 23.25 13.9239 23.3437 13.6725 23.5185L10.14 25.9755Z" fill="#20336B"/>
                </svg>
                  <h6>Get Payment Confirmation</h6>
                  <p>Both parties receive confirmation once the payment is successful.</p>
                </div>
                <div className="col">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 33.7226C6.00007 34.0317 6.18638 34.3103 6.472 34.4284C6.75762 34.5464 7.08623 34.4806 7.30449 34.2619L9.05502 32.5113L11.5693 35.0256C11.7126 35.1692 11.9071 35.25 12.11 35.25C12.3129 35.25 12.5075 35.1692 12.6508 35.0256L15.1651 32.5113L17.6793 35.0256C17.8226 35.1692 18.0172 35.25 18.2201 35.25C18.423 35.25 18.6175 35.1692 18.7608 35.0256L21.2751 32.5113L23.7894 35.0256C23.9326 35.1692 24.1273 35.25 24.3301 35.25C24.533 35.25 24.7276 35.1692 24.8708 35.0256L27.3851 32.5113L29.1356 34.2619C29.3539 34.4806 29.6825 34.5464 29.9681 34.4284C30.2538 34.3103 30.4401 34.0317 30.4401 33.7226V2.27735C30.4401 1.96829 30.2538 1.68973 29.9681 1.57168C29.6825 1.45362 29.3539 1.51932 29.1356 1.73814L27.3851 3.48866L24.8708 0.974382C24.7276 0.830732 24.533 0.75 24.3301 0.75C24.1273 0.75 23.9326 0.830732 23.7894 0.974382L21.2751 3.48866L18.7608 0.974382C18.6175 0.830732 18.423 0.75 18.2201 0.75C18.0172 0.75 17.8226 0.830732 17.6793 0.974382L15.1651 3.48866L12.6508 0.974382C12.5075 0.830732 12.3129 0.75 12.11 0.75C11.9071 0.75 11.7126 0.830732 11.5693 0.974382L9.05502 3.48866L7.30449 1.73814C7.08623 1.51932 6.75762 1.45362 6.472 1.57168C6.18638 1.68973 6.00007 1.96829 6 2.27735L6 33.7226Z" stroke="#20336B" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5781 9.59766H19.7432" stroke="#00BAF2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5781 14.1836H16.6882" stroke="#00BAF2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5781 18.7656H18.2157" stroke="#00BAF2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5781 23.3477H15.1607" stroke="#00BAF2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.3281 9.59766H25.8556" stroke="#00BAF2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.3281 14.1836H25.8556" stroke="#00BAF2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.3281 18.7656H25.8556" stroke="#00BAF2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.3281 23.3477H25.8556" stroke="#00BAF2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                  <h6>View Transaction Receipt</h6>
                  <p>Click on the Payment Confirmation received in the Chat to see Transaction Receipt.</p>
                </div>
                
              </div>
            </div>
          </div>
    </div>
   </div>
    
    </>
  )
}

export default Body