import React from "react";

function YU8() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="331"
      height="151"
      fill="none"
      viewBox="0 0 331 151"
    >
      <path
        className='yu-path'
        stroke="url(#paint0_radial_12_279)"
        strokeWidth="1.5"
        d="M0 64.968h38c5.833.395 17.4-2.723 17-18.354-.4-15.63-.167-23.683 0-29.012 0-4.737 4.291-16.578 15.5-16.578 11.5 0 19 .001 21.5 0h223c5-.266 15 1.559 15 10.98V28.6"
        pathLength="1"
      ></path>
      <path
        className='yu-path'
        stroke="url(#paint1_radial_12_279)"
        strokeWidth="1.5"
        d="M0 78.003h32c8 0 23-.96 23 31.097 0 14.021-.167 21.236 0 26.014 0 4.247 4.291 14.865 15.5 14.864H315c5 .239 15-1.397 15-9.845V127"
        pathLength="1"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_12_279"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 32.1187 -46 0 46 32.882)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96F"></stop>
          <stop offset="1" stopColor="#13EAE2"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial_12_279"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 -28.7995 46 0 46 121.412)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96F"></stop>
          <stop offset="1" stopColor="#15E9E2"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU8;
