import React from "react";
import Moneylinklogo from "../../../assets/images/moneylink.svg";
import Moneylinkplatform from "../../../assets/images/moneylinkimg.svg";
import Moneylinkimg from "../../../assets/images/moneylinkplatform.svg";

const Main = () => {
  return (
    <>
      <div className="moneylink">
        <div className="row">
          <div className="col">
            <h6>Solutions</h6>
            <img className="moneylink-logo" src={Moneylinklogo} alt="image" />
            <h1 className="money-h1">
              Modern Fintech Platform that <b>empowers innovators</b>
            </h1>
            <p>
              Modern Card Issuing platform, POS acquiring platform, lending &
              banking platform connecting banks, fintechs, and businesses with
              custom APIs for a wide range of financial services
            </p>
            <button>
              Explore MoneyLink Platforms <i class="bi bi-chevron-right"></i>
            </button>
            <img className="direction-img" src={Moneylinkimg} alt="image" />
          </div>
          <div className="col moneylink-img-img">
            <img src={Moneylinkplatform} alt="image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
