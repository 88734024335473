import React from "react";

function YU6() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="480"
      height="202"
      fill="none"
      viewBox="0 0 480 202"
    >
      <path
        className='yu-path'
        stroke="url(#paint0_radial_3447_7992)"
        strokeWidth="1.5"
        d="M0 1h222.5c4.667.5 14 3.7 14 12.5V111m0 90.5V111m243.5 0H236.5"
        pathLength="1"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_3447_7992"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 100.25 -240 0 240 101.25)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0CC2FF"></stop>
          <stop offset="1" stopColor="#8CF2B2"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU6;
