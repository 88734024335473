import React from "react";
import dts1 from "../../../../assets/images/dts1.svg";
import dwp3 from "../../../../assets/images/dwp3.svg";
import "./style.css";

const Body = () => {
  return (
    <>
      <div className="dcp-body act-body">
        <div className="dwp-row">
          <div className=" dts-frame act-body-1">
            <div className="dts-frame-img">
              <img src={dts1} alt="" />
            </div>
            <div className="dts-frame-header">
              <h6>Advantage Issuers</h6>
            </div>
            <div className="duo-lists dts-list">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    DUO activation service makes it super easy for pre-approved
                    customers to activate the DUO Credit Card in just few
                    seconds using DUO App or any other Digital Channel from the
                    Issuer that is integrated to DUO Platform.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    DUO Activation service digitize the overall customer
                    experience where the customer is able to see the eligible
                    Credit Limit, Terms & Conditions for the DUO Credit Card
                    being offered by the Issuer. Once customer consents to the
                    T&C, DUO credit card is activated.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col act-img">
            <img src={dwp3} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
