import React from "react";
import act1 from "../../../../assets/images/act1.svg";
import act2 from "../../../../assets/images/act2.svg";
import "./style.css";

const Hero = () => {
  return (
    <>
      <div className="dts-hero act-hero">
        <div className="row">
          <div className="col">
            <div className="dwp-sm-btn act-sm-btn">
              <img src={act2} alt="" />
              <p>Activation</p>
            </div>
            <h1>
              <span>Instant Card Activation Service from DUO</span> makes DUO
              Credit Card Activations easy
            </h1>
            <p>
              DUO white label App or DUO App SDK (that can be integrated with
              Issuer’s Mobile Banking App) offers DUO Credit Card activation
              service using which customers can easily Activate their
              pre-approved DUO Cards in few seconds. Our APIs for Activation
              Service are also available for integration with Issuer’s USSD, ATM
              or any other digital channel.
            </p>

            <button>Contact Sales</button>
          </div>
          <div className="col">
            <img src={act1} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
