import React from 'react'
import dts1 from "../../../../assets/images/dts1.svg";
import dwp3 from "../../../../assets/images/dwp3.svg";
import './style.css';

const Body = () => {
  return (
    <>
    <div className="dcp-body dwp-body">
        <div className="dwp-row">
          <div className=" dts-frame ">
            <div className="dts-frame-img">
              <img src={dts1} alt="" />
            </div>
            <div className="dts-frame-header">
              <h6>Advantage Issuers</h6>
            </div>
            <div className="duo-lists dts-list">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                  DUO onboarding app is implemented with integration to Issuer’s systems such that it seamlessly gathers all income, account balance, debt exposure, delinquency data and further gather credit burau data to under-write the customers based on the approved Risk Acceptance Criteria for this product program. This avoids manual processes that are otherwise used to gather such data for manual underwriting which can often be erroneous.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                  Further DUO Onboarding app creates mirror accounts on Issuer side digitally before the relevant data can be submitted for onboarding the approved customers on DUO Platform.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                  Issuer can access to Portfolio dashboard and even download detailed customer wise report. This provides details related to transactor vs revolver behavior, delinquency data , revenue earned for all income lines at MTD & YTD levels.
                  </p>
                </div>
              </div>
              
            </div>
          </div>
          <div className="col co-img">
            <img src={dwp3} alt='' />
          </div>
        </div>
    </div>
    
    </>
  )
}

export default Body