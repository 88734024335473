import React from "react";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
import personalhero1 from '../../../assets/images/personalhero1.png';
import personalhero2 from '../../../assets/images/personalhero2.png';
import dashboard from "../../../assets/images/dashboard.png"

const Hero = () => {
  return (
    <>
      <div className="personal-hero">
        <div className="row">
          <div className="col personal-hero-left">
            <h1>Powering <span>Digital Payments</span> & <span>Lending</span> for Everyone</h1>
            <p>Marketplaces for multiparty payments & Lending Products</p>
            <div className="app-download">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
          </div>
          <div className="col personal-hero-right">
          <img src={dashboard} alt="image" />
              
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
