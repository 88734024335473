import React from "react";
import map from "../../../assets/images/map.svg";
import countries from "../../../assets/images/map-countries.svg";

const Body = () => {
  return (
    <>
      <div className="our-story-map">
        <h1>Our Location</h1>
        <div className="story-map">
          <img src={map} alt="Our Story Map" />
          <img src={countries} alt="Our Story Map countries" />
        </div>
      </div>
    </>
  );
};

export default Body;
