import React from "react";

function DistributionCompany() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="25"
      fill="none"
      viewBox="0 0 26 25"
    >
      <path
        stroke="#223872"
        strokeWidth="0.7"
        d="M18.705 1.604h0c-.003-.434-.16-.774-.5-1.03h0c-.123-.091-.255-.13-.452-.19l-.08-.023a.651.651 0 01-.155.018c-.034 0-.065-.003-.085-.004h-.003a27437.575 27437.575 0 01-14.967 0H2.46c-.02.001-.052.004-.085.004a.663.663 0 01-.137-.014l16.467 1.24zm0 0c.004.493.004.986.004 1.481v.056c0 .46 0 .92.003 1.381a70.685 70.685 0 00-1.623 0c.005-.485.004-.97.002-1.452v-.352h0c-.001-.184-.024-.426-.2-.601-.176-.175-.418-.196-.601-.196H3.62c-.172 0-.417.014-.592.192-.174.176-.188.42-.188.594V19.66c0 .097.005.2.027.297.023.1.07.214.167.307a.62.62 0 00.309.154c.096.02.2.024.3.024h1.73c2.283 0 4.567 0 6.851-.005m6.481-18.834l-6.48 18.834m0 0c-.008.338-.007.69-.005 1.037 0 .284.002.564-.002.83l.006-1.867zM1.444.834c.14-.186.374-.341.782-.465l9.991 21.935c-.008.498-.006 1.043.178 1.592H2.514h0c-.425 0-.74-.134-.947-.342-.208-.207-.342-.522-.342-.944V1.7c0-.418.081-.682.22-.866zm.999-.636c0-.002 0-.001 0 0h0zm7.513 20.67h0a1.291 1.291 0 00-1.292 1.288v.001c-.004.73.565 1.31 1.295 1.31.707 0 1.294-.575 1.305-1.283.011-.716-.593-1.316-1.308-1.316z"
      ></path>
      <path
        stroke="#3FAFE9"
        strokeWidth="0.7"
        d="M15.705 6.466h3.154c1.577 0 3.154 0 4.732-.004m-7.886.004l7.886.346v-.35m-7.886.004c-.261 0-.518.024-.756.148l.756-.148zm7.886-.004c.794-.001 1.29.38 1.537 1.117M23.59 6.462v.35s0 0 0 0m1.537.767l-10.6 15.326c0-.048-.003-.093-.004-.13v-.004a2.503 2.503 0 01-.003-.103v-3.655c0-3.659.001-7.318-.003-10.977 0-.278.045-.498.135-.673.088-.17.228-.319.457-.439.163-.084.351-.108.595-.108h3.153c1.578 0 3.156 0 4.733-.004m1.537.767l-.332.11a2.513 2.513 0 00-.018-.05m.35-.06h-.35v.06m-1.187-.827c.34 0 .585.08.766.213.172.127.315.323.42.613m-1.186-.826l-8.013 17.083c2.714.007 5.43.006 8.143 0m1.057-16.257v15.428a2.262 2.262 0 00-.104.226.746.746 0 01-.096.18l-.002.002c-.227.283-.507.42-.855.421m0 0l.002.35m-.002-.35s0 0 0 0l.002.35m0 0c.457-.001.835-.19 1.126-.552.072-.088.116-.19.159-.292l-1.285.844zm-4.509-2.794l-3.045-.001c-.097 0-.198-.004-.291-.022a.595.595 0 01-.309-.154.587.587 0 01-.16-.308 1.457 1.457 0 01-.022-.289V8.366c0-.093.005-.192.025-.284a.59.59 0 01.16-.3.596.596 0 01.298-.153c.092-.02.191-.024.284-.024h6.986c.097 0 .2.003.293.022a.59.59 0 01.313.162.6.6 0 01.154.312c.018.094.022.197.022.296v12.265c0 .098-.004.201-.022.295a.593.593 0 01-.16.313.597.597 0 01-.312.158 1.567 1.567 0 01-.297.022h-3.037c.45.176.772.609.78 1.117l-1.66-1.116zm0 0c-.452.177-.78.619-.781 1.13-.002.669.558 1.22 1.224 1.217.664-.001 1.23-.566 1.218-1.23l-1.66-1.117z"
      ></path>
      <mask id="path-3-inside-1_3809_13186" fill="#fff">
        <path d="M17.642 0c-.067.083-.162.046-.244.046H2.464c-.074 0-.16.036-.218-.046h15.396z"></path>
      </mask>
      <path
        fill="#C8D0DB"
        d="M17.642 0l.545.44.918-1.14h-1.463V0zm-.244.046v-.7.7zm-14.934 0v-.7.7zM2.246 0v-.7H.896L1.674.403 2.246 0zm14.85-.44a.56.56 0 01.413-.206c.03 0 .05.003.025 0-.01 0-.071-.008-.136-.008v1.4c-.023 0-.043-.003-.009 0 .018.002.081.01.153.008a.838.838 0 00.644-.315l-1.09-.878zm.302-.214H2.464v1.4h14.934v-1.4zm-14.934 0c-.065 0-.128.008-.133.008-.023.003.003-.001.04 0a.579.579 0 01.447.243L1.674.403a.821.821 0 00.653.35c.075.003.14-.005.156-.007.033-.004.01 0-.019 0v-1.4zM2.246.7H17.641V-.7H2.248V.7z"
        mask="url(#path-3-inside-1_3809_13186)"
      ></path>
    </svg>
  );
}

export default DistributionCompany;
