import React from "react";
import payment from "../../../assets/images/payment.svg";
import dpb from "../../../assets/images/dpb.svg";
import api from "../../../assets/images/api.svg";
import lending from "../../../assets/images/lending.svg";
import investment from "../../../assets/images/investment.svg";
import insurtech from "../../../assets/images/insurtech.svg";
import digital from "../../../assets/images/digital.svg";


const Hero = () => {
  return (
    <>
      <div className="company-hero">
        <div className="company-hero-first-part">
          <div className="company-hero-1">
            <div className="money-trans">
              <div className="money-trans-1">
                <p>Mon</p>
                <p>tra</p>
              </div>
              <div className="money-trans-2">
                <p>ey</p>
                <p>nsfer</p>
                <h6>made easy</h6>
              </div>
            </div>

            <h1>Building future of an Inclusive Financial Ecosystem</h1>
          </div>
          <div className="company-hero-2">
            <img src={payment} alt="" />
          </div>
        </div>
        <div className="company-hero-second-part">
          <div className="service">
            <div className="service-icon">
              <img src={dpb} alt="" />
            </div>
            <p>Digital Payment & Banking</p>
            <div className="service-box service-box-hero">
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>P2P, P2B & B2B payments</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Merchant acquiring </p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Dual Transaction Service</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Digital Banking & Agency Banking</p>
                </div>
              </div>
            </div>
          </div>
          <div className="service">
            <div className="service-icon">
              <img src={api} alt="" />
            </div>
            <p>API Platforms</p>
            <div className="service-box service-box-hero">
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Card Issuing & Processing</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Account Management System</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Merchant Acquiring System</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Loan Management System</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Debt Collection system</p>
                </div>
              </div>
            </div>
          </div>
          <div className="service">
            <div className="service-icon">
              <img src={lending} alt="" />
            </div>
            <p>Lending</p>
            <div className="service-box service-box-hero">
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Loan aggregator</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Loan Origination</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Loan Collections</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Loan Management</p>
                </div>
              </div>
            </div>
          </div>
          <div className="service">
            <div className="service-icon">
              <img src={investment} alt="" />
            </div>
            <p>investment</p>
            <div className="service-box service-box-hero">
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Fixed Deposit</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Mutual Funds</p>
                </div>
              </div>
              
            </div>
          </div>
          <div className="service">
            <div className="service-icon">
              <img src={insurtech} alt="" />
            </div>
            <p>Insurtech</p>
            <div className="service-box service-box-hero">
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Ter Life Plans</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Health Insurance</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Endowment Plans</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Car Insurance</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Travel Insurance</p>
                </div>
              </div>
            </div>
          </div>
          <div className="service">
            <div className="service-icon">
              <img src={digital} alt="" />
            </div>
            <p>Digital Verification</p>
            <div className="service-box service-box-hero">
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Authenticator App</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>Liveness and Facematch</p>
                </div>
              </div>
              <div className="service-text">
                <div className="service-box-box"></div>
                <div className="service-box-text">
                  <p>KYC Verification</p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
