import React, { useState } from "react";
import Navbar from "../Navbar/Main";
import { HashLink as Link } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import moneylinklogo from "../../assets/images/moneylinklogo.svg";
import "./platform.css";

const Solution = () => {
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);

  const handleButtonClick = () => {
    setSubMenuVisible(!isSubMenuVisible);
  };

  const handleSubMenuClick = (e) => {
    e.stopPropagation();
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setSubMenuVisible(false);
    }, 3000);
  };

  return (
    <>
      <div className="product-nav">
        <Navbar />
      </div>

      <div className="second-navbar navbar">
        <div className="solutions-logo">
          <img src={moneylinklogo} alt="" />
        </div>

        <div className="nav-link plat-nav-link">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink
                to="/Platform/Moneylink/cip"
                className="nav-link"
                activeClassName="plat-active"
              >
                Card Issuing & Processing
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Platform/Moneylink/merchant"
                className="nav-link"
                activeClassName="plat-active"
              >
                Merchant Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Platform/Moneylink/Loanmanagement"
                className="nav-link"
                activeClassName="plat-active"
              >
                Loan Management System
              </NavLink>
            </li>
            <li className="nav-item">
              <button
                style={{ color: "#000" }}
                className="more"
                onClick={handleButtonClick}
                onMouseEnter={() => setSubMenuVisible(true)}
                onMouseLeave={handleMouseLeave}
              >
                More
              </button>
              {isSubMenuVisible && (
                <div
                  className="submenu"
                  style={{ position: "absolute" }}
                  onClick={handleSubMenuClick}
                  onMouseEnter={() => setSubMenuVisible(true)}
                  onMouseLeave={handleMouseLeave}
                >
                  {/* Your submenu content goes here */}
                  <NavLink
                    to="/Platform/Moneylink/Debtcollections"
                    className="nav-link"
                    activeClassName="plat-active"
                  >
                    Debt Collection
                  </NavLink>
                  <NavLink
                    to="/Platform/Moneylink/Accountmanagement"
                    className="nav-link"
                    activeClassName="plat-active"
                  >
                    Account Management System
                  </NavLink>
                </div>
              )}
              {/* <NavLink to="/Products/Finance" className="nav-link" activeClassName="plat-active">
          More
          </NavLink> */}
            </li>

            <li className="nav-item">
              <button className="plat-button">
                Partner Sign in <i className="bi bi-chevron-right"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Solution;
