import React from "react";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
import mockup1 from "../../../assets/images/mockup1.svg";
import mockup2 from "../../../assets/images/mockup2.svg";
const Main = () => {
  return (
    <>
      <div className="row home-hero">
        <div className="col home-hero-left">
          <p>
            Single app to manage all your <b>Personal</b> & <b>Business</b>{" "}
            finances
          </p>
          <span>
            Simply download Montra app to set-up your Montra Personal Account to
            access Marketplaces for multiparty payments & Lending Products
          </span>
          <div className="app-download">
            <img src={appstore} alt="image" />
            <img src={playstore} alt="image" />
          </div>
        </div>
        <div className="col mockups">
          <img src={mockup1} alt="image" />
          <img src={mockup2} alt="image" />
        </div>
      </div>
    </>
  );
};

export default Main;
