import React from "react";
import dwp1 from "../../../../assets/images/dwp1.svg";
import dwp2 from "../../../../assets/images/dwp2.svg";
import "./style.css";

const Hero = () => {
  return (
    <>
      <div className="dts-hero dwp-hero">
        <div className="row">
          <div className="col duo-col">
            <div className="dwp-sm-btn">
              <img src={dwp2} alt="" />
              <p>DUO Web Portal</p>
            </div>
            <h1 className="dwp-h1-h1">
              DUO Web Portal enables customer onboarding, portfolio management,
              and reporting for issuers
            </h1>
            <p>
              A user-based interface that offers role-based access for various
              reports such as: Admin Maker, Admin Checker, Product, Business,
              CRM, Sales, Settlement, Contact Center, Operations & Finance
              users.
            </p>
            <div className="duo-list-container">
              <div className="duo-lists">
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                    Upload new pre-approved customers for Onboarding
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                    View portfolio dashboard & reports
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                    Collection Reports
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                    Transaction Analysis for Daily, MTD, YTD transactions
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>Customer Activation Report for Daily, MTD, YTD activations</p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>Sales dashboard & reports</p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>Settlement & reconciliation reports</p>
                  </div>
                </div>
              </div>
            </div>

            <button>Contact Sales</button>
          </div>
          <div className="col duo-col">
            <img src={dwp1} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
