import React from "react";

function LoanOfferAcceptance() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#20336B"
        d="M16.574 23.997H2.86a2.86 2.86 0 01-2.857-2.856V2.857A2.86 2.86 0 012.86 0h8.833a2.87 2.87 0 012.044.857l4.878 4.993a2.85 2.85 0 01.814 2v4.257a.571.571 0 01-1.142 0V7.851c0-.449-.176-.88-.49-1.2L12.92 1.657a1.717 1.717 0 00-1.226-.514H2.861a1.714 1.714 0 00-1.714 1.714V21.14a1.714 1.714 0 001.714 1.714h13.713c.215 0 .429-.041.628-.123a.572.572 0 01.435 1.057c-.338.138-.699.21-1.063.208z"
      ></path>
      <path
        fill="#20336B"
        d="M18.623 7.428h-3.765A2.86 2.86 0 0112 4.572V.749a.572.572 0 011.143 0v3.823a1.714 1.714 0 001.714 1.714h3.765a.571.571 0 110 1.142zm-.908 16.487c-.226 0-.446-.067-.633-.192L15.246 22.5a8.55 8.55 0 01-3.816-7.129v-.978a1.148 1.148 0 01.88-1.111 17.096 17.096 0 004.786-2.16 1.143 1.143 0 011.238 0 17.087 17.087 0 004.785 2.16 1.148 1.148 0 01.88 1.111v.978a8.552 8.552 0 01-3.816 7.132l-1.835 1.223c-.187.124-.408.19-.633.19zm0-11.833a18.229 18.229 0 01-5.129 2.31l-.014.978a7.411 7.411 0 003.308 6.18l1.835 1.222 1.834-1.223a7.414 7.414 0 003.308-6.179v-.978a18.284 18.284 0 01-5.142-2.31z"
      ></path>
      <path
        fill="#24BAF2"
        d="M17.146 18.855a.571.571 0 01-.404-.168L15.6 17.544a.57.57 0 01.808-.808l.738.74 1.882-1.882a.571.571 0 01.808.808l-2.286 2.285a.572.572 0 01-.404.168zM8.576 5.713H4.005a.571.571 0 010-1.143h4.57a.571.571 0 110 1.143zm3.428 3.428H4.005a.571.571 0 010-1.142h8a.571.571 0 110 1.142zm1.143 3.428H4.005a.571.571 0 110-1.142h9.142a.571.571 0 110 1.143zm-3.428 3.429H4.005a.571.571 0 110-1.143h5.714a.571.571 0 010 1.143zm0 3.428H4.005a.571.571 0 110-1.143h5.714a.571.571 0 010 1.143z"
      ></path>
    </svg>
  );
}

export default LoanOfferAcceptance;
