import React from 'react'
import dts1 from "../../../../assets/images/dts1.svg";
import dwp3 from "../../../../assets/images/dwp3.svg";
import './style.css';

const Body = () => {
  return (
    <>
    <div className="dcp-body dwp-body">
        <div className="dwp-row">
          <div className=" dts-frame dwp-app-1">
            <div className="dts-frame-img">
              <img src={dts1} alt="" />
            </div>
            <div className="dts-frame-header">
              <h6>Advantage Customers</h6>
            </div>
            <div className="duo-lists dts-list">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                  Issuer can upload the pre-approved customers on DUO Platform for DUO Virtual Credit Card issuance. This eliminates manual process of data sharing that could be erroneous besides this being completely secure.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                  Issuer can track Daily, Monthly or for any time range Issuance, Activations, Transactions data.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                  Issuer can access to Portfolio dashboard and even download detailed customer wise report. This provides details related to transactor vs revolver behavior, delinquency data , revenue earned for all income lines at MTD & YTD levels.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                  Issuer can onboard its sales staff for them to help customers activate DTS when they visit branches through an OTP based validation process.
                  </p>
                </div>
              </div>

              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                  Issuer’s settlement team can access all transaction related data and use reconciliation capability of DUO for managing all reconciliation & settlement processes.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                  Issuer’s contact center teams can also access DUO portal to manage customer calls in context to activation
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col dwp-img">
            <img src={dwp3} alt='' />
          </div>
        </div>
    </div>
    
    </>
  )
}

export default Body