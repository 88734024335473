import React from "react";
import moneylinkfooter from '../../assets/images/moneylinkfooter.svg';
import logo from "../../assets/images/montralogo.svg";
import './moneylink.css';

const Main = () => {
  return (
    <>
      <section className="footer">
        <div className="footer-box">
          <div className="row footer-boxes">
            <div className="col">
              <div className="download-montra moneylink-footer1">
                <p>Modern Fintech Platform <span>that empowers</span> innovators</p>
                

                <button>Contact Sales</button>
              </div>
            </div>
            <div className="col">
              <img src={moneylinkfooter} alt="" />
            </div>
          </div>
        </div>

        <div className="row footer-links">
          <div className="col-4 footer-first-section">
            <img src={logo} alt="image" />
            <p>
              <i class="bi bi-cursor-fill"></i> Montra, Inc. (United States)
            </p>
            <p>
              Montra introduces innovative products & solutions that promote
              Financial Inclusion across emerging economies towards transition
              from cash to digital payments & making accessibility to Credit
              easy for everyone
            </p>
          </div>
          <div className="col-3">
            <h6>Products</h6>
            <span>Personal</span>
            <ul>
              <li>Payment</li>
              <li>Banking</li>
              <li>Lending</li>
              <li>Insurance</li>
              <li>Investment</li>
              <li>Shopping</li>
            </ul>

            <span>Business</span>
            <ul>
              <li>Payment</li>
              <li>Banking</li>
              <li>Lending</li>
              <li>Invoicing</li>
              <li>Inventory Management</li>
              <li>Montra Store</li>
            </ul>

            <span>Payment Gateway</span>
            <ul>
              <li>Pay with Card</li>
              <li>Pay with Banks</li>
              <li>Pay with QR</li>
              <li>Pay with Montra</li>
              <li>Pay with Loan</li>
              <li>Pricing</li>
            </ul>
          </div>
          <div className="col-3">
            <h6>Solutions</h6>
            <span>Duo</span>
            <ul>
              <li>Dual Transaction Service - DTS</li>
              <li>Duo Card Product - DCP</li>
              <li>DUO App</li>
              <li>DUO Web Portal</li>
              <li>DUO Customer Onboarding</li>
              <li>Limit Management</li>
              <li>Collections</li>
            </ul>

            <span>Verified</span>
            <ul>
              <li>Authenticator App</li>
              <li>Face Authentication</li>
              <li>KYC ID Verification</li>
              <li>Video KYC Solution</li>
            </ul>
            <h6>Platforms</h6>
            <span>MoneyLink</span>
            <ul>
              <li>Card Issuing</li>
              <li>Loan Management System</li>
              <li>Salary Processing</li>
              <li>Account Management</li>
              <li>Supply Chain Managemen</li>
            </ul>
          </div>
          <div className="col-2">
            <h6>Company</h6>
            <ul>
              <li>About Montra</li>
              <li>Jobs</li>
              <li>Montra Press</li>
              <li>Become a Partner</li>
            </ul>

            <h6>Resources</h6>
            <ul>
              <li>Demo Montra App</li>
              <li>Explainer Video</li>
              <li>Developers</li>
            </ul>

            <h6>Help</h6>
            <ul>
              <li>FAQ</li>
              <li>Support</li>
            </ul>

            <h6>Contact</h6>
            <ul>
              <li>hi@montra.ng</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
