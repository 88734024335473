import "./style.css";
import tapPay from "../../../assets/images/tap-pay.svg";
import tapPay2 from "../../../assets/images/tap-pay-2.svg";
import tapPayVar1 from "../../../assets/images/tap-pay-var1.svg";
import tapPayVar2 from "../../../assets/images/tap-pay-var2.svg";

const Hero = () => {
  return (
    <div className="hero">
      <div>
        <h1>
          Accept in-person payments with <b>only an Android device</b>
        </h1>
        <p>
          Give your customers the ability to accept contactless payments in
          person using your Montra App, directly on NFC enabled Android devices.
          With Tap to Pay solution, you can accept contactless payments from any
          physical cards—no extra hardware required.
        </p>
        <div>
          <img src={tapPay2} alt="tap and pay diagram" />
        </div>
        <button className="cta-blue">Contact Sales</button>
      </div>
      <div className="mobile-animation">
        <img src={tapPay} alt="tap and pay" />
        <img src={tapPayVar1} alt="tap and pay" />
        <img src={tapPayVar2} alt="tap and pay" />
      </div>
    </div>
  );
};

export default Hero;
