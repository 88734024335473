import React from 'react'
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
import agencyhero1 from '../../../assets/images/agencyhero1.svg';
import agencyhero2 from '../../../assets/images/agencyhero2.svg';

const Hero = () => {
  return (
    <>
    <div className="business-agency">
        <div className="row">
          <div className="col">
            <h1>
            Earn more by opening a <span>Montra Agent Account</span> & start offering <span>Agency Banking Services</span>
            </h1>
            <p>
            You can register on Montra Business to become a Montra Agent. Already registered Merchants on Montra Business can also become Montra Agent by adding one more business account
            </p>
            <img src={agencyhero1} alt="" />
            <div className="app-download">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
          </div>
          <div className="col business-agency-2">
            <img src={agencyhero2} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero