import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import appstore from "../../assets/images/appstoreblack.png";
import playstore from "../../assets/images/playstore.png";
import logo from "../../assets/images/montralogo.svg";

const Main = () => {
  return (
    <>
      <section className="footer">
        <div className="footer-box">
          <div className="row footer-boxes">
            <div className="col footer-box1">
              <div className="download-montra">
                <p>Download Montra</p>
                <span>Select on preferred app device to download Montra</span>

                <div className="app-download">
                  <img src={playstore} alt="image" />
                  <img src={appstore} alt="image" />
                </div>
              </div>
            </div>
            <div className="col footer-box2">
              <p>Start Innovating with Montra as your partner today</p>
              <span>Select on preferred app device to download Montra</span>
              <button>Contact us</button>
            </div>
          </div>
        </div>

        <div className="row footer-links">
          <div className="col-4 footer-first-section">
            <img src={logo} alt="image" />
            <p>
              <i class="bi bi-cursor-fill"></i> Montra, Inc. (United States)
            </p>
            <p>
              Montra introduces innovative products & solutions that promote
              Financial Inclusion across emerging economies towards transition
              from cash to digital payments & making accessibility to Credit
              easy for everyone
            </p>
          </div>
          <div className="col-3">
            <h6>Products</h6>
            <span>Personal</span>
            <ul>
              <Link
                smooth
                to="/Products/Payment"
                className="nav-link main-active"
              >
                <li>Payment</li>
              </Link>
              <Link
                smooth
                to="/Products/Banking"
                className="nav-link main-active"
              >
                <li>Banking</li>
              </Link>
              <Link
                smooth
                to="/Products/Finance"
                className="nav-link main-active"
              >
              <li>Lending</li>
              </Link>
              <Link
                smooth
                to="/Products/Finance"
                className="nav-link main-active"
              >
              <li>Insurance</li>
              </Link>
              <Link
                smooth
                to="/Products/Finance"
                className="nav-link main-active"
              >
              <li>Investment</li>
              </Link>
              <Link
                smooth
                to="/Products/Shop"
                className="nav-link main-active"
              >
              <li>Shopping</li>
              </Link>
            </ul>

            <span>Business</span>
            <ul>
            <Link
                smooth
                to="/Business/Payment"
                className="nav-link main-active"
            >
              <li>Payment</li>
            </Link>
            <Link
                smooth
                to="/Business/Banking"
                className="nav-link main-active"
            >
              <li>Banking</li>
            </Link>
            <Link
                smooth
                to="/Business/Banking"
                className="nav-link main-active"
            >
              <li>Lending</li>
            </Link>
            <Link
                smooth
                to="/Business/overview"
                className="nav-link main-active"
            >
              <li>Invoicing</li>
            </Link>
            <Link
                smooth
                to="/Business/more/Inventory"
                className="nav-link main-active"
            >
              <li>Inventory Management</li>
              </Link>
            <Link
                smooth
                to="/Business/overview"
                className="nav-link main-active"
            >
              <li>Montra Store</li>
            </Link>
            </ul>

            <span>Payment Gateway</span>
            <ul>
              <li>Pay with Card</li>
              <li>Pay with Banks</li>
              <li>Pay with QR</li>
              <li>Pay with Montra</li>
              <li>Pay with Loan</li>
              <li>Pricing</li>
            </ul>
          </div>
          <div className="col-3">
            <h6>Solutions</h6>
            <span>Duo</span>
            <ul>
            <Link
                smooth
                to="/Solutions/Duo/Dts"
                className="nav-link main-active"
            >
              <li>Dual Transaction Service - DTS</li>
            </Link>
              <li>Duo Card Product - DCP</li>
              <li>DUO App</li>
              <li>DUO Web Portal</li>
              <li>DUO Customer Onboarding</li>
              <li>Limit Management</li>
              <li>Collections</li>
            </ul>

            <span>Verified</span>
            <ul>
              <li>Authenticator App</li>
              <li>Face Authentication</li>
              <li>KYC ID Verification</li>
              <li>Video KYC Solution</li>
            </ul>
            <h6>Platforms</h6>
            <span>MoneyLink</span>
            <ul>
              <li>Card Issuing</li>
              <li>Loan Management System</li>
              <li>Salary Processing</li>
              <li>Account Management</li>
              <li>Supply Chain Managemen</li>
            </ul>
          </div>
          <div className="col-2">
            <h6>Company</h6>
            <ul>
              <li>About Montra</li>
              <li>Jobs</li>
              <li>Montra Press</li>
              <li>Become a Partner</li>
            </ul>

            <h6>Resources</h6>
            <ul>
              <li>Demo Montra App</li>
              <li>Explainer Video</li>
              <li>Developers</li>
            </ul>

            <h6>Help</h6>
            <ul>
              <li>FAQ</li>
              <li>Support</li>
            </ul>

            <h6>Contact</h6>
            <ul>
              <li>hi@montra.ng</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
