import { useState } from "react";
import duohome1 from "../../../../assets/images/duohome1.svg";
import cardinsurance from "../../../../assets/images/cardinsurance.svg";
import activation from "../../../../assets/images/activation.svg";
import mirror from "../../../../assets/images/mirror.svg";
import app from "../../../../assets/images/app.svg";
import switching from "../../../../assets/images/switching.svg";
import webportal from "../../../../assets/images/webportal.svg";
import collection from "../../../../assets/images/collection.svg";
import dynamic from "../../../../assets/images/dynamic.svg";
import customer from "../../../../assets/images/customer.svg";
import issuers from "../../../../assets/images/issuers.svg";

const Body = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleImageClick = () => {
    setIsVideoPlaying(true);
  };

  const [isVideoPlayingDP, setIsVideoPlayingDP] = useState(false);

  const handleImageDPClick = () => {
    setIsVideoPlayingDP(true);
  };

  const [isVideoPlayingIssuers, setIsVideoPlayingIssuers] = useState(false);

  const handleImageIssuersClick = () => {
    setIsVideoPlayingIssuers(true);
  };
  return (
    <>
      <div className="duo-body">
        <h1>
          DUO offers end to end Credit Card Product, Card Issuance, Transaction
          Processing, Risk & Portfolio management services
        </h1>
        <div className="duo-home-body">
          <div className="duo-list-container">
            <img src={duohome1} alt="" />
            <h6>Onboarding</h6>
            <div className="duo-lists">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Our consulting team works with Issuer’s Risk & Product
                    Management teams to help them finalize the underwriting
                    parameters / Risk Acceptance Criteria for the DUO Credit
                    Card program based on Issuer’s risk appetite
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Further our Onboarding Application digitize the onboarding
                    process such that Issuer uploads the customers for scoring
                    them.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Our Onboarding application gathers all the data from
                    Issuer’s system and credit Bureaus to under-write these
                    customers.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    For those customers who are pre-approved are onboarded
                    digitally on DUO platform for issuance of a Virtual (DTS) or
                    Physical DUO card (DCP)
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="duo-list-container">
            <img src={cardinsurance} alt="" />
            <h6>DUO Card Issuance</h6>
            <div className="duo-lists">
              <div className="duo-list">
                <div>
                  <p>
                    Once the pre-approved cards are onboarded on DUO Platform,
                    DUO helps the Credit Card Processor (which could be our own
                    Moneylink card issuance platform or any other card processor
                    of Issuer’s choice) issue virtual or physical DUO Cards
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="duo-list-container">
            <img src={activation} alt="" />
            <h6>Activation</h6>
            <div className="duo-lists">
              <div className="duo-list">
                <div>
                  <p>
                    DUO white label App or DUO App SDK (that can be integrated
                    with Issuer’s Mobile Banking App) offers DUO Credit Card
                    activation service using which customers can easily Activate
                    their pre-approved DUO Cards in few seconds. Our APIs for
                    Activation Service are also available for integration with
                    Issuer’s USSD, ATM or any other digital channel.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="duo-list-container">
            <img src={switching} alt="" />
            <h6>Transaction Switching</h6>
            <p>
              DUO platform offers Transaction Switching Service which involves
              following steps:
            </p>
            <div className="duo-lists">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Customer request for Credit Account usage through a Debit
                    Card from any POS or ATM
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Issuer upon receiving such transaction request,
                    authenticates the PIN and send the transaction to DUO
                    Platform
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    DUO validates if this Debit Card is eligible for DTA (Dual
                    Transaction Service) and also if the customer has previously
                    activated this service.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Once the DTS activation is confirmed, DUO Platform switches
                    the transaction to DUO Virtual Card and send it for
                    processing to the processor where this DUO Credit Card is
                    hosted.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="duo-list-container">
            <img src={cardinsurance} alt="" />
            <h6>Transaction Processing</h6>
            <div className="duo-lists">
              <div className="duo-list">
                <div>
                  <p>
                    Our Moneylink Card Issuing platform is available for card
                    Issuance & Transaction Processing. DUO platform is already
                    integrated to Moneylink Card Management System for seamless
                    DUO Credit Card issuance & transaction processing
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="duo-list-container">
            <img src={mirror} alt="" />
            <h6>Transaction Mirroring</h6>
            <div className="duo-lists">
              <div className="duo-list">
                <div>
                  <p>
                    Once the transaction is processed by the Card Processor, for
                    those transactions that are Approved and for all FEE
                    transactions, DUO Platform sends the transaction to Issuer
                    for mirroring so that the same can be Debited to mirror
                    account & is also accounted for on the product GL.
                    Similarly, when the customers make a repayment against the
                    due outstanding amounts or any amount that is collected on
                    the Issuer side, DUO platform mirrors it on the card
                    Processor side.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="duo-list-container">
            <img src={app} alt="" />
            <h6>Onboarding</h6>
            <p>
              DUO App comes with a white label App that Issuer can launch, or
              Issuer can integrate DUO App SDK with its Mobile Banking App to
              offer following services seamlessly:
            </p>
            <div className="duo-lists">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>Credit Card Activation</p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Access all details related to your Credit Limit, Available
                    balance, Minimum repayment due, Repayment due date, Card &
                    Payment Status.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>Receive transaction alerts on the DUO App</p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>Get repayment reminder notifications</p>
                </div>
              </div>

              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Make repayments easily against your credit card outstanding.
                  </p>
                </div>
              </div>

              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Access the details of the Debit Cards that are linked to Duo
                    Credit Card. If customer has been issued a new Debit Card,
                    customer can use PAN Remapping service to link instantly
                    link the new Debit Card with DUO Credit Card.
                  </p>
                </div>
              </div>

              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Place requests to upgrade to Duo Card Product (DCP), a dual
                    chip card with two chips, one each for Debit & Credit Card.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="duo-list-container">
            <img src={webportal} alt="" />
            <h6>DUO Web Portal</h6>
            <p>
              DUO Web Portal is a user base interface that offers role-based
              access (Admin Maker, Admin Checker, Product, Business, CRM, Sales,
              Settlement, Contact Center, Operations & Finance users) to
              Issuer’s staff for accessing various reports besides digitizing
              customer onboarding & activation processes:
            </p>
            <div className="duo-lists">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>Upload new pre-approved customers for Onboarding</p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>View portfolio dashboard & reports</p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>Collection Reports</p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>Transaction Analysis for Daily, MTD, YTD transactions</p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Customer Activation Report for Daily, MTD, YTD activations
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>Sales dashboard & reports</p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>Settlement & reconciliation reports</p>
                </div>
              </div>
            </div>
          </div>

          <div className="duo-list-container">
            <img src={collection} alt="" />
            <h6>DUO Collection</h6>
            <p>DUO collection application helps issuer:</p>
            <div className="duo-lists">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Collect repayments requested by customer through DUO App
                    such that their linked Bank Account is debited, and credit
                    Card mirror account is Credited and the same is mirrored to
                    Credit Card Account on card processor’s side.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Track for all repayments made voluntarily by customer
                    against the minimum due amount for recent cycle and collect
                    the balance available in customer’s linked bank account at
                    the EOD of the due date.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Collect from any account that customer has in the bank for
                    the delinquent amount. Collection application keeps track of
                    delinquent outstanding amount as well as the available
                    balances in all inked accounts such that it can help Issuer
                    collect from the customer efficiently.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="duo-list-container">
            <img src={dynamic} alt="" />
            <h6>Dynamic Limit Management (DLM)</h6>
            <p>
              DUO Dynamic Limit Management helps Issuers manage Risk in the
              following steps:
            </p>
            <div className="duo-lists">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    DLM application scores every customer onboarded every month
                    such that those who have not activated the Credit Card yet
                    may remain eligible for the offering or may be flagged as
                    NOT ELIGIBLE for card activations based on the most recent
                    results. DLM repeats this every month for all the customers
                    onboarded by Issuer on DUO Platform.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    In case an Active credit card is found NOT ELIGIBLE based on
                    the revised score, Credit Processor is asked to instantly
                    block the card. Similarly, if a blocked that was previously
                    blocked is found ELIGIBLE in the following month, DLM ask
                    Card Processor to un-block such cards.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    DLM assess the Credit Limit of all the customers onboarded
                    on DUO Platform based on revised & recent data inputs. Where
                    the revised Credit Limit has reduced, DLM ask Credit
                    Processor to reduce the credit limit for such DUO Credit
                    Cards. Where DLM finds that revised Credit Limit is higher
                    than the existing limit, the same is queued to RIS user for
                    approval on DUO Web portal.
                  </p>
                </div>
              </div>

              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    DLM therefore helps Issuers manage risk dynamically by
                    taking rule-based decisions seamlessly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="duo-body2">
        <h1>Dynamic Limit Management (DLM)</h1>
        <div className="duo-body-2">
          <div className="">
            {/* <img src={customer} alt="" /> */}
            <div style={{ position: "relative" }}>
              {/* Image */}
              {!isVideoPlaying && (
                <img
                  src={customer} // Replace with the actual path to your image
                  alt=""
                  className="duo-img"
                  onClick={handleImageClick}
                />
              )}

              {/* YouTube Video */}
              {isVideoPlaying && (
                <iframe
                  title="YouTube Video"
                  className="duo-img"
                  src="//www.youtube.com/embed/AlsM99-FFA4?rel=0&autoplay=1&mute=1" // Muted
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                />
              )}
            </div>
          </div>
          <div className="">
            <h6>What is DUO Credit Card Preposition?</h6>
            <p>Get to know about DUO Credit Card</p>
            <a
              href="https://www.youtube.com/embed/AlsM99-FFA4?rel=0&autoplay=1&mute=1"
              target="_blank"
            >
              <button>Watch Video</button>
            </a>
          </div>
        </div>

        <div className="duo-body-2">
          <div className="">
            {/* <img src={customer} alt="" /> */}
            <div style={{ position: "relative" }}>
              {/* Image */}
              {!isVideoPlayingDP && (
                <img
                  src={customer} // Replace with the actual path to your image
                  alt=""
                  className="duo-img"
                  onClick={handleImageDPClick}
                />
              )}

              {/* YouTube Video */}
              {isVideoPlayingDP && (
                <iframe
                  title="YouTube Video"
                  className="duo-img"
                  src="//www.youtube.com/embed/9LmzJSJsnNc?rel=0&autoplay=1&mute=1" // Muted
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                />
              )}
            </div>
          </div>
          <div className="">
            <h6>Combined explainer video on overall DUO proposition</h6>
            <p>
              Get to know more about overall DUO proposition and how you benefit
              from it.
            </p>
            <a
              href="https://www.youtube.com/embed/9LmzJSJsnNc?rel=0&autoplay=1&mute=1"
              target="_blank"
            >
              <button>Watch Video</button>
            </a>
          </div>
        </div>
      </div>

      <div className="duo-body3">
        <h1>DUO proposition for Issuers</h1>
        <div className="duo-body-3">
          <div>
            {/* <img src={issuers} alt="" /> */}
            <div style={{ position: "relative" }}>
              {/* Image */}
              {!isVideoPlayingIssuers && (
                <img
                  src={issuers} // Replace with the actual path to your image
                  alt=""
                  className="duo-img"
                  onClick={handleImageIssuersClick}
                />
              )}

              {/* YouTube Video */}
              {isVideoPlayingIssuers && (
                <iframe
                  title="YouTube Video"
                  width="463px"
                  height="254px"
                  src="//www.youtube.com/embed/AlsM99-FFA4?rel=0&autoplay=1&mute=1" // Muted
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  style={{
                    position: "relative",
                    zIndex: 0,
                    borderRadius: "20px",
                  }}
                />
              )}
            </div>
          </div>
          <div>
            <h6>DUO Proposition for Issuers?</h6>
            <p>
              This explainer Video depicts all the benefits Issuers have from
              DUO offering. Partnership with Artha Fintech not only helps
              Issuers in creating / improving Credit Card product program but
              also help them in driving acquisitions & activations digitally for
              the product besides helping Issuers manage Credit Card portfolio,
              usage & collections against delinquent customers.
            </p>
            <button>Contact Sales</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
