import React from 'react'
import Animation from "../Animation/Main"

const Hero = () => {
  return (
    <>
    <div className='sup-payment-hero'>
        <div className='row'>
            <div className='col'>
                <h1>Effortlessly send invoices to Distributors and Outlets <span>directly from your handheld with</span> Montra Supply Chain SDK: </h1>

                <div className='capabilities'>
                    
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Offer credit period on the Invoice that you send to Distributor or Outlets under Distributor’s direct coverage</p>
                        </div>
                    </div>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Distributors & Outlets can simply pay during the Credit Period or on the last day using their Montra Business Accounts or Linked Bank Accounts</p>
                        </div>
                    </div>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Montra provides Dashboard to both Distribution Company / Distributor & Outlet owner to view all payments done against a Credit based invoice</p>
                        </div>
                    </div>
                    
                </div>
                <button>Contact Sales</button>
            </div>
            <div className='col'>
                <Animation index={1}/>
            </div>
        </div>
    </div>
    </>
  )
}

export default Hero