import React from "react";

function YU12() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="455"
      height="111"
      fill="none"
      viewBox="0 0 455 111"
    >
      <path
        className='yu-path'
        stroke="url(#paint0_radial_3806_11530)"
        strokeWidth="1.5"
        d="M0 52h25.501c4.667 0 14-2.898 14-14.491V10.673c.167-3.22 3-9.66 13-9.66h136c3.5-.18 10.5 1.502 10.5 9.66V37.51c.166 4.83 3.1 14.491 13.5 14.491H287"
        pathLength="1"
      ></path>
      <path
        className='yu-path'
        stroke="url(#paint1_radial_3806_11530)"
        strokeWidth="1.5"
        d="M0 61.25h25.53c4.671 0 14.015 2.762 14.015 13.805v25.564c.167 3.068 3.003 9.203 13.014 9.203h136.15c3.504.171 10.512-1.431 10.512-9.203V75.055c.166-4.601 3.103-13.804 13.514-13.804H281L294.5 56H441c4.333.5 13-.2 13-7v-3"
        pathLength="1"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_3806_11530"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 25.5 -202 0 202 26.5)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17D92A"></stop>
          <stop offset="1" stopColor="#FFBB08"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial_3806_11530"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 -53 227 0 227 57)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17D92A"></stop>
          <stop offset="1" stopColor="#FFBB08"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU12;
