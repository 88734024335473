import React, { useState, useEffect } from "react";
import tap from "../../../assets/images/tap.svg";
import businessanimg1 from "../../../assets/images/businessimg1.svg";
import businessanimg2 from "../../../assets/images/businessimg2.svg";
import businessanimg3 from "../../../assets/images/businessimg3.svg";
import businessanimg4 from "../../../assets/images/businessimg4.svg";
import cash from "../../../assets/images/cash.svg";
import businessanima4 from "../../../assets/images/businessanima4.svg";
import businessanima5 from "../../../assets/images/businessanima5.svg";
import arrow1 from "../../../assets/images/arrow1.svg";
import billpayment from "../../../assets/images/bill-payment.svg";
import Paydayloan from "../../../assets/images/Paydayloan.svg";
import bank from "../../../assets/images/bank.svg";
import wallet from "../../../assets/images/wallet.svg";
import gatecard from "../../../assets/images/gatecard.svg";
import gatecard2 from "../../../assets/images/gatecard2.svg";
import gatecard3 from "../../../assets/images/gatecard3.svg";
import gatecard4 from "../../../assets/images/gatecard4.svg";
import gatewayimghover from "../../../assets/images/gatewayimghover.svg";
import P2P from "../../../assets/images/P2P.svg";
import P2S from "../../../assets/images/P2S.svg";
import qr from "../../../assets/images/qr.svg";
import POS1 from "../../../assets/images/POS1.svg";
import anym from "../../../assets/images/anym.svg";
import withdraw from "../../../assets/images/withdraw.svg";
import montracard from "../../../assets/images/montracard.svg";
import BT from "../../../assets/images/Group 89.svg";
import Payment from "../../../assets/images/Payment2.svg";
import DebitedFromMontraIcon from "../../../assets/images/Debited from montra icon.svg";
import "./style.css";
import {
  Animation1,
  Animation2,
  Animation3,
  Animation4,
  Animation5,
  Animation6,
  Animation7,
} from "./_animations";
import MSP1 from "../../../assets/images/ms-p-1.png";
import MSP2 from "../../../assets/images/ms-p-2.png";
import MSP3 from "../../../assets/images/ms-p-3.png";
import MSP4 from "../../../assets/images/ms-p-4.png";
import MSSke1 from "../../../assets/images/ms-ske-1.svg";
import MSSke2 from "../../../assets/images/ms-ske-2.svg";
import MSSke3 from "../../../assets/images/ms-ske-3.svg";
import MSSke4 from "../../../assets/images/ms-ske-4.svg";
import S from "../../../assets/images/Inactive & Active.svg";
import SP from "../../../assets/images/SP.png";

const Main = () => {
  const [anim, setAnim] = useState(1);
  const [anim2, setAnim2] = useState(1);

  const [ms, setMs] = useState(1);
  const [showS, setShowS] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setAnim((prev) => {
        return prev === 3 ? 1 : prev + 1;
      });
    }, 4000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setMs((prev) => {
        return prev === 4 ? 1 : prev + 1;
      });
    }, 4000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setAnim2((prev) => {
        return prev === 4 ? 1 : prev + 1;
      });
    }, 4000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setShowS((prev) => {
        return !prev;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="home-business">
        <div className="arrow">
          <img src={arrow1} />
        </div>
        <div className="home-business-body">
          <div className="row">
            <div className="col">
              <div className="home-business-left">
                <div className="left-1 ">
                  <h6>Montra - Business</h6>
                  <h1>
                    <span>Grow</span> your business with{" "}
                    <span>Montra Business Account</span> & start accepting
                    Payments from everyone
                  </h1>
                  <p>
                    Elevate your business with Montra's tailored solutions,
                    propelling you toward your goals with unmatched support and
                    innovation.
                  </p>
                  <button>
                    Explore Payments Acceptance{" "}
                    <i class="bi bi-chevron-right"></i>
                  </button>
                  <div>
                    <img
                      className="direction-img"
                      src={businessanimg1}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="yu-flex gap-92 yu-relative">
                <div className="yu-mr-3">
                  <div className="ske">
                    <img
                      src={P2P}
                      alt=""
                    />
                    <p>Montra Merchants</p>
                  </div>
                </div>
                <div className="yu-mr-3">
                  <div className="ske">
                    <img
                      src={qr}
                      alt=""
                    />
                    <p>QR Scan</p>
                  </div>
                  <div className="ske mt48">
                    <img
                      src={tap}
                      alt=""
                    />
                    <p>Tap & Pay</p>
                  </div>
                  <div className="ske mt48">
                    <img
                      src={POS1}
                      alt=""
                    />
                    <p>POS</p>
                  </div>
                  <div className="ske mt48">
                    <img
                      src={qr}
                      alt=""
                    />
                    <p>QR Scan</p>
                  </div>
                  <div className="ske mt48">
                    <img
                      src={BT}
                      alt=""
                    />
                    <p>Bank Transfer</p>
                  </div>
                </div>
                <div className="yu-mr-3">
                  <div className="ske">
                    <img
                      src={P2S}
                      alt=""
                    />
                    <p>Montra Customers</p>
                  </div>
                  <div className="ske mt120">
                    <img
                      src={montracard}
                      alt=""
                    />
                    <p>Debit or Credit Card</p>
                  </div>
                  <div className="ske mt192">
                    <img
                      src={anym}
                      alt=""
                    />
                    <p>Any Mobile Banking App</p>
                  </div>
                </div>
                <div>
                  {anim === 1 && <Animation1 />}
                  {anim === 2 && <Animation2 />}
                  {anim === 3 && <Animation3 />}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="home-business-left">
                <div className="left-1 left">
                  <h1>
                    Earn more by opening a <span>Montra Agent Account</span> &
                    start offering <span>Agency Banking Services</span>
                  </h1>
                  <p>
                    Expand your financial horizons and provide essential banking
                    services to your community. Montra makes it easy for you to
                    boost your income while offering valuable services to those
                    around you.
                  </p>
                  <button>
                    Explore Payments Acceptance{" "}
                    <i class="bi bi-chevron-right"></i>
                  </button>
                  <div>
                    <img
                      className="direction-img"
                      src={businessanimg2}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col business-2">
              <div className="yu-flex gap-72 yu-relative mt24">
                <div className="yu-mr-4">
                  <div className="ske">
                    <img
                      src={P2S}
                      alt=""
                    />
                    <p>Montra Customers</p>
                  </div>
                  <div className="ske mt72">
                    <img
                      src={montracard}
                      alt=""
                    />
                    <p>Any Debit or Credit Card</p>
                  </div>
                  <div className="ske mt72">
                    <img
                      src={anym}
                      alt=""
                    />
                    <p>Any Mobile Banking App</p>
                  </div>
                  <div className="ske mt124">
                    <img
                      src={Payment}
                      alt=""
                    />
                    <p>Cash Payments</p>
                  </div>
                </div>
                <div className="yu-mr-4">
                  <div className="ske">
                    <img
                      src={cash}
                      alt=""
                    />
                    <p>Cash Withdrawal</p>
                  </div>
                  <div className="ske mt8">
                    <img
                      src={withdraw}
                      alt=""
                    />
                    <p>Cash Deposit</p>
                  </div>
                  <div className="ske mt20">
                    <img
                      src={withdraw}
                      alt=""
                    />
                    <p>Cash Deposit</p>
                  </div>
                  <div className="ske mt20">
                    <img
                      src={cash}
                      alt=""
                    />
                    <p>Cash Withdrawal</p>
                  </div>
                  <div className="ske mt8">
                    <img
                      src={withdraw}
                      alt=""
                    />
                    <p>Cash Deposit</p>
                  </div>
                  <div className="ske mt20">
                    <img
                      src={billpayment}
                      alt=""
                    />
                    <p>Recharge & Bill Payments</p>
                  </div>
                  <div className="ske mt8">
                    <img
                      src={BT}
                      alt=""
                    />
                    <p>Bank Transfer</p>
                  </div>
                </div>
                <div className="yu-mr-4">
                  <div className="ske">
                    <img
                      src={qr}
                      alt=""
                    />
                    <p>QR Scan</p>
                  </div>
                  <div className="ske mt20">
                    <img
                      src={tap}
                      alt=""
                    />
                    <p>Tap & Pay</p>
                  </div>
                  <div className="ske mt8">
                    <img
                      src={POS1}
                      alt=""
                    />
                    <p>POS</p>
                  </div>
                  <div className="ske mt20">
                    <img
                      src={qr}
                      alt=""
                    />
                    <p>QR Scan</p>
                  </div>
                  <div className="ske mt124">
                    <img
                      src={DebitedFromMontraIcon}
                      alt=""
                    />
                    <p>Montra Agent Account</p>
                  </div>
                </div>
                <div className="yu-mr-4">
                  <div className="ske">
                    <img
                      src={P2P}
                      alt=""
                    />
                    <p>Montra Merchants</p>
                  </div>
                </div>
                <div>
                  {anim2 === 1 && <Animation4 />}
                  {anim2 === 2 && <Animation5 />}
                  {anim2 === 3 && <Animation6 />}
                  {anim2 === 4 && <Animation7 />}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col home-business-left">
              <div className="left-3 left">
                <p>Don’t have a website for your business?</p>
                <h1>
                  Start selling online on Montra App by setting up your{" "}
                  <span>Montra Store</span>
                </h1>
                <p>
                  Create a free online customized Montra store for your business
                  category that helps you find customers, accept payments from
                  anyone, and grow your business
                </p>
                <button>
                  Explore Montra Store offering{" "}
                  <i class="bi bi-chevron-right"></i>
                </button>
                <div>
                  <img
                    className="direction-img"
                    src={businessanimg3}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="yu-flex-c gap-16 mt24">
                {ms===1 ? (
                  <img
                    src={MSP1}
                    alt=""
                  />
                ) : (
                  <img
                    src={MSSke1}
                    alt=""
                  />
                )}
                {ms===2 ? (
                  <img
                    src={MSP2}
                    alt=""
                  />
                ) : (
                  <img
                    src={MSSke2}
                    alt=""
                  />
                )}
                {ms===3 ? (
                  <img
                    src={MSP3}
                    alt=""
                  />
                ) : (
                  <img
                    src={MSSke3}
                    alt=""
                  />
                )}
                {ms===4 ? (
                  <img
                    src={MSP4}
                    alt=""
                  />
                ) : (
                  <img
                    src={MSSke4}
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>

          <div className="row nowrap">
            <div className="col">
              <div className="home-business-left">
                <div className="left-4 left">
                  <p>Have an existing website or Mobile App?</p>
                  <h1>
                    Power your business with <span>Montra Payment Gateway</span>
                  </h1>
                  <p>
                    Simply integrate Montra Payment Gateway with your website or
                    Mobile App for your customers to make payments using their :
                  </p>
                  <ul>
                    <li>Debit & Credit Cards</li>
                    <li>
                      Bank Accounts by scanning QR code using their Mobile
                      Banking Apps
                    </li>
                    <li>
                      Montra App where they can make payments using Montra
                      account and even using Installments
                    </li>
                    <li>USSD to make payment using USSD app</li>
                  </ul>
                  <button>
                    Explore Montra Payment Gateway{" "}
                    <i class="bi bi-chevron-right"></i>
                  </button>
                  <div>
                    <img
                      className="direction-img"
                      src={businessanimg4}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="mt24 ml96">
                { showS ? <img src={SP} alt="" className="ml96" /> : <img src={S} alt="" />}
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
