import React from "react";
import "./style.css";
import heroImg from "../../../../assets/images/duo-imp-hero.svg";

const Hero = () => {
  return (
    <section className="imp-hero">
      <div>
      <h1 className="hero-text">
        <b>DUO Credit Card</b> can be simply launched within <b>two months of </b>{" "}MOU execution
      </h1>
      <button>Contact Sales</button>
      </div>
      <img src={heroImg} alt="hero-pic"/>
    </section>
  );
};

export default Hero;
