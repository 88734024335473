import React from "react";

function YU4() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="579"
      height="235"
      fill="none"
      viewBox="0 0 579 235"
    >
      <path
        className='yu-path'
        stroke="url(#paint0_radial_3447_7734)"
        strokeWidth="1.5"
        d="M1.5 120V1H129c5.5 0 16.5 3.8 16.5 13v56h289v145H560c6 0 17.5 1 17.5 9.5V235"
        pathLength="1"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_3447_7734"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 117 -288 0 289.5 118)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17D92A"></stop>
          <stop offset="1" stopColor="#FFBB08"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU4;
