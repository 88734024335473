import React, { useState } from "react";
import Navbar from "../Navbar/Main";
import { HashLink as Link } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import moneylinklogo from "../../assets/images/moneylinklogo.svg";
import "./company.css";

const Company = () => {
  return (
    <>
      <div className="product-nav">
        <Navbar />
      </div>

      <div className="nav-link company-nav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item company-nav-link">
            <NavLink
              to="/Company/Overview"
              className="nav-link"
              activeClassName="com-active"
            >
              Overview
            </NavLink>
          </li>
          <li className="nav-item company-nav-link">
            <NavLink
              to="/Company/Ourstory"
              className="nav-link"
              activeClassName="com-active"
            >
              Our Story
            </NavLink>
          </li>
          <li className="nav-item company-nav-link">
            <NavLink
              to="/"
              className="nav-link"
              activeClassName="com-active"
            >
              Working at Montra
            </NavLink>
          </li>
          <li className="nav-item company-nav-link">
            <NavLink
              to="/"
              className="nav-link"
              activeClassName="com-active"
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Company;
