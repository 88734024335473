import React from "react";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
import personalhero1 from "../../../assets/images/personalhero1.png";
import personalhero2 from "../../../assets/images/personalhero2.png";
import paymentimg3 from "../../../assets/images/paymentimg3.svg";
import paymentimg4 from "../../../assets/images/paymentimg4.svg";
import paymentimg6 from "../../../assets/images/paymentimg6.svg";

const Hero = () => {
  return (
    <>
      <div className="personal-hero payment-hero">
        <div className="row">
          <div className="col personal-hero-left">
            <h1>
              Secure way of accessing <span>world of Payments</span>
            </h1>
            <p>
              Secured with 2FA through your Montra Soft Token, you can now pay
              with a variety of Payment methods using Montra Accounts, Bank
              Accounts & Cards you have linked on Montra App and Installments by
              borrowing loans from our Lending Partners.
            </p>
            <div className="app-download">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
            <img className="paymentimg3" src={paymentimg3} />
            <p>
              Simply scan the QR codes of our Merchants or Tap & Pay on our
              Merchant’s app with your Debit & Credit Cards or use your Debit &
              Credit cards on POS issued by Montra to our merchants
            </p>
          </div>
          <div className="col personal-hero-right payment-hero-img">
            <img src={paymentimg4} alt="image" />
            <img src={paymentimg6} alt="image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
