import React from "react";
import "./style.css";

const Body = () => {
  return (
    <>
      <div className="lm">
        <div className="lm1">
          <div className="lmm">
            <h6>Loan Origination</h6>
            <p>
              Our API can help you offer variety of loan products on Lender’s
              Mobile Banking app to their customers. MoneyLink underwrites these
              loans instantly based on the templatized rules that you set-up on
              our Portal. MoneyLink platform can instantly disburse the approved
              loans
            </p>
          </div>
          <div className="lmm">
            <h6>Loan Management</h6>
            <p>
              MoneyLink cloud-based Loan Management System manages Loan Accounts
              for each of the disbursed loans and help Lenders manage the loan
              over its life-cycle
            </p>
          </div>
          <div className="lmm">
            <h6>Loan Collection</h6>
            <p>
              MoneyLink undertakes the collection processes on behalf of the
              Lenders based on the rules set-up by them on or Portal both for
              Due as well as Delinquent amounts. MoneyLink platform not just
              collects from the linked settlement accounts but also from all
              other accounts that customer have across multiple banks
            </p>
          </div>
          <div className="lmm">
            <h6>MoneyLink Portal</h6>
            <p>
              Lenders can set-up underwriting & collection rules besides
              accessing variety of dashboards to analyze and manage the Loan
              Portfolio
            </p>
          </div>
        </div>
        <div className="lm2">
          <div className="lmm2"></div>
          <div className="lmm2"></div>
          <div className="lmm2"></div>
        </div>
      </div>
    </>
  );
};

export default Body;
