import React from "react";
import paymentimg7 from "../../../assets/images/paymentimg7.svg";
import paymentimg8 from "../../../assets/images/paymentimg8.svg";
import paymentimg9 from "../../../assets/images/paymentimg9.svg";
import paymentimg10 from "../../../assets/images/paymentimg10.svg";
import paymentimg11 from "../../../assets/images/paymentimg11.svg";
import table1 from "../../../assets/images/table11.svg";
import table2 from "../../../assets/images/table2.svg";
import table3 from "../../../assets/images/table3.svg";
import table4 from "../../../assets/images/table4.svg";
import table5 from "../../../assets/images/table5.svg";
import bill from "../../../assets/images/bill.svg";
import data from "../../../assets/images/data.svg";
import tv from "../../../assets/images/tv.svg";
import nepa from "../../../assets/images/nepa.svg";
import wifi from "../../../assets/images/wifi.svg";
import shop from "../../../assets/images/shop.svg";
import trackpayment1 from "../../../assets/images/trackpayment1.svg";
import trackpayment2 from "../../../assets/images/trackpayment2.svg";
import trackpayment3 from "../../../assets/images/trackpayment3.svg";
import trackpayment4 from "../../../assets/images/trackpayment4.svg";
import secure from "../../../assets/images/secure.svg";
import padlock from "../../../assets/images/padlock.svg";
import shield from "../../../assets/images/shield.svg";
import privacy from "../../../assets/images/privacy.svg";

const Body = () => {
  return (
    <>
      <div className="personal-page">
        <div className="payment-body">
          <div className="bank-body-section-2">
            <h1>Payment Methods available on Montra App</h1>
            <div className="row">
              <div className="col-4 payment-body-width-2">
                <img className="payment-img-mobile" src={paymentimg7} alt="" />
              </div>
              <div className="col payment-body-width">
                <div className="open-account">
                  <div className="">
                    <img src={paymentimg8} alt="" />
                  </div>
                  <div className="payment">
                    <h6>Montra Account</h6>
                    <p>Send money or make payments using Montra Account</p>
                  </div>
                </div>
                <div className="open-account">
                  <div className="">
                    {" "}
                    <img src={paymentimg9} alt="" />
                  </div>
                  <div className="payment">
                    <h6>Linked Bank Accounts</h6>
                    <p>
                      Send money or make payments using your Bank Accounts you
                      have previously linked on Montra App
                    </p>
                  </div>
                </div>
                <div className="open-account">
                  <div className="">
                    {" "}
                    <img src={paymentimg10} alt="" />
                  </div>
                  <div className="payment">
                    <h6>Linked Cards</h6>
                    <p>
                      Send money or make payments using your Debit / Credit
                      Cards you have previously linked on Montra App
                    </p>
                  </div>
                </div>
                <div className="open-account">
                  <div className="">
                    {" "}
                    <img src={paymentimg11} alt="" />
                  </div>
                  <div className="payment">
                    <h6>Installments</h6>
                    <p>
                      Borrow instantly from our Lending Partners and use the
                      borrowed funds to make payments and repay in Installments
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="payment-body-section-3">
            <h1>
              Montra App offers a variety of easy-to-use methods for Sending
              Money
            </h1>
            <div className="payment-table-1">
              <table>
                <thead>
                  <tr>
                    <th>Send Money to</th>
                    <th>Send Money using</th>
                    <th>Payment Methods available</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="col-2">
                      Another Montra customer - P2P transfer
                    </td>
                    <td className="col-4">
                      <div className="payment-table-row">
                        <div className="payment-table-row-img">
                          <img src={table1} alt="" />
                        </div>
                        <div className="">
                          <h6>To Mobile or Contact</h6>
                          <p>
                            Send money to another Montra Customer by entering or
                            Selecting one of the numbers from phone contacts
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="col-5">
                      <div className="payment-table-row-2">
                        <div>
                          <img src={paymentimg8} />
                          <p>Montra Account</p>
                        </div>
                        <div>
                          <img src={paymentimg9} />
                          <p>Linked Bank Accounts</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Montra Merchant - P2M payments</td>
                    <td>
                      <div className="payment-table-row">
                        <div className="payment-table-row-img">
                          <img src={table2} />
                        </div>
                        <div className="">
                          <h6>Scan & Pay</h6>
                          <p>
                            Scan the QR code of a Montra merchant and select a
                            payment method
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="payment-table-row-2">
                        <div>
                          <img src={paymentimg8} />
                          <p>Montra Account</p>
                        </div>
                        <div>
                          <img src={paymentimg9} />
                          <p>Linked Bank Accounts</p>
                        </div>
                        <div>
                          <img src={paymentimg10} />
                          <p>Linked Cards</p>
                        </div>
                        <div>
                          <img src={paymentimg11} />
                          <p>Installments</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Any Bank Account - Bank Transfer</td>
                    <td>
                      <div className="payment-table-row">
                        <div className="payment-table-row-img">
                          <img src={table3} alt="" />
                        </div>
                        <div className="">
                          <h6>Bank Transfer</h6>
                          <p>
                            Select a Bank & enter Bank Account number to send
                            money
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="payment-table-row-2">
                        <div>
                          <img src={paymentimg8} />
                          <p>Montra Account</p>
                        </div>
                        <div>
                          <img src={paymentimg9} />
                          <p>Linked Bank Accounts</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Self Accounts</td>
                    <td>
                      <div className="payment-table-row">
                        <div className="payment-table-row-img">
                          <img src={table4} alt="" />
                        </div>
                        <div className="">
                          <h6>Pay to Self Accounts</h6>
                          <p>
                            Select from Montra & Linked Bank Accounts to send
                            money to another Montra or Linked Bank Accounts
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="payment-table-row-2">
                        <div>
                          <img src={paymentimg8} />
                          <p>Montra Account</p>
                        </div>
                        <div>
                          <img src={paymentimg9} />
                          <p>Linked Bank Accounts</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Online shopping</td>
                    <td>
                      <div className="payment-table-row">
                        <div className="payment-table-row-img">
                          <img src={table5} alt="" />
                        </div>
                        <div className="">
                          <h6>Montra Virtual Debit Card</h6>
                          <p>
                            Shop on website / mobile app of other merchants &
                            make payment using Montra Virtual Debit Card
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="payment-table-row-2">
                        <div>
                          <img src={paymentimg8} />
                          <p>Montra Account</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="payment-body-section-4">
            <h1>
              Marketplace on Montra App offers a variety of products & services
            </h1>
            <div className="payment-table-1">
              <table>
                <thead>
                  <tr>
                    <th>Marketplace Products & Services</th>
                    <th>Payment Methods available</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="col-6">
                      <div className="marketplace-icon">
                        <div>
                          <img src={table1} alt="" />
                        </div>
                        <div>Airtime top-up</div>
                      </div>
                    </td>
                    <td className="col-4" rowspan="7">
                      <div className="marketplace-icon2">
                        <div>
                          <div>
                            <img src={paymentimg8} alt="" />
                            <p>Montra Account</p>
                          </div>
                          <div>
                            <img src={paymentimg10} alt="" />
                            <p>Linked Cards </p>
                          </div>
                        </div>
                        <div>
                          <div>
                            <img src={paymentimg9} alt="" />
                            <p>Linked Bank Accounts</p>
                          </div>
                          <div>
                            <img src={paymentimg11} alt="" />
                            <p>Installments</p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="marketplace-icon">
                        <div>
                          <img src={data} alt="" />
                        </div>
                        <div>Data Recharge</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="marketplace-icon">
                        <div>
                          <img src={tv} alt="" />
                        </div>
                        <div> Cable TV</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="marketplace-icon">
                        <div>
                          <img src={nepa} alt="" />
                        </div>
                        <div>Electricity Bill</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="marketplace-icon">
                        <div>
                          <img src={bill} alt="" />
                        </div>
                        <div>Bill Payment</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="marketplace-icon">
                        <div>
                          <img src={wifi} alt="" />
                        </div>
                        <div>Broadband</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="marketplace-icon">
                        <div>
                          <img src={shop} alt="" />
                        </div>
                        <div>
                          Shop on Montra App for Products & Services offered by
                          Montra Store Merchants
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="payment-body-section-5">
            <h1>Track Payments & Rewards easily on Montra</h1>
            <div>
              <div className="row">
                <div className="col">
                  <img src={trackpayment1} alt="" />
                  <h6>Montra Account Statement</h6>
                  <p>
                    Track all your payments made using Montra Account through
                    Montra Account Statement
                  </p>
                </div>
                <div className="col">
                  <img src={trackpayment2} alt="" />
                  <h6>Reward Point Account Statement</h6>
                  <p>
                    Track your Reward Points credited in your Reward Point
                    Account through Reward Point Account statement
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <img src={trackpayment3} alt="" />
                  <h6>
                    View history for all transactions from Linked Accounts &
                    Cards
                  </h6>
                  <p>
                    Track all your payments made using Linked Bank Accounts or
                    Linked Cards through Transaction History on Montra App
                  </p>
                </div>
                <div className="col">
                  <img src={trackpayment4} alt="" />
                  <h6>Built-in protection before you pay</h6>
                  <p>
                    Track your Reward Points credited in your Reward Point
                    Account through Reward Point Account statement
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="payment-body-section-6">
            <h1>Montra, a more secure way to pay</h1>
            <div>
              <div className="row">
                <div className="col">
                  <img src={secure} alt="" />
                  <h6>Secure from the start</h6>
                  <p>
                    Access to your Montra App is secured with the Password that
                    you set-up. You can also access the app using your Face-ID
                    or Biometric-ID that you have set-up on your phone
                  </p>
                </div>
                <div className="col">
                  <img src={padlock} alt="" />
                  <h6>Built-in protection before you pay</h6>
                  <p>
                    Every payment request is processed only upon successful
                    authentication of the Montra PIN you have set-up
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <img src={shield} alt="" />
                  <h6>Secure with 2FA</h6>
                  <p>
                    Our Fraud & Risk Assessment tool would assess the risk for
                    every transaction and would ask for your Soft Token from
                    time to time. Montra offers access to Free Soft Token on
                    VERIFYED app for every Montra Customer
                  </p>
                </div>
                <div className="col">
                  <img src={privacy} alt="" />
                  <h6>Payment Privacy</h6>
                  <p>
                    Only you and the person you are transacting with will be
                    able to see the details of your payment
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
