import React from 'react'
import col1 from "../../../../assets/images/col1.svg";
import col2 from "../../../../assets/images/col2.svg";
import "./style.css";

const Hero = () => {
  return (
    <>
    <div className="dts-hero col-hero">
        <div className="row">
          <div className="col collection-hero-1">
            <div className="dwp-sm-btn">
              <img src={col2} alt="" />
              <p>DUO Collection</p>
            </div>
            <h1>
              <span>Collect better, faster and more with DUO</span>
            </h1>
            <p className='col-hero-p'>
            AI-powered collection platform helps issuer:
            </p>
            <div className="duo-list-container">
              <div className="duo-lists">
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                    Collect repayments requests on DUO App from customers for debiting their bank account & crediting the Credit Card account with requested amount.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                    Track repayments made by customer against minimum due amount & collect the balance due on the due date.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                    Track all delinquent cards & collect delinquent amounts from all the accounts customer has in the bank.
                    </p>
                  </div>
                </div>
                
                
              </div>
            </div>

            <button>Contact Sales</button>
          </div>
          <div className="col">
            <img src={col1} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero