import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeRoute from "./HomeRoute";
import ProductRoute from "./ProductRoute";
import BankingRoute from "./BankingRoute";
import PaymentRoute from "./PaymentRoute";
import FinanceRoute from "./FinanceRoute";
import ChatRoute from "./ChatRoute";
import ShopRoute from "./ShopRoute";
import BusinessRoute from "./BusinessRoute";
import BusinessBankingRoute from "./BusinessBankingRoute";
import BusinessPaymentRoute from "./BusinessPaymentRoute";
import BusinessAgencyRoute from "../components/Business/AgencyBanking/Main";
import InventoryRoute from "../components/Business/Inventory/Main";
import StoreRoute from "../components/Business/Store/Main";
import GatewayRoute from "../components/Business/Gateway/Main";
import TapPayRoute from "../components/Business/TapPay/Main";
import DuoRoute from "../components/Solutions/Duo/Overview/Main";
import DtsRoute from "../components/Solutions/Duo/Dts/Main";
import DcpRoute from "../components/Solutions/Duo/Dcp/Main";
import DlmRoute from "../components/Solutions/Duo/Dlm/Main";
import ImplementationRoute from "../components/Solutions/Duo/Implementation/Main";
import CollectionRoute from "../components/Solutions/Duo/Collections/Main";
import DwpRoute from "../components/Solutions/Duo/Dwp/Main";
import ActivationRoute from "../components/Solutions/Duo/Activation/Main";
import CustomeronboardingRoute from "../components/Solutions/Duo/Customeronboarding/Main";
import DuoappRoute from "../components/Solutions/Duo/Duoapp/Main";
import SupplychainRoute from "../components/Solutions/Supplychain/Overview/Main";
import SalesRoute from "../components/Solutions/Supplychain/Sales/Main";
import SupplychainpaymentRoute from "../components/Solutions/Supplychain/Payment/Main";
import SupplychaincollectionRoute from "../components/Solutions/Supplychain/Payment/Main";
import OrderFulfilmentRoute from "../components/Solutions/Supplychain/OrderFulfilment/Main";
import DistributionAnalyticsRoute from "../components/Solutions/Supplychain/DistributionAnalytics/Main";
import MoneylinkcipRoute from "../components/Platform/Moneylink/Cardissuing/Main";
import MoneylinkmerchantRoute from "../components/Platform/Moneylink/Merchant/Main";
import MoneylinkLoanmanagementRoute from "../components/Platform/Moneylink/Loanmanagement/Main";
import MoneylinkDebtCollectionsRoute from "../components/Platform/Moneylink/DebtCollections/Main";
import MoneylinkAccountManagementRoute from "../components/Platform/Moneylink/AccountManagement/Main";
import Verifiedauthapp from "../components/Solutions/Verified/Authapp/Main";
import Verifiedfaceauth from "../components/Solutions/Verified/Faceauth/Main";
import CompanyRoute from "../components/Company/Home/Main";
import OurstoryRoute from "../components/Company/Ourstory/Main";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeRoute />} />
      <Route path="/Products/overview" element={<ProductRoute />} />
      <Route path="/Products/Banking" element={<BankingRoute />} />
      <Route path="/Products/Payment" element={<PaymentRoute />} />
      <Route path="/Products/Finance" element={<FinanceRoute />} />
      <Route path="/Products/Chat" element={<ChatRoute />} />
      <Route path="/Products/Shop" element={<ShopRoute />} />
      <Route path="/Business/overview" element={<BusinessRoute />} />
      <Route path="/Business/Banking" element={<BusinessBankingRoute />} />
      <Route path="/Business/Payment" element={<BusinessPaymentRoute />} />
      <Route path="/Business/AgencyBanking" element={<BusinessAgencyRoute />} />
      <Route path="/Business/more/Inventory" element={<InventoryRoute />} />
      <Route path="/Business/more/Store" element={<StoreRoute />} />
      <Route path="/Business/more/Gateway" element={<GatewayRoute />} />
      <Route path="/Business/more/TapPay" element={<TapPayRoute />} />
      <Route path="/Solutions/Duo/overview" element={<DuoRoute />} />
      <Route path="/Solutions/Duo/Dts" element={<DtsRoute />} />
      <Route path="/Solutions/Duo/Dcp" element={<DcpRoute />} />
      <Route path="/Solutions/Duo/Dcp" element={<DcpRoute />} />
      <Route path="/Solutions/Duo/Dwp" element={<DwpRoute />} />
      <Route path="/Solutions/Duo/Dlm" element={<DlmRoute />} />
      <Route
        path="/Solutions/Duo/Implementation"
        element={<ImplementationRoute />}
      />
      <Route path="/Solutions/Duo/Collection" element={<CollectionRoute />} />
      <Route path="/Solutions/Duo/Activation" element={<ActivationRoute />} />
      <Route
        path="/Solutions/Duo/Customeronboarding"
        element={<CustomeronboardingRoute />}
      />
      <Route path="/Solutions/Duo/Duoapp" element={<DuoappRoute />} />
      <Route
        path="/Solutions/Supplychain/overview"
        element={<SupplychainRoute />}
      />
      <Route path="/Solutions/Supplychain/Sales" element={<SalesRoute />} />
      <Route
        path="/Solutions/Supplychain/payment"
        element={<SupplychainpaymentRoute />}
      />
      <Route
        path="/Solutions/Supplychain/collection"
        element={<SupplychaincollectionRoute />}
      />
      <Route
        path="/Solutions/Supplychain/Orderfulfilment"
        element={<OrderFulfilmentRoute />}
      />
      <Route
        path="/Solutions/Supplychain/Distributionanalytics"
        element={<DistributionAnalyticsRoute />}
      />
      <Route path="/Platform/Moneylink/cip" element={<MoneylinkcipRoute />} />
      <Route
        path="/Platform/Moneylink/merchant"
        element={<MoneylinkmerchantRoute />}
      />
      <Route
        path="/Platform/Moneylink/Loanmanagement"
        element={<MoneylinkLoanmanagementRoute />}
      />
      <Route
        path="/Platform/Moneylink/Debtcollections"
        element={<MoneylinkDebtCollectionsRoute />}
      />
      <Route
        path="/Platform/Moneylink/Accountmanagement"
        element={<MoneylinkAccountManagementRoute />}
      />
      <Route path="/Solution/Verified/Authapp" element={<Verifiedauthapp />} />
      <Route
        path="/Solution/Verified/Faceauth"
        element={<Verifiedfaceauth />}
      />
      <Route path="/Company/Overview" element={<CompanyRoute />} />
      <Route path="/Company/Ourstory" element={<OurstoryRoute />} />
    </Routes>
  );
};

export default AppRoutes;
