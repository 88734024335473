import React from "react";

function DebitOrCredit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="17"
      fill="none"
      viewBox="0 0 26 17"
    >
      <rect
        width="23.438"
        height="16"
        x="1.281"
        y="0.5"
        stroke="#223872"
        rx="1.5"
      ></rect>
      <circle cx="5.35" cy="12.537" r="1.944" stroke="#223872"></circle>
      <circle cx="7.303" cy="12.537" r="1.944" stroke="#223872"></circle>
      <path stroke="#223872" d="M0.781 3.75L24.156 3.75"></path>
      <path stroke="#223872" d="M0.781 6.938L24.156 6.938"></path>
      <rect
        width="3.188"
        height="1.063"
        x="11.406"
        y="12.219"
        fill="#3FAFE9"
        rx="0.531"
      ></rect>
      <rect
        width="3.188"
        height="1.063"
        x="15.656"
        y="12.219"
        fill="#3FAFE9"
        rx="0.531"
      ></rect>
      <rect
        width="3.188"
        height="1.063"
        x="19.906"
        y="12.219"
        fill="#3FAFE9"
        rx="0.531"
      ></rect>
    </svg>
  );
}

export default DebitOrCredit;
