import React from 'react';
import Bankingimg1 from '../../../assets/images/bankingimg1.svg';
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";

const Hero = () => {
  return (
   <>
   <div className='banking-hero'>
    <div className='row'>
        <div className='col banking-hero-left'>
            <h1>Do more with your <span>Money</span>. Spend & earn <span>Rewards</span></h1>
            <ul>
                <li>Open a Montra Account and get a virtual Montra Card issued instantly.</li>
                <li>You can also link your existing Bank accounts on Montra App.</li>
                <li>Now you can start making payments using your Montra or Linked Bank accounts and earn exciting rewards</li>
            </ul>
            <div className="app-download">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
        </div>
        <div className='col banking-hero-img'>
            <img src={Bankingimg1} alt="Montra Banking Image"/>
        </div>
    </div>
   </div> 
   
   
   </>
  )
}

export default Hero