import React from "react";

function YU9() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="388"
      height="316"
      fill="none"
      viewBox="0 0 388 316"
    >
      <path
        className='yu-path'
        stroke="url(#paint0_radial_3450_10973)"
        strokeWidth="1.5"
        d="M1 .5V284c0 11 2.2 30.5 21 30.5s93.167.5 129.001.5H371.5c5.167.333 15.5-.9 15.5-8.5V200v-15c0-9.6-9.833-12-15-12H33.5c-14.4 0-19-14-19-21.5V.5"
        pathLength="1"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_3450_10973"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 157.833 -20.9092 0 21.91 157.172)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0048E5"></stop>
          <stop offset="1" stopColor="#7A73FF"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU9;
