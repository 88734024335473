import React from "react";
import YU1 from "../../../assets/svg/yu1.jsx";
import YU2 from "../../../assets/svg/yu2.jsx";
import YU3 from "../../../assets/svg/yu3.jsx";
import YU4 from "../../../assets/svg/yu4.jsx";
import YU5 from "../../../assets/svg/yu5.jsx";
import YU6 from "../../../assets/svg/yu6.jsx";
import "./style.css";
import MontraCustomers from "../../../assets/svg/montra-customers.jsx";
import ScanAndPay from "../../../assets/svg/scan-and-pay.jsx";
import MontraAccount from "../../../assets/svg/montra-account.jsx";
import Installments from "../../../assets/svg/installments.jsx";
import LinkedCards from "../../../assets/svg/linked-cards.jsx";
import LinkedBanks from "../../../assets/svg/linked-banks.jsx";
import PaymentAuthorization from "../../../assets/svg/payment-authorization.jsx";
import MontraMerchants from "../../../assets/svg/montra-merchants.jsx";
import OtherCustomers from "../../../assets/svg/other-customers.jsx";
import BankTransfer from "../../../assets/svg/bank-transfer.jsx";
import MobileOrContact from "../../../assets/svg/mobile-or-contact.jsx";
import LendingPartners from "../../../assets/svg/lending-partners.jsx";
import LoanEligibilityComparison from "../../../assets/svg/loan-eligibility-comparison.jsx";
import LoanOfferAcceptance from "../../../assets/svg/loan-offer-acceptance.jsx";
import LoanAuthorization from "../../../assets/svg/loan-authorization.jsx";
import InstantLoanDisbursement from "../../../assets/svg/instant-loan-disbursement.jsx";
import LoanManagementSystem from "../../../assets/svg/loan-management-system.jsx";
import LoanStatement from "../../../assets/svg/loan-statement.jsx";
import LoanMgtService from "../../../assets/svg/loan-mgt-service.jsx";
import LoanRepayment from "../../../assets/svg/loan-repayment.jsx";

export const Animation1 = ({ className }) => {
  return (
    <div className={`yu-g-1 yu-flex yu-absolute ${className}`}>
      <div className="yu-f-1 ske-b">
        <MontraCustomers />
        <p>Montra Customers</p>
      </div>
      <div className="yu-f-2 ske-b">
        <MobileOrContact />
        <p>Mobile or Contact</p>
      </div>
      <div className="yu-f-3 ske-b">
        <MontraAccount />
        <p>Montra Account</p>
      </div>
      <div className="yu-f-4 ske-b">
        <PaymentAuthorization />
        <p>Payout Authorization</p>
      </div>
      <div className="yu-f-5 ske-b">
        <MontraCustomers />
        <p>Montra Customers</p>
      </div>
      <div className="yu-absolute yu-mt-x">
        <YU1 />
      </div>
    </div>
  );
};

export const Animation2 = ({ className }) => {
  return (
    <div className={`yu-g-2 yu-flex yu-absolute ${className}`}>
      <div className="yu-f-1 ske-b">
        <MontraCustomers />
        <p>Montra Customers</p>
      </div>
      <div className="yu-f-6 ske-b">
        <BankTransfer />
        <p>Bank Transfer</p>
      </div>
      <div>
        <div className="yu-f-3 ske-b">
          <MontraAccount />
          <p>Montra Account</p>
        </div>
        <div className="yu-f-7 ske-b">
          <LinkedBanks />
          <p>Linked Banks</p>
        </div>
      </div>
      <div className="yu-f-4 ske-b">
        <PaymentAuthorization />
        <p>Payout Authorization</p>
      </div>
      <div className="yu-f-6 ske-b">
        <OtherCustomers />
        <p>Other Customers</p>
      </div>
      <div className="yu-absolute yu-mt-x-2">
        <YU2 />
      </div>
    </div>
  );
};

export const Animation3 = ({ className }) => {
  return (
    <div className={`yu-g-3 yu-flex yu-absolute ${className}`}>
      <div className="yu-f-1 ske-b">
        <MontraCustomers />
        <p>Montra Customers</p>
      </div>
      <div className="yu-f-1 ske-b">
        <ScanAndPay />
        <p>Scan & Pay</p>
      </div>
      <div>
        <div className="yu-f-3 ske-b">
          <MontraAccount />
          <p>Montra Account</p>
        </div>
        <div className="yu-f-3 ske-b">
          <Installments />
          <p>Installments</p>
        </div>
        <div className="yu-f-3 ske-b">
          <LinkedCards />
          <p>Linked Cards</p>
        </div>
        <div className="yu-f-3 ske-b">
          <LinkedBanks />
          <p>Linked Banks</p>
        </div>
      </div>
      <div className="yu-f-4 ske-b">
        <PaymentAuthorization />
        <p>Payout Authorization</p>
      </div>
      <div className="ske-b yu-f-1">
        <MontraMerchants />
        <p>Montra Merchants</p>
      </div>
      <div className="yu-absolute yu-mt-x-3">
        <YU3 />
      </div>
    </div>
  );
};

export const Animation4 = ({ className }) => {
  return (
    <div className={`yu-flex yu-absolute ${className}`}>
      <div className="yu-mr-2">
        <div className="ske-b mt24">
          <LendingPartners />
          <p>Lending Partners</p>
        </div>
        <div className="ske-b mt66">
          <MontraCustomers />
          <p>Montra Customers</p>
        </div>
      </div>
      <div className="yu-mr-2">
        <div className="ske-b mt24">
          <LoanEligibilityComparison />
          <p>Loan Eligibility Comparison</p>
        </div>
      </div>
      <div className="yu-mr-2">
        <div className="ske-b mt24">
          <LoanOfferAcceptance />
          <p>Loan Offer Acceptance</p>
        </div>
      </div>
      <div className="yu-mr-2">
        <div className="ske-b mt24">
          <LoanAuthorization />
          <p>Loan Authorization</p>
        </div>
        <div className="ske-b mt50">
          <InstantLoanDisbursement />
          <p>Instant Loan Disbursement</p>
        </div>
      </div>
      <div className="yu-mr-2">
        <div className="ske-b mt24">
          <LoanManagementSystem />
          <p>Loan Mgt. System</p>
        </div>
      </div>
      <div className="yu-absolute mt67 ml45 z-back">
        <YU4 />
      </div>
    </div>
  );
};

export const Animation5 = ({ className }) => {
  return (
    <div className={`yu-flex yu-absolute ${className}`}>
      <div className="yu-mr-2">
        <div className="ske-b mt186">
          <MontraCustomers />
          <p>Montra Customers</p>
        </div>
      </div>
      <div className="yu-mr-2">
        <div className="ske-b mt232">
          <LoanStatement />
          <p>Loan Statement</p>
        </div>
      </div>
      <div className="yu-mr-2">
        <div className="ske-b mt371">
          <LoanMgtService />
          <p>Loan Mgt. Service</p>
        </div>
      </div>
      <div className="yu-absolute mt286 ml45 z-back">
        <YU5 />
      </div>
    </div>
  );
};

export const Animation6 = ({ className }) => {
  return (
    <div className={`yu-flex yu-absolute ${className}`}>
      <div className="yu-mr-2">
        <div className="ske-b mt186">
          <MontraCustomers />
          <p>Montra Customers</p>
        </div>
      </div>
      <div className="yu-mr-2">
        <div className="ske-b opacity-0"></div>
      </div>
      <div className="yu-mr-2">
        <div className="ske-b mt232">
          <LoanRepayment />
          <p>Loan Repayment</p>
        </div>
        <div className="ske-b mt43">
          <LoanMgtService />
          <p>Loan Mgt. Service</p>
        </div>
      </div>
      <div className="yu-mr-2">
      <div className="ske-b opacity-0"></div>
      </div>
      <div className="yu-mr-2">
        <div className="ske-b mt24">
          <LoanManagementSystem />
          <p>Loan Mgt. System</p>
        </div>
      </div>
      <div className="yu-absolute mt240 ml96 z-back">
        <YU6 />
      </div>
    </div>
  );
};
