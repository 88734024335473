import React from "react";
import duocard from "../../../../assets/images/duocard.svg";
import duohero2 from "../../../../assets/images/duohero2.svg";

const Hero = () => {
  return (
    <>
      <div className="duo-overview-hero">
        <div className="row">
          <div className="col duo-overview-text">
            <h1>
              <span>Enjoy DUO Card Experience</span> With both Credit & Debit
              cards in a single card
            </h1>
            <p>
              DUO offers end to end solutions for Issuers to launch , manage &
              scale Credit Card issuance, activations, usage & collections
              towards enabling them create a profitable portfolio
            </p>
          </div>
          <div className="col ">
            <img className="duoheroimg" src={duocard} alt="" />
          </div>
        </div>

        <div className="duohero2">
          <img src={duohero2} alt="" />
          <div>
          <button>Contact Sales</button>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Hero;
