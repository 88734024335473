
import React, { useState } from 'react';
import Navbar from '../Navbar/Main'
import { HashLink as Link } from "react-router-hash-link";
import { NavLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import './product.css';

const Productnav = () => {
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);

  const handleButtonClick = () => {
    setSubMenuVisible(!isSubMenuVisible);
  };

  const handleSubMenuClick = (e) => {
    e.stopPropagation();
  };

  const handleMouseLeave = () => {
   
    setTimeout(() => {
      setSubMenuVisible(false);
    }, 3000); 
  };
  return (
    <>
    <div className='product-nav'>
      <Navbar />
    </div>
    <div className='second-navbar navbar'>
      <div className='swtich-btn'>
        <div className=''>
        <NavLink to="/Products/overview" className="nav-link">
          <button className='nonactive-swtich-btn'>Personal</button>
          </NavLink>
        </div>
        <div className=''>
            
            <button className='active-swtich-btn'>Business</button>
            
          
        </div>
      </div>


      <div className="nav-link">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <NavLink  to="/Business/overview" className="nav-link" activeClassName="active">
            Overview
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/Business/Banking" className="nav-link" activeClassName="active">
            Banking
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/Business/Payment" className="nav-link" activeClassName="active">
            Payments
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/Business/AgencyBanking" className="nav-link" activeClassName="active">
            Agency Banking
          </NavLink>
        </li>
        
        <li className="nav-item">
          
      <button
        style={{ color: '#000' }}
        className='more'
        onClick={handleButtonClick}
        onMouseEnter={() => setSubMenuVisible(true)}
        onMouseLeave={handleMouseLeave}
      >
        More
      </button>

      {isSubMenuVisible && (
        <div className="submenu" style={{ position: 'absolute' }} onClick={handleSubMenuClick}
          onMouseEnter={() => setSubMenuVisible(true)} 
          onMouseLeave={handleMouseLeave}
        >
          {/* Your submenu content goes here */}
          <NavLink to="/Business/more/Inventory" className="nav-link" activeClassName="active">
            Inventory Management
          </NavLink>
          <NavLink to="/Business/more/Store" className="nav-link" activeClassName="active">
            Montra Store
          </NavLink>
          <NavLink to="/Business/more/Gateway" className="nav-link" activeClassName="active">
            Payment Gateway
          </NavLink>
          <NavLink to="/Business/more/TapPay" className="nav-link" activeClassName="active">
            Tap & Pay
          </NavLink>
          {/* Add more submenu items as needed */}
        </div>
      )}
        </li>
       
        <li className="nav-item">
          <button>Sign In <i className="bi bi-chevron-right"></i></button>
        </li>
      </ul>
    </div>
    </div>
    
    </>
  )
}

export default Productnav