import React from "react";

function OtherCustomers() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#223872"
        d="M4.091 8.37c1.713 1.238 2.794 1.487 4.941 1.107.286 1.449.545 1.964 1.098 2.612-1.186.377-1.817.802-2.902 1.82A597.348 597.348 0 014.64 11.06a163.556 163.556 0 00-3.607 3.8c-.198-3.677.462-5.106 3.058-6.49z"
      ></path>
      <circle cx="14.606" cy="8.577" r="4.95" fill="#223872"></circle>
      <path
        fill="#223872"
        fillRule="evenodd"
        d="M10.927 4.205A5.326 5.326 0 009.03 8.188a3.905 3.905 0 01-1.94.512C4.941 8.7 3.2 6.976 3.2 4.85 3.2 2.724 4.94 1 7.09 1c1.927 0 3.527 1.386 3.837 3.205z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#223872"
        d="M18.949 12.77c-3.637 2.53-5.607 2.476-9.007 0-1.054.589-1.567.955-2.212 1.728.731.884 1.062 1.235 1.58 1.729a25.963 25.963 0 00-2.686 0c-.558 3.3.802 4.11 3.318 5.5 4.831-.812 7.335-.59 11.456.942.72-.142 1.077-.322 1.58-.942.182-4.855-.736-6.789-4.03-8.957z"
      ></path>
      <path
        fill="#4ABCEB"
        stroke="#4ABCEB"
        strokeWidth="0.2"
        d="M1.441 15.3l3.3-3.3 2.75 3.3h-1.65c-.376 4.174 1.148 5.317 3.85 7.15-3.963-1.036-6.01-1.635-6.6-7.15h-1.65z"
      ></path>
    </svg>
  );
}

export default OtherCustomers;
