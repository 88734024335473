import React from "react";

function InstantLoanDisbursement() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#20336B"
        d="M24.43 15.659a1.79 1.79 0 00-2.567-1.085 8.26 8.26 0 00-.338-3.984.469.469 0 00-.887.304 7.318 7.318 0 01.015 4.688L16.917 18.9a3.767 3.767 0 01-.752.519 1.802 1.802 0 00-.97-2.056l-3.309-1.585a6.016 6.016 0 00-4.645-.22l-.337.123A7.24 7.24 0 018.62 8.12l3.31-3.31h3.663l2.804 2.805a.469.469 0 00.663-.663l-2.68-2.68.97-2.787A.84.84 0 0017 .493a.84.84 0 00-1.044.118l-.005.006a.498.498 0 01-.703 0 2.112 2.112 0 00-2.984 0 .494.494 0 01-.703 0 .84.84 0 00-1.045-.118.839.839 0 00-.348.993l.973 2.78-3.184 3.184a8.163 8.163 0 00-2.265 4.297 8.202 8.202 0 00.331 4.252l-.739.27a1.342 1.342 0 00-1.065-.525H1.844c-.741 0-1.344.602-1.344 1.343v5.563C.5 23.397 1.103 24 1.844 24h12.355c1.94 0 3.775-.745 5.167-2.098l4.59-4.46a1.79 1.79 0 00.474-1.783zM11.912 1.7c.383 0 .744-.15 1.015-.42a1.174 1.174 0 011.658 0c.27.27.631.42 1.014.42.272 0 .533-.075.758-.216l-.83 2.388h-3.532l-.835-2.384c.224.138.482.212.752.212zM1.438 22.656v-5.563c0-.224.182-.406.406-.406h2.375c.224 0 .406.182.406.406v5.563a.407.407 0 01-.406.406H1.844a.407.407 0 01-.406-.406zm21.865-5.887l-4.59 4.461a6.443 6.443 0 01-4.514 1.832h-8.7c.043-.131.064-.268.064-.406v-5.483l2-.735a5.073 5.073 0 013.919.186l3.309 1.585a.852.852 0 01.455.534.861.861 0 01-1.089 1.066L9.76 18.41a.469.469 0 00-.284.893l4.397 1.398c.177.057.361.085.547.085.022 0 .044 0 .066-.002a4.695 4.695 0 003.054-1.184l4.599-4.085a.858.858 0 01.745-.197.843.843 0 01.645.6.843.843 0 01-.226.85z"
      ></path>
      <path
        fill="#20336B"
        d="M20.129 9.328a.47.47 0 00.331-.8.472.472 0 00-.663 0 .471.471 0 00.331.8z"
      ></path>
      <path
        fill="#24BAF2"
        d="M17.452 11.336h-.986v-.947h.986a.268.268 0 100-.537h-.986V8.538c0-.192-.038-.333-.112-.418-.071-.082-.165-.12-.294-.12a.346.346 0 00-.283.119c-.074.086-.111.227-.111.42v1.313h-1.854l-.789-1.192c-.063-.101-.128-.202-.192-.302a1.34 1.34 0 00-.16-.209.514.514 0 00-.158-.11.52.52 0 00-.215-.039c-.109 0-.208.03-.303.091a.501.501 0 00-.198.23c-.035.09-.053.226-.053.402v1.13h-.976a.268.268 0 000 .536h.976v.947h-.976a.268.268 0 000 .536h.976v1.5c0 .187.039.326.116.415a.362.362 0 00.294.123c.123 0 .216-.039.292-.124.08-.086.119-.226.119-.413v-1.501h1.678l.907 1.392c.064.093.128.185.194.276.058.082.122.154.19.214a.592.592 0 00.435.157c.246 0 .497-.076.497-.633v-1.406h.986a.268.268 0 000-.536zm-1.786-.947v.947h-.874l-.626-.947h1.5zM12.565 9.3l.36.552h-.36V9.3zm0 2.036v-.947h.71l.618.947h-1.328zm3.1 1.322l-.518-.786h.519v.786z"
      ></path>
    </svg>
  );
}

export default InstantLoanDisbursement;
