import React, { useState } from 'react';
import Navbar from '../Navbar/Main'
import { HashLink as Link } from "react-router-hash-link";
import { NavLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import verifiedlogo from '../../assets/images/verifiedlogo.svg';
import './verified.css';


const Verified = () => {
  return (
    <>
    <div className='product-nav'>
      <Navbar />
    </div>


    <div className='second-navbar navbar'>
      <div className='solutions-logo'>
        <img src={verifiedlogo} alt='' />
      </div>


      <div className="nav-link ver-nav-link">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <NavLink  to="/Solution/Verified/Authapp" className="nav-link" activeClassName="sol-active">
          Authenticator App
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/Solution/Verified/Faceauth" className="nav-link" activeClassName="sol-active">
          Face Authentication
          </NavLink>
        </li>
        
      </ul>
    </div>
    </div>
    </>
  )
}

export default Verified