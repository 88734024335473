import React from "react";
import duo2 from "../../../assets/images/duo2.png";
import duo3 from "../../../assets/images/duo3.svg";
import duo4 from "../../../assets/images/duo4.svg";

const Main = () => {
  return (
    <>
      <div className="duo">
        <div className="row">
          <div className="col">
            <h6>Solutions</h6>
            <img src={duo4} alt="" />
            <h3>
              <span>Enjoy DUO card experience</span> with both Credit <span>&</span> Debit
              cards in a single card
            </h3>
            <p>
              DUO offers end to end solutions for Issuers to launch , manage &
              scale Credit Card issuance, activations, usage & collections
              towards enabling them create a profitable portfolio
            </p>
            <button>Explore DUO <i class="bi bi-chevron-right"></i></button>
          </div>
          <div className="col">
            <img className="duo-card"  src={duo2} alt="" />
          </div>
        </div>
        <img className="duo-card2" src={duo3} alt="" />
      </div>
    </>
  );
};

export default Main;
