import React from "react";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
import storehero from "../../../assets/images/storehero.svg";
import storehero2 from "../../../assets/images/storehero2.svg";

const Hero = () => {
  return (
    <>
      <div className="store-hero">
        <div className="row">
          <div className="col">
            <p>Don’t have a website for your business?</p>
            <h1>
              Start selling online on Montra App by setting up your <span>Montra Store</span>
            </h1>
            <p>
              Create a free online customized Montra store for your business
              category that helps you find customers, accept payments from
              anyone, and grow your business
            </p>

            <img style={{padding: '10px 0'}} src={storehero2} alt=" " />
            <div className="app-download">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
          </div>
          <div className="col store-hero2">
            <img src={storehero} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
