import React from 'react'
import Navbar from "../../Navbar/Productnav";
import Productfooter from "../../Footer/Productfooter";
import arrow1 from "../../../assets/images/arrow1.svg";
import arrow2 from "../../../assets/images/arrow2.png";
import Hero from './Hero';
import Body from './Body';

const Main = () => {
  return (
    <>
    <Navbar />
    <Hero />
    <div className="payment-page">
        <div className="arrow">
          
          <img src={arrow1} />
        </div>

        <Body />
      </div>
    <Productfooter />

    
    </>
  )
}

export default Main