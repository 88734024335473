import React from "react";

function YU5() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="242"
      height="204"
      fill="none"
      viewBox="0 0 242 204"
    >
      <path
        className='yu-path'
        stroke="url(#paint0_radial_3447_7899)"
        strokeWidth="1.5"
        d="M1 0v56.5c.667 3.667 4.6 10.9 15 10.5 10.4-.4 58.667-.167 81.5 0h47v121c.167 5.167 4.8 15.4 22 15 17.2-.4 57.5-.167 75.5 0"
        pathLength="1"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_3447_7899"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 101.506 -120.5 0 121.5 101.506)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17D92A"></stop>
          <stop offset="1" stopColor="#635BFF"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU5;
