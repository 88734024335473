import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="453"
      height="285"
      fill="none"
      viewBox="0 0 453 285"
    >
      <path
        className='yu-path'
        stroke="url(#paint0_radial_3806_11596)"
        strokeWidth="1.5"
        d="M0 226h22c5.167-.167 15.4-3.8 15-17v-21.5c.5-3 3.8-9 13-9h139.5c3 .167 8.9 2.2 8.5 9V209c-.667 5.667 1.5 17 15.5 17H331"
        pathLength="1"
      ></path>
      <path
        className='yu-path'
        stroke="url(#paint1_radial_3806_11596)"
        strokeWidth="1.5"
        d="M0 236.5h22c5.167.167 15.4 3.8 15 17V275c.5 3 3.8 9 13 9h139.5c3-.167 8.9-2.2 8.5-9v-21.5c-.667-5.667 1.5-17 15.5-17h107l8-5.5H424c9.333.333 28-2.8 28-18V0"
        pathLength="1"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_3806_11596"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 26.25 -167.75 0 167.75 204.75)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0CC2FF"></stop>
          <stop offset="1" stopColor="#8CF2B2"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial_3806_11596"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 -142 226 0 226 142)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0CC2FF"></stop>
          <stop offset="1" stopColor="#8CF2B2"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default Icon;
