import React, { useState } from 'react';
import Navbar from '../Navbar/Main'
import { HashLink as Link } from "react-router-hash-link";
import { NavLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import duologo from '../../assets/images/duologo.svg';
import './solution.css';
import duoapp from '../../assets/images/duoapp.svg';
import dwp from '../../assets/images/dwp.svg';
import co from '../../assets/images/co.svg';
import act from '../../assets/images/act.svg';
import dyn from '../../assets/images/dyn.svg';
import col from '../../assets/images/col.svg';

const Solution = () => {
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);

  const handleButtonClick = () => {
    setSubMenuVisible(!isSubMenuVisible);
  };

  const handleSubMenuClick = (e) => {
    e.stopPropagation();
  };

  const handleMouseLeave = () => {
   
    setTimeout(() => {
      setSubMenuVisible(false);
    }, 3000); 
  };

  return (
    <>
    
     <div className='product-nav'>
      <Navbar />
    </div>


    <div className='second-navbar navbar'>
      <div className='solutions-logo'>
        <img src={duologo} alt='' />
      </div>


      <div className="nav-link sol-nav-link">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <NavLink  to="/Solutions/Duo/overview" className="nav-link" activeClassName="sol-active">
            Overview
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/Solutions/Duo/Dts" className="nav-link" activeClassName="sol-active">
          DTS
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/Solutions/Duo/Dcp" className="nav-link" activeClassName="sol-active">
          DCP
          </NavLink>
        </li>
        <li className="nav-item">
          
      <button
        style={{ color: '#000' }}
        className='more'
        onClick={handleButtonClick}
        onMouseEnter={() => setSubMenuVisible(true)}
        onMouseLeave={handleMouseLeave}
      >
        DUO Support
      </button>

      {isSubMenuVisible && (
        <div className="submenu" style={{ position: 'absolute' }} onClick={handleSubMenuClick}
          onMouseEnter={() => setSubMenuVisible(true)} 
          onMouseLeave={handleMouseLeave}
        >
          {/* Your submenu content goes here */}
          <div className='solution-menu-menu'>
                    <NavLink
                          to="/Solutions/Duo/Duoapp"
                          className="nav-link"
                          activeClassName="active"
                        >
                    <div className='solution-menu'>
                      <div>
                        <img src={duoapp} alt='' />
                      </div>
                      <div>
                        <h6>DUO Card</h6>
                      </div>
                    </div>
                    </NavLink>
                    
                    <NavLink
                          to="/Solutions/Duo/Dwp"
                          className="nav-link "
                          activeClassName="active"
                        >
                    <div className='solution-menu'>
                      <div>
                        <img src={dwp} alt='' />
                      </div>
                      <div>
                        <h6>Duo Web Portal</h6>
                      </div>
                      
                    </div>
                    </NavLink>
                    <NavLink
                          to="/Solutions/Duo/Customeronboarding"
                          className="nav-link"
                          activeClassName="active"
                        >
                    <div className='solution-menu'>
                      <div>
                        <img src={co} alt='' />
                      </div>
                      <div>
                        <h6>Customer Onboarding</h6>
                      </div>
                      
                    </div>
                    </NavLink>
                    <NavLink
                          to="/Solutions/Duo/Activation"
                          className="nav-link"
                          activeClassName="active"
                        >
                    <div className='solution-menu'>
                      <div>
                        <img src={act} alt='' />
                      </div>
                      <div>
                        <h6>Activation</h6>
                      </div>
                      
                    </div>
                    </NavLink>
                    <NavLink
                          to="/Solutions/Duo/Dlm"
                          className="nav-link"
                          activeClassName="active"
                        >
                    <div className='solution-menu'>
                      <div>
                        <img src={dyn} alt='' />
                      </div>
                      <div>
                        <h6>Dynamic Limit Management</h6>
                      </div>
                      
                    </div>
                    </NavLink>
                    <NavLink
                          to="/Solutions/Duo/Collection"
                          className="nav-link"
                          activeClassName="active"
                        >
                    <div className='solution-menu'>
                      <div>
                        <img src={col} alt='' />
                      </div>
                      <div>
                        <h6>Collections</h6>
                      </div>
                      
                    </div>
                    </NavLink>
                    </div>
          {/* Add more submenu items as needed */}
        </div>
      )}
        </li>
        <li className="nav-item">
          <NavLink to="/Solutions/Duo/Implementation" className="nav-link" activeClassName="sol-active">
          DUO Implementation
          </NavLink>
        </li>
       
        <li className="nav-item">
          <button className='sol-button'>Partner Sign in <i className="bi bi-chevron-right"></i></button>
        </li>
      </ul>
    </div>
    </div>
    
    </>
  )
}

export default Solution