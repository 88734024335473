import React from "react";
import hero from "../../../../assets/images/debt-hero.svg";

const Hero = () => {
  return (
    <div className="debt-hero">
      <div className="row">
        <div className="col">
          <h1>
            <span>Full Suite Debt Collections Management Platform</span> for
            lenders to collect from linked Bank Accounts
          </h1>
          <p>
            Simply integrate MoneyLink Debt Collection Platform to share data of
            defaulted loans for collection from all bank accounts customer has
            in the country.
          </p>
          <button>Contact Sales</button>
        </div>
        <div className="col debt-hero-2">
          <img src={hero} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
