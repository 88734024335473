import React from 'react' 
import faceauth3 from '../../../../assets/images/faceauth3.svg';
import faceauth2 from '../../../../assets/images/faceauth2.svg';
import faceauth1 from '../../../../assets/images/faceauth1.svg';


const Hero = () => {
  return (
    <>
    <div className='face-auth-hero'>
      <div className='row'>
        <div className='col'>
          <h1>Digital KYC & ID Verification </h1>
          <img className='face-auth-img1' src={faceauth3} alt='' />
          <img className='face-auth-img2' src={faceauth2} alt='' />
          <div className=''>
            <button>Explore Verifyed  KYC solution</button>
          </div>
        </div>
        <div className='col'>
        <img src={faceauth1} alt='' />
        </div>
      </div>
    </div>
    
    </>
  )
}

export default Hero