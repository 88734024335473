import React from "react";
import finance from "../../../assets/images/finance.svg";
import finance2 from "../../../assets/images/finance2.svg";
import finance3 from "../../../assets/images/finance3.svg";
import finance4 from "../../../assets/images/finance4.svg";
import finance5 from "../../../assets/images/finance5.svg";
import finance6 from "../../../assets/images/finance6.svg";
import financephone1 from "../../../assets/images/finance-phone1.svg";
import financephone2 from "../../../assets/images/finance-phone2.svg";
import loan6 from "../../../assets/images/loan6.svg";
import loan7 from "../../../assets/images/loan7.svg";
import naira2 from "../../../assets/images/naira2.svg";

const Body = () => {
  return (
    <>
      <div className="personal-page">
        <div className="finance-body">
          <div className="row">
            <div className="col finance-body-secction1-left">
              <h6>Loans</h6>
              <h2>
                <span>Compare loan offers</span> from our Lending Partners & get{" "}
                <span>instant disbursement</span>
              </h2>
              <img src={finance} alt="" />
            </div>
            <div className="col finance-body-secction1-right">
              <img src={financephone1} alt="" />
            </div>
          </div>

          <div className="payment-body-section-6 loan-offer">
            <h1>Why apply for loan on Montra?</h1>
            <div>
              <div className="row">
                <div className="col">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_631_17093)">
                      <mask
                        id="mask0_631_17093"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="36"
                        height="36"
                      >
                        <path d="M36 0H0V36H36V0Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_631_17093)">
                        <mask
                          id="mask1_631_17093"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="36"
                          height="36"
                        >
                          <path d="M36 0H0V36H36V0Z" fill="white" />
                        </mask>
                        <g mask="url(#mask1_631_17093)">
                          <path
                            d="M3.46875 13.5005L8.31491 8.6543"
                            stroke="#20336B"
                          />
                          <path
                            d="M3.46875 20.4238L8.31491 25.27"
                            stroke="#20336B"
                          />
                          <path
                            d="M15.9225 25.2698H4.15325C3.7709 25.2698 3.46094 24.9597 3.46094 24.5774V9.34661C3.46094 8.96426 3.7709 8.6543 4.15325 8.6543H31.8456C32.2278 8.6543 32.5379 8.96426 32.5379 9.34661V23.8851"
                            stroke="#20336B"
                          />
                          <path
                            d="M32.5415 13.5005L27.6953 8.6543"
                            stroke="#20336B"
                          />
                          <path
                            d="M10.5781 28.4473L11.7717 30.1171V33.5786"
                            stroke="#20336B"
                          />
                          <path
                            d="M13.1538 7.96157C13.1538 7.94357 13.1538 7.92696 13.1538 7.90896C13.1538 6.7619 12.224 5.83203 11.0769 5.83203C9.92987 5.83203 9 6.7619 9 7.90896C9 7.92696 9 7.94357 9 7.96157V8.65388"
                            stroke="#20336B"
                          />
                          <path
                            d="M17.3102 8.65373V6.5768C17.3102 6.5588 17.3102 6.54218 17.3102 6.52418C17.3102 5.37713 16.3802 4.44727 15.2331 4.44727C14.0861 4.44727 13.1562 5.37713 13.1562 6.52418C13.1562 6.54218 13.1562 6.5588 13.1562 6.5768V8.65373"
                            stroke="#20336B"
                          />
                          <path
                            d="M21.4585 8.65459V4.55336C21.4585 4.53536 21.4585 4.51874 21.4585 4.50074C21.4585 3.35369 20.5286 2.42383 19.3816 2.42383C18.2345 2.42383 17.3047 3.35369 17.3047 4.50074C17.3047 4.51874 17.3047 4.53536 17.3047 4.55336V8.65459"
                            stroke="#20336B"
                          />
                          <path
                            d="M25.6147 8.65373V6.5768C25.6147 6.5588 25.6147 6.54218 25.6147 6.52418C25.6147 5.37713 24.6849 4.44727 23.5378 4.44727C22.3908 4.44727 21.4609 5.37713 21.4609 6.52418C21.4609 6.54218 21.4609 6.5588 21.4609 6.5768V7.96142"
                            stroke="#20336B"
                          />
                          <path
                            d="M24.2319 33.5782V32.1935C24.2319 31.322 24.6423 30.5012 25.3397 29.9782L28.6628 27.4859C29.3601 26.9629 29.7705 26.1421 29.7705 25.2706V18.9968C29.7705 18.9871 29.7635 18.9788 29.7635 18.9691C29.7162 17.9261 28.9017 17.0803 27.8612 16.9936C26.8205 16.9069 25.8774 17.6063 25.6581 18.6271C25.5954 18.7415 25.5489 18.8641 25.5197 18.9913L25.1043 21.8533C24.9834 22.6987 24.4791 23.4412 23.7377 23.8651L21.4628 25.1653C20.6039 25.6612 20.0757 26.5786 20.0781 27.5704V29.4244"
                            stroke="#20336B"
                          />
                          <path
                            d="M12.9609 15.9863H23.0308"
                            stroke="#00BAF2"
                            stroke-linecap="square"
                          />
                          <path
                            d="M12.9609 18H23.0308"
                            stroke="#00BAF2"
                            stroke-linecap="square"
                          />
                          <path
                            d="M21.019 21.0208H20.012L15.9839 12.9648H14.9141V21.0208"
                            stroke="#00BAF2"
                            stroke-linecap="square"
                          />
                          <path
                            d="M21.0859 21.0208V12.9648"
                            stroke="#00BAF2"
                            stroke-linecap="square"
                          />
                        </g>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_631_17093">
                        <rect width="36" height="36" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <h6>Compare Loan Offers</h6>
                  <p>
                    Customers can apply for variety of Instant & Other loan
                    products offered by our Lending partners on Montra App
                  </p>
                </div>
                <div className="col">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_631_17116)">
                      <mask
                        id="mask0_631_17116"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="36"
                        height="36"
                      >
                        <path d="M36 0H0V36H36V0Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_631_17116)">
                        <path
                          d="M23.6366 28.3451L29.7704 26.3083C31.3085 25.8522 32.9675 26.4052 33.9243 27.6929C16.9918 36.1571 22.4707 36.1072 7.61659 31.1545L2.07812 34.616"
                          stroke="#20336B"
                        />
                        <path
                          d="M14.5397 29.0775H22.155C22.7088 29.1769 23.2686 28.937 23.5783 28.4672C23.8879 27.9974 23.8879 27.3884 23.5783 26.9186C23.2686 26.4488 22.7088 26.2089 22.155 26.3083H18.0012C16.36 24.615 14.1271 23.6226 11.7704 23.5391H7.61659L2.07812 26.3083"
                          stroke="#20336B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22.8499 18.4128C27.5521 18.4128 31.3639 14.6009 31.3639 9.89877C31.3639 5.19661 27.5521 1.38477 22.8499 1.38477C18.1478 1.38477 14.3359 5.19661 14.3359 9.89877C14.3359 14.6009 18.1478 18.4128 22.8499 18.4128Z"
                          stroke="#20336B"
                        />
                        <path
                          d="M18.4453 8.62109H27.2565"
                          stroke="#00BAF2"
                          stroke-linecap="square"
                        />
                        <path
                          d="M18.4453 10.3848H27.2565"
                          stroke="#00BAF2"
                          stroke-linecap="square"
                        />
                        <path
                          d="M25.498 13.0275H24.6169L21.0924 5.97852H20.1562V13.0275"
                          stroke="#00BAF2"
                          stroke-linecap="square"
                        />
                        <path
                          d="M25.5469 13.0277L25.6177 6.23047"
                          stroke="#00BAF2"
                          stroke-linecap="square"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_631_17116">
                        <rect width="36" height="36" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <h6>Instant Loan Disbursements</h6>
                  <p>
                    Upon successfully submitting loan applications for Instant
                    Loan products, Montra get the amount disbursed into
                    customers’ bank accounts instantly
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_631_17150)">
                      <mask
                        id="mask0_631_17150"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="36"
                        height="36"
                      >
                        <path d="M36 0H0V36H36V0Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_631_17150)">
                        <path d="M2.08594 7.61523H29.7782" stroke="#20336B" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.89303 4.5C5.70186 4.5 5.54688 4.65498 5.54688 4.84615C5.54688 5.03733 5.70186 5.19231 5.89303 5.19231C6.0842 5.19231 6.23918 5.03733 6.23918 4.84615C6.23918 4.65498 6.0842 4.5 5.89303 4.5Z"
                          stroke="#20336B"
                        />
                        <path
                          d="M9.3517 4.50004C9.21122 4.4978 9.08331 4.5807 9.02798 4.70986C8.97265 4.839 9.00088 4.98879 9.09942 5.08893C9.19798 5.18908 9.34728 5.21974 9.47731 5.1665C9.60734 5.11326 9.6923 4.98671 9.6923 4.8462C9.6923 4.65502 9.53734 4.50004 9.34616 4.50004"
                          stroke="#20336B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.8149 4.5C12.6237 4.5 12.4688 4.65498 12.4688 4.84615C12.4688 5.03733 12.6237 5.19231 12.8149 5.19231C13.0061 5.19231 13.1611 5.03733 13.1611 4.84615C13.1611 4.65498 13.0061 4.5 12.8149 4.5Z"
                          stroke="#20336B"
                        />
                        <path
                          d="M14.5397 22.8454H4.84736C3.31795 22.8454 2.07812 21.6056 2.07812 20.0762V4.8454C2.07812 3.316 3.31795 2.07617 4.84736 2.07617H27.0012C28.5306 2.07617 29.7704 3.316 29.7704 4.8454V10.3839"
                          stroke="#20336B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M27.6971 17.9985L29.8737 14.188C29.9961 13.9738 29.9952 13.7106 29.8714 13.4972C29.7476 13.2838 29.5195 13.1523 29.2728 13.1523H21.9731C21.7261 13.1519 21.4976 13.2831 21.3734 13.4966C21.2494 13.7101 21.2483 13.9736 21.3708 14.188L23.5433 17.9985C22.4494 18.7143 17.3125 20.6459 17.3125 26.3062C17.3798 28.4057 18.2831 30.3912 19.8217 31.8214C21.3603 33.2516 23.4065 34.0076 25.5053 33.9216C29.9125 33.9216 33.9279 30.7037 33.9279 26.3062C33.9279 20.8619 28.8962 18.9248 27.6971 17.9985Z"
                          stroke="#20336B"
                        />
                        <path
                          d="M21.2109 25.2363H30.0221"
                          stroke="#00BAF2"
                          stroke-linecap="square"
                        />
                        <path
                          d="M21.2109 27H30.0221"
                          stroke="#00BAF2"
                          stroke-linecap="square"
                        />
                        <path
                          d="M28.2637 29.6427H27.3825L23.8581 22.5938H22.9219V29.6427"
                          stroke="#00BAF2"
                          stroke-linecap="square"
                        />
                        <path
                          d="M28.3125 29.6429L28.3833 22.8457"
                          stroke="#00BAF2"
                          stroke-linecap="square"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_631_17150">
                        <rect width="36" height="36" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <h6>Track Loan Applications</h6>
                  <p>
                    Customers can track the status of the loans applied for, on
                    Montra Personal app to see their status
                  </p>
                </div>
                <div className="col">
                  <img src={naira2} alt="" />
                  <h6>Make Loan Repayments</h6>
                  <p>
                    Customers can initiate payment of Due monthly installments
                    on Montra Personal app
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                  >
                    <mask
                      id="mask0_631_17181"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="36"
                      height="36"
                    >
                      <path d="M36 0H0V36H36V0Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0_631_17181)">
                      <path
                        d="M29.25 0.750539H9.75C7.67893 0.750539 6 2.42948 6 4.50054V29.4546C5.99964 31.6865 4.94885 33.7881 3.1635 35.1276L3 35.2506H22.5L22.6635 35.1276C24.4488 33.7881 25.4997 31.6865 25.5 29.4546V4.71504C25.4585 2.73749 26.9039 1.04196 28.863 0.770039C29.9193 0.660441 30.9726 1.00388 31.7613 1.71507C32.55 2.42628 33.0001 3.43854 33 4.50054V9.75054C33 10.579 32.3284 11.2505 31.5 11.2505H25.5"
                        stroke="#20336B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.75 6.75H13.5"
                        stroke="#00BAF2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.75 11.25H21"
                        stroke="#00BAF2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.75 15.75H21"
                        stroke="#00BAF2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.75 20.25H21"
                        stroke="#00BAF2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.75 24.75H16.5"
                        stroke="#00BAF2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.75 24.75H21"
                        stroke="#00BAF2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.75 29.25H16.5"
                        stroke="#00BAF2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                  <h6>View Loan Statements</h6>
                  <p>
                    Customers can view Loan Statements for all the loans they
                    have applied for on Montra Personal app
                  </p>
                </div>
                <div className="col">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_631_17134)">
                      <mask
                        id="mask0_631_17134"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="36"
                        height="36"
                      >
                        <path d="M36 0H0V36H36V0Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_631_17134)">
                        <path
                          d="M25.2813 26.9766H6.59375"
                          stroke="#20336B"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15.9375 29.2637C16.136 29.2637 16.2969 29.4099 16.2969 29.5904C16.2969 29.7707 16.136 29.9169 15.9375 29.9169C15.739 29.9169 15.5781 29.7707 15.5781 29.5904C15.5781 29.4099 15.739 29.2637 15.9375 29.2637Z"
                          stroke="#20336B"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M25.2813 21.0973V29.5899C25.2813 31.033 23.994 32.2029 22.4063 32.2029H9.46875C7.88093 32.2029 6.59375 31.033 6.59375 29.5899V4.76544C6.59375 3.32226 7.88093 2.15234 9.46875 2.15234H18.0938"
                          stroke="#20336B"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M33.1875 13.9113C31.9001 12.7526 31.1316 11.2008 31.0313 9.55655V7.37853C31.0313 4.49219 28.457 2.15234 25.2813 2.15234C22.1057 2.15234 19.5312 4.49219 19.5312 7.37853V9.55655C19.4309 11.2008 18.6625 12.7526 17.375 13.9113H33.1875Z"
                          stroke="#24BAF2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M23.4297 16.5254C23.6449 17.2937 24.406 17.8314 25.2784 17.8314C26.1507 17.8314 26.9118 17.2937 27.127 16.5254"
                          stroke="#24BAF2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.75 9.75H15.75"
                          stroke="#20336B"
                          stroke-linecap="square"
                        />
                        <path
                          d="M9.75 12.75H14.25"
                          stroke="#20336B"
                          stroke-linecap="square"
                        />
                        <path
                          d="M9.75 15.75H14.25"
                          stroke="#20336B"
                          stroke-linecap="square"
                        />
                        <path
                          d="M9.75 18.75H18.75"
                          stroke="#20336B"
                          stroke-linecap="square"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_631_17134">
                        <rect width="36" height="36" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <h6>Get Payment Reminders</h6>
                  <p>
                    Montra sends alerts reminding Customers to pay against the
                    due & delinquent amounts for them to avoid paying penal
                    charges
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bank-body-section-2 loan-offer2">
            <div style={{ width: "100%" }}>
              <h1>Open an account in few minutes in these easy steps:</h1>
              <div className="row">
                <div className="col finance-body-secction1-right">
                  <img src={financephone1} alt="" />
                </div>
                <div
                  className="col loan-offer-2-2"
                  style={{ marginLeft: "70px" }}
                >
                  <div className="open-account">
                    <div className="open-acct-count">1</div>
                    <div>
                      <h6>Download Montra App</h6>
                      <p>
                        You can download Montra App from Google & IOS App
                        stores.
                      </p>
                    </div>
                  </div>
                  <div className="open-account">
                    <div className="open-acct-count">2</div>
                    <div>
                      <h6>Sign Up</h6>
                      <p>
                        Sign up with your mobile number and create your profile
                        once your mobile number is verified.
                      </p>
                    </div>
                  </div>
                  <div className="open-account">
                    <div className="open-acct-count">3</div>
                    <div>
                      <h6>Verify your details</h6>
                      <p>
                        Submit your verified liveness profile picture followed
                        by verification of your BVN or NIN.
                      </p>
                    </div>
                  </div>
                  <div className="open-account">
                    <div className="open-acct-count">4</div>
                    <div>
                      <h6>Your Montra account is now ready!!!</h6>
                      <p>
                        You can start using your Montra account based on any KYC
                        Level. You can upgrade your KYC Level based on further
                        verification of your ID & residence address.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row loan3">
          <div className="col finance-body-secction1-left">
            <h6>Insurance</h6>
            <h2>
              <span>Compare Insurance Policy Premiums & Features</span> to buy
              the most suitable plan from our Insurance Partners
            </h2>
            <img src={finance2} alt="" />
          </div>
          <div className="col finance-body-secction1-right">
            <img src={financephone2} alt="" />
          </div>
        </div>
        <div className="payment-body-section-5 loan4">
          <h1>Why buy Insurance Plans on Montra?</h1>
          <div>
            <div className="row">
              <div className="col">
                <img src={finance3} alt="" />
                <h6>Compare Insurance Plans</h6>
                <p>
                  Customers can apply for a variety of Insurance Products and
                  compare the plans for the premium amounts and features towards
                  making a more informed decision.
                </p>
              </div>
              <div className="col">
                <img src={finance4} alt="" />
                <h6>View Policy Details</h6>
                <p>
                  Customers can find details of all the polices purchased on
                  Montra Personal app in their pocket
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <img src={finance5} alt="" />
                <h6>Renew Insurance Policies</h6>
                <p>
                  Customers can easily renew the existing policies on Montra
                  Personal app
                </p>
              </div>
              <div className="col">
                <img src={finance6} alt="" />
                <h6>Make Payments </h6>
                <p>
                  Customers get a variety of Payment Methods to pay for
                  Insurance premiums
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bank-body-section-2 loan-offer2 loan5">
          <div style={{ width: "100%" }}>
            <h1>
              Buy Insurance Plan in few minutes on Montra App in these easy
              steps:{" "}
            </h1>
            <div className="row">
              <div className="col-3">
                <img src={financephone2} />
              </div>
              <div className="col" style={{ marginLeft: "70px" }}>
                <div className="open-account">
                  <div className="open-acct-count">1</div>
                  <div>
                    <h6>Select an Insurance Plan</h6>
                    <p>
                      Select one of the Insurance Products available on Montra
                      App based on your requirement
                    </p>
                  </div>
                </div>
                <div className="open-account">
                  <div className="open-acct-count">2</div>
                  <div>
                    <h6>
                      Compare Insurance Premiums for our Insurance Partners
                    </h6>
                    <p>
                      Montra would instantly offer comparison for Insurance
                      Premiums & Features for our Insurance Partners
                    </p>
                  </div>
                </div>
                <div className="open-account">
                  <div className="open-acct-count">3</div>
                  <div>
                    <h6>
                      Choose the Insurance Policy you want to buy & make payment
                    </h6>
                    <p>
                      Select a Insurance Policy you want to buy and proceed for
                      making Payment using a variety of payment methods
                      available on Montra
                    </p>
                  </div>
                </div>
                <div className="open-account">
                  <div className="open-acct-count">4</div>
                  <div>
                    <h6>Get Confirmation on issuance of Insurance Policy</h6>
                    <p>
                      Upon making the payment successfully, Montra would confirm
                      the the issuance of the Policy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="loan6">
          <div className="row">
            <div className="col">
              <h6>Investments</h6>
              <h1>
                <span>Compare best Investment plans</span> available towards
                maximizing your returns
              </h1>
              <img className="loan6-img-img" src={loan6} alt="" />
            </div>
            <div className="col loan6-img">
              <img src={loan7} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
