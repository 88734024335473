import React from "react";

const YU3 = ({ className }) => {
  return (
    <svg
      width="480"
      height="366"
      viewBox="0 0 480 366"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 196H48M288 1.5H365.5C373 1.5 384.5 7.1 384.5 17.5C384.5 27.9 384.5 106.833 384.5 145M288 119.5H300.5C304.5 119.5 311.5 121.2 311.5 134C311.5 146.8 311.5 160.833 311.5 171C311.667 175.667 314.1 185 322.5 185C330.9 185 335 185 336 185M336 196C335.833 196 332.9 196 322.5 196C314.1 196 311.667 202 311.5 205C311.5 211.833 311.5 228 311.5 238C311.5 248 305 249.5 300.5 249.5H288M288 364.5H361C368.833 363.833 384.5 359 384.5 345C384.5 331 384.5 269.833 384.5 241M432 196H480M144 174H155C157.667 173.833 162.5 171.3 162.5 164.5C162.5 157.7 161 61.1667 161 13.5C161 8 166.8 1.5 176 1.5C185.2 1.5 190.5 1.5 192 1.5M144 185H162.5C167.5 185 174 181.4 174 171C174 160.6 174.5 139.167 174.5 129C174.5 122 177.9 119.5 185.5 119.5C193.1 119.5 193 119.5 192 119.5M144 196H163.5C173 196 174.5 205.6 174.5 218C174.5 230.4 174.5 238 174.5 239.5C174.5 242 175.9 249.5 183.5 249.5C191.1 249.5 192.333 249.5 192 249.5M144 207H155C157.5 206.833 162.5 208.8 162.5 218C162.5 227.2 162.5 309.167 162.5 349C162.333 354.167 165.2 364.5 178 364.5C190.8 364.5 192.667 364.5 192 364.5"
        stroke="url(#paint0_radial_3288_8323)"
        stroke-width="1.5"
        className="yu-path"
        pathLength="1"
      />
      <defs>
        <radialGradient
          id="paint0_radial_3288_8323"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(240 183) rotate(90) scale(181.5 240)"
        >
          <stop stop-color="#B38DFF" />
          <stop
            offset="1"
            stop-color="#FD7FAE"
          />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default YU3;
