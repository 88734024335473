import React from 'react'
import Navbar from "../../Navbar/Company";
import Footer from '../../Footer/Main'
import './style.css';
import Body from './Body';
import Hero from './Hero';

const Main = () => {
  return (
    <>
    <Navbar />
    <Hero />
    <Body />
    <Footer />
    
    </>
  )
}

export default Main