import React from "react";
import businesshero from "../../../assets/images/businesshero.svg";
import businesshero2 from "../../../assets/images/businesshero2.svg";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";

const Hero = () => {
  return (
    <>
      <div className="business-hero">
        <div className="row">
          <div className="col">
            <h1>
            <span>Accelerate business growth with</span> Payment acceptance & business management solutions from Montra
            </h1>
            <p>We will help you achieve your business goals</p>
            <img src={businesshero2} alt="" />
            <div className="app-download">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
          </div>
          <div className="col">
            <img className="business-hero-img" src={businesshero} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
