import React, { useState, useEffect } from "react";

import personalimg1 from "../../../assets/images/personalimg1.svg";
import personalimg2 from "../../../assets/images/personalimg2.svg";
import P2S from "../../../assets/images/P2S.svg";
import CompareLoanOffers from "../../../assets/images/CompareLoanOffers.svg";
import settlement from "../../../assets/images/settlement.svg";
import viewpolicy from "../../../assets/images/viewpolicy.svg";
import Renewinsurancepolicy from "../../../assets/images/Renewinsurancepolicy.svg";
import Lending from "../../../assets/images/lending1.svg";
import loanmanagement from "../../../assets/images/loanmanagement.svg";
import CompareInvest from "../../../assets/images/CompareInvest.svg";
import powerfuldashboard from "../../../assets/images/powerfuldashboard.svg";
import Transactionhistory from "../../../assets/images/Transactionhistory.svg";
import anym from "../../../assets/images/anym.svg";
import PaymentConfirmation from "../../../assets/images/payment-confirmation.svg";
import DebitedFromMontraIcon from "../../../assets/images/Debited from montra icon.svg";
import SP from "../../../assets/images/Group 60635.svg";
import BT from "../../../assets/images/Group 89.svg";
import Pricing from "../../../assets/images/pricing.svg";
import LC from "../../../assets/images/Link cards.svg";
import LB from "../../../assets/images/Change-the-collection-Bank-account-linked.svg";
import P2P from "../../../assets/images/P2P.svg";
import RG from "../../../assets/images/refer & get.svg";
import "./style.css";
import { Animation1, Animation2, Animation3, Animation4, Animation5, Animation6 } from "./_animations";


const Main = () => {
  const [anim, setAnim] = useState(1);
  const [anim2, setAnim2] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnim((prev) => {
        return prev === 3 ? 1 : prev + 1;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [])
  useEffect(() => {
    const interval = setInterval(() => {
      setAnim2((prev) => {
        return prev === 3 ? 1 : prev + 1;
      });
    }, 4000);

    return () => clearInterval(interval);
  }, [])


  return (
    <>
      <div className="home-personal">
        <div className=" home-container">
          <div className="row">
            <div className="col col-lg-5">
              <div className="home-personal-left">
                <div className="left-1 home-left-1">
                  <h6>Montra - Personal</h6>
                  <h1>
                    Secure way of accessing <span>world of Payments</span>
                  </h1>
                  <p>
                    Secured with 2FA through your Montra Soft Token, you can now
                    pay using variety of Payment methods using Montra Accounts,
                    Bank Accounts & Cards you have linked on Montra App and
                    Installments by borrowing loans from our Lending Partners.
                  </p>
                  <button>
                    Explore Payments <i class="bi bi-chevron-right"></i>
                  </button>
                  <div>
                    <img className="direction-img" src={personalimg1} alt="" />
                  </div>
                  <p>
                    Simply scan the QR codes of our Merchants or Tap & Pay on
                    our Merchant’s app with your Debit & Credit Cards or use
                    your Debit & Credit cards on POS issued by Montra to our
                    merchants.
                  </p>
                </div>
              </div>
            </div>
            <div className="col ">
              <div className="yu-flex yu-relative">
                <div className={`yu-mr`}>
                    <div className="ske">
                      <img src={P2S} alt="" />
                      <p>Montra Customers</p>
                    </div>
                </div>
                <div className={`yu-mr`}>
                    <div className="ske">
                      <img src={anym} alt="" />
                      <p>Mobile or Contact</p>
                    </div>
                    <div className="ske">
                        <img src={SP} alt="" />
                        <p>Scan & Pay</p>
                    </div>
                    <div className="ske">
                        <img src={BT} alt="" />
                        <p>Bank Transfer</p>
                    </div>
                </div>
                <div className={`yu-mr`}>
                    <div className="ske">
                      <img src={DebitedFromMontraIcon} alt="" />
                      <p>Montra Account</p>
                    </div>
                  <div className="ske">
                      <img src={Pricing} alt="" />
                      <p>Linked Bank</p>
                  </div>
                  <div className="ske">
                      <img src={LC} alt="" />
                      <p>Linked Cards</p>
                  </div>
                  <div className="ske">
                      <img src={LB} alt="" />
                      <p>Installments</p>
                  </div>
                </div>
                <div className={`yu-mr`}>
                  <div className="ske">
                    <img src={PaymentConfirmation} alt="" />
                    <p>Payout Authorization</p>
                  </div>
                </div>
                <div className={`yu-mr`}>
                  <div className="ske yu-mt">
                      <img src={P2S} alt="" />
                      <p>Montra Customers</p>
                  </div>
                  <div className="ske yu-mt" >
                      <img src={P2P} alt="" />
                      <p>Montra Merchants</p>
                  </div>
                  <div className="ske yu-mt">
                      <img src={RG} alt="" />
                      <p>Other Customers</p>
                  </div>
                </div>
                <div>
                  {anim === 1 && <Animation1 />}
                  {anim === 2 && <Animation2 />}
                  {anim === 3 && <Animation3 />}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="home-personal-left">
                <div className="left-2 left">
                  <h1>
                    <span>Compare loan offers</span> from our lending partners &
                    get <span>instant disbursal to your bank account</span>
                  </h1>
                  <p>
                    Explore and compare diverse loan offers from our trusted
                    Lending Partners, giving you a range of options tailored to
                    your needs. Once you've found the right fit, enjoy the
                    convenience of instant disbursement, ensuring funds are
                    swiftly deposited into your bank account
                  </p>
                  <button>
                    Explore Lending <i class="bi bi-chevron-right"></i>
                  </button>
                  <div>
                    <img className="direction-img" src={personalimg2} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="yu-flex yu-relative">
                <div className="yu-mr-2">
                  <div className="ske">
                      <img src={settlement} alt="" />
                      <p>Lending Partners</p>
                  </div>
                  <div className="ske mt66">
                    <img src={P2S} alt="" />
                    <p>Montra Customers</p>
                  </div>
                </div>
                <div className="yu-mr-2">
                  <div className="ske">
                    <img src={CompareLoanOffers} alt="" />
                    <p>Loan Eligibility Comparison</p>
                    </div>
                    <div className="ske mt112">
                      <img src={Transactionhistory} alt="" />
                      <p>Loan Statement</p>
                    </div>
                </div>
                <div className="yu-mr-2">
                  <div className="ske">
                    <img src={viewpolicy} alt="" />
                    <p>Loan Offer Acceptance</p>
                  </div>
                  <div className="ske mt112">
                    <img src={CompareInvest} alt="" />
                    <p>Loan Repayment</p>
                  </div>
                  <div className="ske mt43">
                    <img src={powerfuldashboard} alt="" />
                    <p>Loan Mgt. Service</p>
                  </div>
                </div>
                <div className="yu-mr-2">
                  <div className="ske">
                    <img src={Renewinsurancepolicy} alt="" />
                    <p>Loan Authorization</p>
                  </div>
                  <div className="ske mt50">
                    <img src={Lending} alt="" />
                    <p>Instant Loan Disbursement</p>
                  </div>
                  
                </div>
                <div className="yu-mr-2">
                  <div className="ske">
                    <img src={loanmanagement} alt="" />
                    <p>Loan Mgt. System</p>
                  </div>
                </div>
                <div>
                  {anim2 === 1 && <Animation4 />}
                  {anim2 === 2 && <Animation5 />}
                  {anim2 === 3 && <Animation6 />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
