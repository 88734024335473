import React from "react";

function LoanEligibilityComparison() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_3447_7521)">
        <mask
          id="mask0_3447_7521"
          style={{ maskType: "luminance" }}
          width="24"
          height="24"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M24 0H0v24h24V0z"></path>
        </mask>
        <g mask="url(#mask0_3447_7521)">
          <path
            stroke="#20336B"
            d="M2.313 9l3.23-3.23M2.313 13.615l3.23 3.231M10.616 16.846H2.77a.462.462 0 01-.461-.461V6.23c0-.255.206-.461.461-.461h18.462c.255 0 .461.206.461.461v9.692M21.696 9l-3.231-3.23M7.055 18.963l.795 1.113v2.308"
          ></path>
          <path
            stroke="#20336B"
            d="M8.77 5.308v-.035a1.385 1.385 0 10-2.77 0v.497"
          ></path>
          <path
            stroke="#20336B"
            d="M11.539 5.77V4.384v-.036a1.385 1.385 0 00-2.77 0v1.42"
          ></path>
          <path
            stroke="#20336B"
            d="M14.308 5.77V3.034 3a1.385 1.385 0 10-2.769 0v2.769"
          ></path>
          <path
            stroke="#20336B"
            d="M17.078 5.77V4.384v-.036a1.385 1.385 0 10-2.77 0V5.308M16.156 22.384v-.924c0-.58.274-1.128.738-1.477l2.216-1.661c.465-.349.738-.896.738-1.477v-4.182c0-.007-.004-.012-.004-.019a1.385 1.385 0 00-2.737-.228.947.947 0 00-.093.243l-.276 1.908a1.846 1.846 0 01-.912 1.341l-1.516.867c-.573.33-.925.942-.923 1.603v1.236"
          ></path>
          <path
            stroke="#00BAF2"
            strokeLinecap="square"
            d="M8.645 10.656h6.713M8.645 12h6.713M14.015 14.013h-.671l-2.685-5.37h-.714v5.37M14.059 14.013v-5.37"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3447_7521">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LoanEligibilityComparison;
