import React from 'react'
import Navbar from '../Navbar/Main'
import { HashLink as Link } from "react-router-hash-link";
import { NavLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import './product.css';

const Productnav = () => {
  return (
    <>
    <div className='product-nav'>
      <Navbar />
    </div>
    <div className='second-navbar navbar'>
      <div className='swtich-btn'>
        <div className='personal-btn'>
          <button className='active-swtich-btn'>Personal</button>
        </div>
        <div className=''>
        <NavLink to="/Business/overview" className="nav-link">
          <button className='nonactive-swtich-btn'>Business</button>
          </NavLink>
        </div>
      </div>


      <div className="nav-link">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <NavLink  to="/Products/overview" className="nav-link" activeClassName="active">
            Overview
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/Products/Banking" className="nav-link" activeClassName="active">
            Banking
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/Products/Payment" className="nav-link" activeClassName="active">
            Payments
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/Products/Finance" className="nav-link" activeClassName="active">
            Finance
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/Products/Chat" className="nav-link" activeClassName="active">
            Chat
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/Products/Shop" className="nav-link" activeClassName="active">
            Shop
          </NavLink>
        </li>
        <li className="nav-item">
          <button>Sign In <i className="bi bi-chevron-right"></i></button>
        </li>
      </ul>
    </div>
    </div>
    
    </>
  )
}

export default Productnav