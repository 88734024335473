import React from 'react'
import Finance from '../components/Personal/Finance/Main'

const FinanceRoute = () => {
  return (
   <>
   <Finance />
   </>
  )
}

export default FinanceRoute