import React from "react";
import businesspaymenthero1 from "../../../assets/images/businesspaymenthero1.svg";
import businesspaymenthero2 from "../../../assets/images/businesspaymenthero2.svg";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";

const Hero = () => {
  return (
    <>
      <div className="business-payment">
        <div className="row">
          <div className="col">
            <h1>
              <span>Grow your business</span> with Montra Business Account & <span>start accepting
              Payments from everyone</span>
            </h1>
            <p>
              Upgrade your business to let Montra help you achieve your business
              goals
            </p>
            <img src={businesspaymenthero1} alt="" />
            <div className="app-download">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
          </div>
          <div className="col">
            <img src={businesspaymenthero2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
