import React from 'react'
import loansack from '../../../../assets/images/loansack.svg';
import lms1 from '../../../../assets/images/lms1.svg';

const Hero = () => {
  return (
    <div className='lms-hero'>
        <div className='row'>
            <div className='col'>
                <h1><span>Most complete cloud-based lending ecosystem</span> enabling Lenders creating profitable Loan Portfolios</h1>
                <p>MoneyLink cloud-based loan management system helps lenders Originate & Disburse loans instantly, Manage them over the life-cycle, Collect due & delinquent amounts from settlement as well as other bank accounts the customers have across multiple banks</p>
            
                <img src={lms1} alt='' />
                <div>
                    <button>Contact Sales</button>
                </div>
            </div>
            <div className='col lms-hero-2'>
                <img src={loansack} alt='' />
            </div>
        </div>
    </div>
  )
}

export default Hero