import React from 'react';
import Business from '../components/Business/Home/Main';

const BusinessRoute = () => {
  return (
      <Business />
  );
};

export default BusinessRoute;
