import React from 'react'
import faceauth5 from '../../../../assets/images/faceauth5.svg';
import verifiedicon1 from "../../../../assets/images/verifiedicon1.svg";
import verifiedicon2 from "../../../../assets/images/verifiedicon2.svg";
import verifiedicon3 from "../../../../assets/images/verifiedicon3.svg";
import verifiedicon4 from "../../../../assets/images/verifiedicon4.svg";

const Body = () => {
  return (
    <>
    <div className='face-auth-body'>
      <h1>Fast & Safe onboarding in seconds</h1>

      <div className='row'>
       
        <div className="col">
        <div className="veri-part">
                <div className="col ">
                  <div className="verified-part">
                    <div className="verified-part-text verified-part-text-text faceauth">
                      <div>
                        <img src={verifiedicon1} alt="image" />
                      </div>

                      <div>
                        <p>Setup</p>
                        <span>
                          Easy & self-service onboarding to setup 2FA for any
                          account
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="verified-part">
                    <div className="verified-part-text verified-part-text-text">
                      <div>
                        <img src={verifiedicon2} alt="image" />
                      </div>

                      <div>
                        <p>Secure</p>
                        <span>
                          TouchID, Encrypted Backups and more to keep you secure
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="veri-part">
                <div className="col ">
                  <div className="verified-part">
                    <div className="verified-part-text verified-part-text-text">
                      <div>
                        <img src={verifiedicon3} alt="image" />
                      </div>

                      <div>
                        <p>Backup</p>
                        <span>
                          Prevents account from getting locked when you lose
                          your phone
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="verified-part">
                    <div className="verified-part-text verified-part-text-text">
                      <div>
                        <img src={verifiedicon4} alt="image" />
                      </div>

                      <div>
                        <p>Multiple Devices</p>
                        <span>
                          Sync Verifyed 2FA across mobile, tablet and desktop
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

        <div className='col'>
        <img src={faceauth5} alt='' />
        </div>
      </div>
    </div>
    </>
  )
}

export default Body