import React from "react";
import "../Overview/style.css";
import dtshero from "../../../../assets/images/dtshero.svg";

const Hero = () => {
  return (
    <>
      <div className="dts-hero">
        <div className="row">
          <div className="col duo-col">
            <h1>
              <span>Switch to credit with DUO</span> An Innovation enabling <span>credit card usage
              from any existing debit card</span>
            </h1>
            <div className="duo-list-container">
              <div className="duo-lists">
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      DUO comes with revolutionary Dual Transaction Service
                      (DTS) a patented product that enables you to access Credit
                      pre-approved by your bank/ Issuer instantly through your
                      existing Debit Cards.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Now you don’t have to apply for a Credit Card by
                      submitting application form & documents or even visit a
                      bank branch to collect it once it is approved.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Simply activate DTS by registering on DUO App introduced
                      by your bank and start accessing your Credit Card
                      instantly from the existing Debit Card that you already
                      have
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <button>Contact Sales</button>
          </div>
          <div className="col duo-col">
            <img src={dtshero} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
