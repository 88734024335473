import React from "react";
import verifiedlogo from "../../../assets/images/veryfied-logo.svg";
import verifiedimg1 from "../../../assets/images/verifiedimg1.svg";
import verifiedimg2 from "../../../assets/images/verifiedimg2.svg";
import verifiedbar from "../../../assets/images/verifiedbar.svg";
import verifiedicon1 from "../../../assets/images/verifiedicon1.svg";
import verifiedicon2 from "../../../assets/images/verifiedicon2.svg";
import verifiedicon3 from "../../../assets/images/verifiedicon3.svg";
import verifiedicon4 from "../../../assets/images/verifiedicon4.svg";
import facescan from "../../../assets/images/verifyimg.svg";

const Main = () => {
  return (
    <>
      <div className="verified">
        <div className="row">
          <div className="col">
            <h6>Solutions</h6>
            <img src={verifiedlogo} alt="image" />
            <h1>
              Soft Token  <span>158 107</span> 
            </h1>
            <p className="veri-p1">
              With Verifyed Authenticator, turn on 2-Step Verification when you
              enable 2-Step Verification (also known as two-factor
              authentication), you add an extra layer of security to your
              account.
            </p>
            <button className="verified-btn1">
              Explore Verifyed <i class="bi bi-chevron-right"></i>
            </button>
            <img className="verifiedimg-1" src={verifiedimg1} alt="image" />
          </div>
          <div className="col">
            <div className="row">
              <div className="col ">
                <div className="verified-part">
                  <div className="">
                    <img src={verifiedbar} alt="image" />
                  </div>
                  <div className="verified-part-text">
                    <img src={verifiedicon1} alt="image" />
                    <p>Setup</p>
                    <span>
                      Easy & self-service onboarding to setup 2FA for any
                      account
                    </span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="verified-part">
                  <div className="">
                    <img src={verifiedbar} alt="image" />
                  </div>
                  <div className="verified-part-text">
                    <img src={verifiedicon2} alt="image" />
                    <p>Secure</p>
                    <span>
                    TouchID, Encrypted Backups and more to keep you secure
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col ">
                <div className="verified-part">
                  <div className="">
                    <img src={verifiedbar} alt="image" />
                  </div>
                  <div className="verified-part-text">
                    <img src={verifiedicon3} alt="image" />
                    <p>Backup</p>
                    <span>
                    Prevents account from getting locked when you lose your phone
                    </span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="verified-part">
                  <div className="">
                    <img src={verifiedbar} alt="image" />
                  </div>
                  <div className="verified-part-text">
                    <img src={verifiedicon4} alt="image" />
                    <p>Multiple Devices</p>
                    <span>
                    Sync Verifyed 2FA across mobile, tablet and desktop
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h3>Digital KYC & ID Verification</h3>
            <p className="veri-p1">
              With Verifyed Authenticator, Turn on 2-Step Verification when you
              enable 2-Step Verification (also known as two-factor
              authentication), you add an extra layer of security to your
              account.
            </p>
            <button className="verifiedbtn-2">Explore Verifyed  KYC solution <i class="bi bi-chevron-right"></i></button>
            <img className="verifiedimg-2" src={verifiedimg2} alt="" />
          </div>
          <div className="col">
            <img src={facescan} className="face-scan-img" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
