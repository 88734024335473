import React from "react";

function POS() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#20336B"
        fillRule="evenodd"
        d="M16.928 29.803h-6.087a1.4 1.4 0 00-1.4 1.4v1.215a.4.4 0 00.8 0v-1.215a.6.6 0 01.6-.6h6.087v-.8z"
        clipRule="evenodd"
      ></path>
      <rect
        width="17.052"
        height="32.224"
        x="8.051"
        y="0.799"
        stroke="#20336B"
        rx="1.5"
      ></rect>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeWidth="0.6"
        d="M10.54 2.809h12.292"
      ></path>
      <rect
        width="11.921"
        height="8.939"
        x="10.488"
        y="5.229"
        stroke="#20336B"
        rx="0.5"
      ></rect>
      <rect
        width="3.93"
        height="2.601"
        x="9.797"
        y="17.713"
        fill="#fff"
        stroke="#20336B"
        strokeWidth="0.5"
        rx="0.55"
      ></rect>
      <rect
        width="3.93"
        height="2.601"
        x="14.668"
        y="17.713"
        fill="#fff"
        stroke="#20336B"
        strokeWidth="0.5"
        rx="0.55"
      ></rect>
      <rect
        width="3.93"
        height="2.601"
        x="19.652"
        y="17.713"
        fill="#fff"
        stroke="#20336B"
        strokeWidth="0.5"
        rx="0.55"
      ></rect>
      <rect
        width="3.93"
        height="2.601"
        x="9.797"
        y="21.59"
        fill="#fff"
        stroke="#20336B"
        strokeWidth="0.5"
        rx="0.55"
      ></rect>
      <rect
        width="3.93"
        height="2.601"
        x="14.668"
        y="21.59"
        fill="#fff"
        stroke="#20336B"
        strokeWidth="0.5"
        rx="0.55"
      ></rect>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeWidth="0.5"
        d="M19.793 24.108v-1.836a.6.6 0 01.6-.6h2.372a.6.6 0 01.6.6V23"
      ></path>
      <rect
        width="3.93"
        height="2.601"
        x="9.797"
        y="25.467"
        fill="#fff"
        stroke="#20336B"
        strokeWidth="0.5"
        rx="0.55"
      ></rect>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeWidth="0.5"
        d="M18.418 25.547h-3.064a.6.6 0 00-.6.6v1.236a.6.6 0 00.6.6h1.061"
      ></path>
      <rect
        width="3.93"
        height="2.601"
        x="19.652"
        y="25.467"
        fill="#fff"
        stroke="#20336B"
        strokeWidth="0.5"
        rx="0.75"
      ></rect>
      <path
        fill="#20336B"
        d="M8.104 16.246v.4h.4v-.4h-.4zm0-12.625h.4v-.4h-.4v.4zm-1.704 1a.6.6 0 01.6-.6v-.8a1.4 1.4 0 00-1.4 1.4h.8zm0 10.625V4.621h-.8v10.625h.8zm.6.6a.6.6 0 01-.6-.6h-.8a1.4 1.4 0 001.4 1.4v-.8zm1.104 0H7v.8h1.104v-.8zm.4.4V3.621h-.8v12.625h.8zM7 4.021h1.104v-.8H7v.8zM25.047 16.246v.4h-.4v-.4h.4zm0-12.625h-.4v-.4h.4v.4zm1.592 1a.6.6 0 00-.6-.6v-.8a1.4 1.4 0 011.4 1.4h-.8zm0 10.625V4.621h.8v10.625h-.8zm-.6.6a.6.6 0 00.6-.6h.8a1.4 1.4 0 01-1.4 1.4v-.8zm-.992 0h.992v.8h-.992v-.8zm-.4.4V3.621h.8v12.625h-.8zm1.392-12.225h-.992v-.8h.992v.8z"
      ></path>
      <circle
        cx="23.607"
        cy="29.646"
        r="5.641"
        fill="#fff"
        stroke="#20336B"
        strokeWidth="0.9"
      ></circle>
      <path
        fill="#3FAFE9"
        d="M23.628 32.71a.906.906 0 100-1.812.906.906 0 000 1.812z"
      ></path>
      <path
        fill="#3FAFE9"
        d="M25.793 29.621l-.042-.039-.04-.038-.023-.02a2.955 2.955 0 00-4.081.097l-.131.131-.182.182a.568.568 0 00.804.804l.313-.313a1.826 1.826 0 012.577 0l.226.218a.563.563 0 00.797-.796l-.218-.226z"
      ></path>
      <path
        fill="#3FAFE9"
        d="M27.336 28.504l-.188-.188-.003-.003-.148-.149a.54.54 0 00-.084-.068 4.93 4.93 0 00-6.66.154c-.01.008-.02.014-.028.023l-.34.34a.518.518 0 00.733.733l.34-.34.007-.007a3.892 3.892 0 015.37-.03l.268.268a.517.517 0 00.733-.733z"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeWidth="0.8"
        d="M16.41 30.203h-4.43"
      ></path>
    </svg>
  );
}

export default POS;
