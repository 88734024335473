import React from "react";

function QRScan() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <path
        stroke="#21366E"
        strokeLinecap="square"
        d="M19.249 26.8H3.445v-24h24v16.395"
      ></path>
      <path
        stroke="#3FAFE9"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M30.5 24l-3.146 4.873a.793.793 0 01-.593.375.755.755 0 01-.634-.273L24.5 27.088"
      ></path>
      <path
        stroke="#21366E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20 27a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#21366E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.305 20.832v1.23c0 .346.28.626.625.626h1.23M10.16 7.813H8.93a.625.625 0 00-.625.625v1.23M22.684 9.668v-1.23a.625.625 0 00-.625-.626h-1.23"
      ></path>
      <path
        stroke="#21366E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.43 11.188h3.375v3.374H11.43v-3.374zM17.68 11.188h3.375v3.374H17.68v-3.374zM11.43 17.438h3.375v3.375H11.43v-3.375z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#21366E"
        strokeLinecap="square"
        d="M17.746 17.25V18M17.746 20.25V21M20.746 20.25V21M20.746 17.25V18M19.246 18.75v.75"
      ></path>
    </svg>
  );
}

export default QRScan;
