import React from 'react';
import Personal from '../components/Personal/Home/Main';

const PersonalRoute = () => {
  return (
      <Personal />
  );
};

export default PersonalRoute;
