import React from 'react'
import shophero2 from "../../../assets/images/shophero2.svg";
import shophero1 from "../../../assets/images/shophero1.svg";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";

const Hero = () => {
  return (
   <>
   <div className='shop-hero'>
    <div className='row'>
        <div className='col-6'>
            <h1>Connect to sellers on Montra app for exciting deals </h1>
            <p>Montra shoppers can find what they are looking for online and in person. From delivering fresh produce to their doorstep to creating and distributing movies, music, and more, we are always finding new ways to delight our customers.</p>
            <img src={shophero2} alt='' />
            <div className="app-download">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
        </div>
        <div className="col-6 shop-hero-right">
            <img src={shophero1} alt='' />
        </div>
    </div>
   </div>
   </>
  )
}

export default Hero