import React from "react";

function MontraAccount() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect
        width="20.563"
        height="16.25"
        x="1"
        y="5.813"
        stroke="#20336B"
        rx="1.5"
      ></rect>
      <path
        stroke="#20336B"
        d="M6.73 5.792l7.2-4.5a.5.5 0 01.713.2l.712 1.425M9.605 5.792l7.656-3.602a.5.5 0 01.68.277l1.248 3.325"
      ></path>
      <mask id="path-4-inside-1_3288_8228" fill="#fff">
        <path d="M13.918 13.063a2 2 0 012-2h7.083a.5.5 0 01.5.5v4.75a.5.5 0 01-.5.5h-7.083a2 2 0 01-2-2v-1.75z"></path>
      </mask>
      <path
        stroke="#20336B"
        strokeWidth="2"
        d="M13.918 13.063a2 2 0 012-2h7.083a.5.5 0 01.5.5v4.75a.5.5 0 01-.5.5h-7.083a2 2 0 01-2-2v-1.75z"
        mask="url(#path-4-inside-1_3288_8228)"
      ></path>
      <circle
        cx="17.03"
        cy="13.889"
        r="0.848"
        stroke="#20336B"
        strokeWidth="0.7"
      ></circle>
      <rect
        width="1.917"
        height="0.958"
        x="15.641"
        y="3.779"
        fill="#20336B"
        rx="0.3"
      ></rect>
      <path stroke="#20336B" d="M21.582 9.625h1.137a.3.3 0 01.3.3v1.617"></path>
      <path
        fill="#4ABCEB"
        stroke="#4ABCEB"
        d="M7.65 15.718l.037-.17.337-.05-.047.22H7.65zM11.335 15.71l.14-.686.319-.037-.168.722h-.29z"
      ></path>
      <mask id="path-10-inside-2_3288_8228" fill="#fff">
        <path d="M3.379 16.168l1.455-.492-.106.532h-1.35v-.04z"></path>
      </mask>
      <path
        fill="#4ABCEB"
        d="M3.379 16.168l1.455-.492-.106.532h-1.35v-.04z"
      ></path>
      <path
        fill="#4ABCEB"
        d="M3.379 16.168l-.32-.948-.68.23v.718h1zm1.455-.492l.981.195.339-1.696-1.64.553.32.948zm-.106.532v1h.82l.16-.804-.98-.196zm-1.35 0h-1v1h1v-1zm.321.907l1.455-.492-.64-1.895-1.455.492.64 1.895zm.155-1.635l-.107.533 1.962.391.106-.533-1.961-.39zm.874-.272h-1.35v2h1.35v-2zm-.35 1v-.04h-2v.04h2z"
        mask="url(#path-10-inside-2_3288_8228)"
      ></path>
      <mask
        id="mask0_3288_8228"
        style={{ maskType: "luminance" }}
        width="10"
        height="7"
        x="3"
        y="10"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M12.435 14.421c-.47.05-.93.102-1.38.157l.431-2.029c.046-.2.07-.38.07-.542 0-.576-.35-.865-1.046-.865H9.463l-.801 3.764c-.485.076-.95.155-1.396.238l.852-4.002H6.14c-.238 0-.38.116-.425.346l-.891 4.213c-.549.154-1.035.32-1.45.496l1.076-5.055c.077-.361.207-.625.39-.79.184-.166.472-.248.863-.248h5.162c.636 0 1.143.127 1.523.38.38.254.57.646.57 1.177 0 .223-.016.4-.047.53l-.477 2.23z"
        ></path>
        <path
          stroke="#fff"
          d="M12.435 14.421c-.47.05-.93.102-1.38.157l.431-2.029c.046-.2.07-.38.07-.542 0-.576-.35-.865-1.046-.865H9.463l-.801 3.764c-.485.076-.95.155-1.396.238l.852-4.002H6.14c-.238 0-.38.116-.425.346l-.891 4.213c-.549.154-1.035.32-1.45.496l1.076-5.055c.077-.361.207-.625.39-.79.184-.166.472-.248.863-.248h5.162c.636 0 1.143.127 1.523.38.38.254.57.646.57 1.177 0 .223-.016.4-.047.53l-.477 2.23z"
        ></path>
      </mask>
      <g mask="url(#mask0_3288_8228)">
        <path fill="#20336B" d="M43.93 20.323H1.98V8.629h41.95v11.694z"></path>
        <path
          stroke="#4ABCEB"
          d="M43.93 20.323H1.98V8.629h41.95v11.694z"
        ></path>
      </g>
    </svg>
  );
}

export default MontraAccount;
