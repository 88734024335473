import React from "react";
import Animation from "../Animation/Main"
import "./style.css";

const Hero = () => {
  return (
    <>
      <div className="sup-payment-hero">
        <div className="row">
          <div className="col distribution">
            <h1>
              Access SKU-level stock insights for both direct and indirect
              distribution:
            </h1>
            <p>
              Unlock powerful supply chain payment capabilities on a fully
              digital platform that enables
            </p>
            <ul>
              <li>Collection of B2B payments digitally</li>
              <li>Collection of Credit based sales</li>
              <li>Tracking of Due & Collected payments</li>
              <li>
                Launching & managing digital sales promotions for Primary,
                Secondary as well as Tertiary sales
              </li>
              <li>
                Distribution analytics thereby helping in assessing SKU level
                stock availability in the outlets under direct distribution as
                well those where Montra App is being used for selling the SKUs
                to outlets under indirect distribution
              </li>
            </ul>
            <button>Contact Sales</button>
          </div>
          <div className="col">
            <Animation />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
