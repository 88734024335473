import React from 'react'
import Animation from "../Animation/Main"

const Hero = () => {
  return (
    <>
    <div className='sup-payment-hero'>
        <div className='row'>
            <div className='col'>
                <h1>Promotes Primary, Secondary or Tertiary sales <span>to boost your business:</span> </h1>

                <div className='capabilities'>
                    
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Scratch Coupons & Lucky Wheel provide instant discounts and cashbacks for Distributors, Outlets, and Consumers under both Direct and Indirect coverage.</p>
                        </div>
                    </div>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Raffle Schemes to increase primary, secondary or tertiary sales.</p>
                        </div>
                    </div>
                    
                    
                </div>
                <button>Contact Sales</button>
            </div>
            <div className='col'>
            <Animation />
            </div>
        </div>
    </div>
    </>
  )
}

export default Hero