import React from "react";

function LoanRepayment() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      fill="none"
      viewBox="0 0 30 31"
    >
      <path
        stroke="#20336B"
        d="M18.146 13.926l-.58-.041c-.34-.024-.866-.047-1.416-.072l-.68-.031a39.732 39.732 0 01-.999-.057 6.35 6.35 0 01-.343-.033 1.453 1.453 0 01-.14-.024.7.7 0 01-.11-.035c-.824.728-1.645 1.883-2.196 3.131h0c-.646 1.464-.774 2.686-.498 3.477l6.962-6.315zm0 0c.717.726 1.405 1.746 1.886 2.838h0c.647 1.465.775 2.686.498 3.477-.095.272-.26.554-.546.773-.285.217-.729.402-1.438.415h0c-.675.011-1.736.027-2.688.033l2.288-7.536zm-4.978 7.503h0c.328.006 1.49.025 2.685.033h.004l-2.689-.033zm0 0c-.708-.012-1.153-.198-1.437-.415a1.627 1.627 0 01-.547-.773l-.472.165c.238.68.855 1.495 2.448 1.523l.008-.5zm1.033-10.097h3.314l.602-2.25c-.042.03-.09.067-.142.109 0 0 0 0 0 0l-.006.004c-.081.064-.19.15-.298.22-.087.055-.278.172-.502.172-.224 0-.414-.117-.501-.173-.109-.069-.217-.155-.298-.219l-.006-.004-2.163 2.14zm0 0l-.604-2.251.144.11.005.004c.081.064.19.15.298.22.087.055.278.172.502.172.223 0 .414-.117.501-.173.108-.069.217-.155.298-.219l.006-.004s0 0 0 0c.285-.224.403-.293.506-.293.104 0 .222.069.506.293l-2.162 2.14z"
      ></path>
      <path
        fill="#20336B"
        fillRule="evenodd"
        d="M17.905 13.588h-4.094a1.113 1.113 0 010-2.223h4.094a1.113 1.113 0 010 2.223z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#099ED6"
        d="M18.275 18.083h-.713v-.685h.713a.194.194 0 100-.388h-.713v-.95c0-.14-.027-.241-.081-.304-.052-.059-.12-.086-.213-.086a.25.25 0 00-.204.086c-.054.062-.08.164-.08.303v.951h-1.342l-.57-.863a35.12 35.12 0 00-.14-.218.978.978 0 00-.116-.151.373.373 0 00-.114-.08.376.376 0 00-.155-.028.392.392 0 00-.22.066.362.362 0 00-.143.166.85.85 0 00-.038.291v.817h-.706a.194.194 0 000 .388h.706v.685h-.706a.194.194 0 000 .388h.706v1.086c0 .134.028.235.084.3.054.06.121.089.213.089a.267.267 0 00.211-.09c.057-.063.086-.164.086-.3v-1.085h1.214l.656 1.007.14.2c.043.059.089.111.138.155a.427.427 0 00.315.113c.177 0 .36-.055.36-.458v-1.017h.712a.194.194 0 000-.388zm-1.292-.685v.685h-.632l-.453-.685h1.085zm-2.243-.788l.26.4h-.26v-.4zm0 1.473v-.685h.514l.447.685h-.961zm2.243.957l-.375-.569h.375v.569z"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.404 19.117l1.662-2.671 2.069 2.19M4.056 17.347a11.352 11.352 0 0022.378.282M27.596 11.32l-1.662 2.672-2.069-2.19M25.944 13.09a11.352 11.352 0 00-22.378-.281"
      ></path>
    </svg>
  );
}

export default LoanRepayment;
