import React from 'react'
import Chat from '../components/Personal/Chat/Main'

const ChatRoute = () => {
  return (
   <>
   <Chat />
   </>
  )
}

export default ChatRoute