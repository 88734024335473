import React from "react";
import "../Overview/style.css";
import './style.css';
import dcpcard from "../../../../assets/images/dcpcard.svg";


const Hero = () => {
  return (
    <>
      <div className="dts-hero">
        <div className="row">
          <div className="col duo-col">
            <h1>
              Enjoy DUO Card experience with both <span>Credit & Debit cards in a
              single card</span>
            </h1>
            <p>
              Enhance your experience with DUO Card which comes with Dual Chips
              / Magnetic stripes, one each for making a Debit & Credit
              transaction requests from anywhere in the world.
            </p>
            <div className="duo-list-container">
              <div className="duo-lists">
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      DUO Card Product (DCP) comes with Two Chips / Magnetic
                      stripes, one each for making a Debit & Credit transaction
                      requests from anywhere in the world.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Simply dip or swipe the Credit or Debit side based on
                      where you choose to pay from without carrying multiple
                      cards.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Similarly, you can Withdraw cash on any ATM, anywhere in
                      the world from either of your Credit & Debit accounts.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Use DUO for making online transactions by entering details
                      of the Credit or Debit card without having to carry
                      multiple cards.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <button>Contact Sales</button>
          </div>
          <div className="col duo-col">
            <img src={dcpcard} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
