import React from "react";
import Business from "../../Home/Business/Main"

const Body = () => {
  return (
    <>
      <div className="business-page">
        <Business />
      </div>
    </>
  );
};

export default Body;
