import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import logo from "../../assets/images/montralogo.svg";
import nigeria from "../../assets/images/nigeria.svg";
import duologo2 from "../../assets/images/duologo2.svg";
import veryfilogo from "../../assets/images/veryfilogo.svg";
import montra from "../../assets/images/montra.svg";
import Mobilemenu from "./Mobilemenu";

const Main = () => {
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);

  const handleButtonClick = () => {
    setSubMenuVisible(!isSubMenuVisible);
  };

  const handleSubMenuClick = (e) => {
    e.stopPropagation();
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setSubMenuVisible(false);
    }, 3000);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="navbar">
        <div className="navbar-section1">
          <div className="logo">
            <Link to="/" className="navbar-brand">
              <img src={logo} alt="image" />
            </Link>
          </div>
          <div className="nav-link">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link
                  smooth
                  to="/Products/overview"
                  className="nav-link main-active"
                >
                  Products
                </Link>
              </li>
              <li className="nav-item">
                <button
                  className="solution-btn"
                  onClick={handleButtonClick}
                  onMouseEnter={() => setSubMenuVisible(true)}
                  onMouseLeave={handleMouseLeave}
                >
                  Solutions
                </button>

                {isSubMenuVisible && (
                  <div
                    className="submenu"
                    style={{ position: "absolute" }}
                    onClick={handleSubMenuClick}
                    onMouseEnter={() => setSubMenuVisible(true)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {/* Your submenu content goes here */}
                    <div className="solution-menu-menu">
                      <NavLink
                        to="/Solutions/Duo/overview"
                        className="nav-link"
                        activeClassName="active"
                      >
                        <div className="solution-menu">
                          <div>
                            <img src={duologo2} alt="" />
                          </div>
                          <div>
                            <h6>DUO Card</h6>
                            <p>
                              DUO experience offering usage of both Credit &
                              Debit cards in a single card
                            </p>
                          </div>
                        </div>
                      </NavLink>

                      <NavLink
                        to="/Solution/Verified/Authapp"
                        className="nav-link "
                        activeClassName="active"
                      >
                        <div className="solution-menu">
                          <div>
                            <img src={veryfilogo} alt="" />
                          </div>
                          <div>
                            <h6>Verifyed</h6>
                            <p>
                              Authenticator App from VERIFYED is the simplest &
                              most effective way to verify the user's identity
                            </p>
                          </div>
                        </div>
                      </NavLink>
                      <NavLink
                        to="/Solutions/Supplychain/overview"
                        className="nav-link"
                        activeClassName="active"
                      >
                        <div className="solution-menu">
                          <div>
                            <img src={montra} alt="" />
                          </div>
                          <div>
                            <h6>Montra Supply Chain</h6>
                            <p>
                              B2B payments & Supply Chain solution for
                              Distribution Companies
                            </p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                    {/* Add more submenu items as needed */}
                  </div>
                )}
              </li>
              <li className="nav-item">
                <Link to="/Platform/Moneylink/cip" className="nav-link">
                  Platforms
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-section2">
          <button>
            <img src={nigeria} alt="image" /> Nigeria{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M5 7.5L10 12.5L15 7.5"
                stroke="#223872"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <div className="nav-link">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link smooth to="/Company/Overview" className="nav-link">
                  Company
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Resources
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Help
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="mobile-menu-button">
          <button onClick={toggleMenu}>
            <i class="bi bi-list"></i>
          </button>
        </div>
      </div>

      {isMenuOpen && <Mobilemenu setOpen={setIsMenuOpen} />}
    </>
  );
};

export default Main;
