import React, { useState } from "react";
import dlm1 from "../../../../assets/images/dlm1.svg";
import "./style.css";
import duo1 from "../../../../assets/images/duo1.svg";
import duo22 from "../../../../assets/images/duo22.svg";
import margnet from "../../../../assets/images/magnet.svg";
import ppl1 from "../../../../assets/images/ppl1.png";

const Hero = () => {
  const [showDuo2, setShowDuo2] = useState(true);
  const [showMagnet, setShowMagnet] = useState(false);
  const [showPeople1, setShowPeople1] = useState(false);
  const [showPeople2, setShowPeople2] = useState(false);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setShowDuo2((prevShowDuo2) => !prevShowDuo2);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  React.useEffect(() => {
    let timeout1, timeout2, timeout3;

    if (showDuo2) {
      timeout1 = setTimeout(() => {
        setShowMagnet(true);
      }, 1000); 

      timeout2 = setTimeout(() => {
        setShowPeople1(true);
      }, 1500); 

      timeout3 = setTimeout(() => {
        setShowPeople2(true);
      }, 1500); 
    }

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
    };
  }, [showDuo2]);
  return (
    <>
      <div className="dts-hero  dml-hero">
        <div className="row">
          <div className="col">
            <div className="dwp-sm-btn dlm-sm-btn">
              <img src={dlm1} alt="" />
              <p>Dynamic Limit Management</p>
            </div>
            <h1>
              <span>Dynamic data & AI-driven credit limit management</span>
            </h1>

            <button>Contact Sales</button>
          </div>
          <div className="col">
            <div className="duo-ani">
              <div className={`duo-ani-1 ${showDuo2 ? "reduce-width" : ""}`}>
                <img
                  src={duo1}
                  alt=""
                  className={showDuo2 ? "reduce-opacity" : ""}
                />
                <img
                  src={duo22}
                  alt=""
                  className={showDuo2 ? "increase-opacity" : ""}
                />
              </div>
              <div className={`duo-ani-2 ${showDuo2 ? 'expand-width' : ''}`}>
        <div className={`magnet ${showMagnet ? 'show' : ''}`}>
          <img src={margnet} alt="" />
        </div>
        <div className={`people1 ${showPeople1 ? 'show' : ''}`}>
          <img src={ppl1} alt="" />
          <img src={ppl1} alt="" />
          <img src={ppl1} alt="" />
        </div>
        <div className={`people2 ${showPeople2 ? 'show' : ''}`}>
          <img src={ppl1} alt="" />
          <img src={ppl1} alt="" />
          <img src={ppl1} alt="" />
        </div>
      </div>
            </div>
          </div>
        </div>

        <div className="dlm-boxes">
          <div className="dlm-box">
            <p>
              Dynamic Limit Management (DLM) application assesses each monthly
              customer onboarding, determining eligibility for Credit Card
              activation. Customers not activating the card may be flagged as
              NOT ELIGIBLE based on the latest results. This process is repeated
              monthly for all customers onboarded on the DUO Platform by the
              Issuer.
            </p>
          </div>
          <div className="dlm-box">
            <p>
              For INELIGIBLE cards, Credit processor maybe required to block the
              card instantly, however, the card will be un-blocked if found
              ELIGIBLE the following month.
            </p>
          </div>
          <div className="dlm-box">
            <p>
              Where the revised Credit Limit has reduced, DLM asks Credit
              Processor to reduce the credit limit for such DUO Credit Cards.
              Where DLM finds that revised Credit Limit is higher than the
              existing limit, the same is queued to RIS user for approval on DUO
              Web porta
            </p>
          </div>
          <div className="dlm-box">
            <p>
              Ultimately, DLM helps Issuers manage risk dynamically by taking
              rule-based decisions seamlessly.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
