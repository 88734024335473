import React from 'react'
import cap1 from '../../../../assets/images/cap1.svg';
import cap2 from '../../../../assets/images/cap2.svg';
import cap3 from '../../../../assets/images/cap3.svg';
import cap4 from '../../../../assets/images/cap4.svg';
import cap5 from '../../../../assets/images/cap5.svg';
import cap6 from '../../../../assets/images/cap6.svg';
import cap7 from '../../../../assets/images/cap7.svg';
import cap8 from '../../../../assets/images/cap8.svg';
import cap9 from '../../../../assets/images/cap9.svg';
import cap10 from '../../../../assets/images/cap10.svg';

const Body = () => {
  return (
    <>
    <div className='supply-body'>
        <h1>Unlock powerful supply chain payment capabilities on a fully digital platform for your Distribution Business with<br/><span>Montra Supply chain SDK </span></h1>
    
        <div className='row'>
            <div className='col'>
                <div className='capabilities'>
                    <img src={cap1} alt='' />
                    <h6>B2B Payments</h6>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Seamless delivery of Invoices to your Distributor & the Outlets under Distributor’s direct coverage</p>
                        </div>
                    </div>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Distributors & Outlets can simply pay using their Montra Business Accounts or Linked Bank Accounts</p>
                        </div>
                    </div>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Dashboard to track payments against Invoices shared through Montra Supply chain SDK</p>
                        </div>
                    </div>
                </div>

                <div className='capabilities'>
                    <img src={cap2} alt='' />
                    <h6>Collections against Credit based Sales</h6>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Offer credit period on the Invoice that you send to Distributor or Outlets under Distributor’s direct coverage</p>
                        </div>
                    </div>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Distributors & Outlets can simply pay during the Credit Period or on the last day using their Montra Business Accounts or Linked Bank Accounts</p>
                        </div>
                    </div>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Montra provides Dashboard to both Distribution Company / Distributor & Outlet owner to view all payments done against a Credit based invoice</p>
                        </div>
                    </div>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Montra would start collecting payments after the last date of the Credit Period for the overdue amount</p>
                        </div>
                    </div>
                </div>

                <div className='capabilities'>
                    <img src={cap3} alt='' />
                    <h6>Sales Promotions</h6>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Distributors can create Sales Promotions for Primary , Secondary & Tertiary sales using Scratch Coupon, Spin Lucky Wheel, Raffle based execution</p>
                        </div>
                    </div>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Montra SDK enables the Distribution companies to launch and manage such promotions digitally</p>
                        </div>
                    </div>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Distributors, Outlets, Consumers get the benefits instantly in their Montra Accounts.</p>
                        </div>
                    </div>
                </div>

                <div className='capabilities'>
                    <img src={cap4} alt='' />
                    <h6>Order Fulfillment</h6>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Outlets under direct coverage can place orders to their Distributors at SKU level</p>
                        </div>
                    </div>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Distributors can easily make invoices against the Orders received from the Outlets</p>
                        </div>
                    </div>
                    
                </div>

                <div className='capabilities'>
                    <img src={cap5} alt='' />
                    <h6>Distribution analytics</h6>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Montra tracks the inventories at the SKU level for the outlets under direct coverage</p>
                        </div>
                    </div>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Montra also tracks inventories at SKU level for the outlets under indirect coverage </p>
                        </div>
                    </div>
                    <div className='sup-point'>
                        <div className='square-point'></div>
                        <div>
                            <p>Our Distribution Analytics dashboard provides insights on the length, breadth & depth of distribution besides giving insights on the SKU level stock that these outlets have</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col'>
                <div className='row'>
                    <div className='col phone1'>
                        <img src={cap6} alt='' />
                        <img src={cap7} alt='' />
                        <img src={cap8} alt='' />
                    </div>
                    <div className='col phone2'>
                    <img src={cap9} alt='' />
                        <img src={cap10} alt='' />
                    </div>
                </div>
            </div>
        </div>
    
        
    </div>
    </>
  )
}

export default Body