import React from "react";
import pos from "../../../../assets/images/pos.svg";
import merchant1 from "../../../../assets/images/merchant1.svg";

const Hero = () => {
  return (
    <>
      <div className="merchant-hero">
        <div className="row">
          <div className="col">
            <h1>
              MoneyLink offers <span>all-in-one Point of sale(POS)</span> terminal for all
              your business needs.
            </h1>
            <p>
              From contactless payments, dipping or swiping debit & credit
              cards, to QR enabled payments, now MoneyLink powered terminals can
              accept all sort of payment modes at your store, quickly and
              securely.
            </p>
            <img src={merchant1} alt="" />
            <div>
              <button>Contact Sales</button>
            </div>
          </div>
          <div className="col pos-img">
            <img src={pos} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
