import React from 'react'
import businessbanking1 from '../../../assets/images/businessbanking1.svg'
import businessbanking2 from '../../../assets/images/businessbanking2.svg'
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";

const Hero = () => {
  return (
   <>
   <div className='business-banking'>
    <div className='row'>
        <div className='col'>
            <h1>Open a free Business Account <span>for all your Business Finances</span></h1>
            <img className='business-banking-img' src={businessbanking2} alt='' />
            <div className="app-download">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
        </div>
        <div className='col business-banking-img-img'>
            <img src={businessbanking1} alt='' />
        </div>
    </div>
   </div>
   
   </>
  )
}

export default Hero