import React from "react";

function LoanMgtService() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="23"
      fill="none"
      viewBox="0 0 26 23"
    >
      <path
        stroke="#20336B"
        strokeWidth="0.5"
        d="M17.601 21.16l-.154.197.154-.196a1.009 1.009 0 00-.622-.217h0-.106a1.262 1.262 0 01-.834-.324 1.364 1.364 0 01-.437-.817s0 0 0 0L15.342 18l-.03-.215h-4.515l-.031.215-.26 1.803s0 0 0 0a1.365 1.365 0 01-.437.817 1.262 1.262 0 01-.833.324H9.13h0c-.227 0-.445.077-.623.217s-.305.335-.365.554l-.085.316h9.994l-.085-.316a1.056 1.056 0 00-.365-.554z"
      ></path>
      <rect
        width="24.052"
        height="17.55"
        x="0.974"
        y="0.4"
        stroke="#20336B"
        strokeWidth="0.8"
        rx="1.6"
      ></rect>
      <path
        fill="#fff"
        d="M13.055 17.306a.65.65 0 100-1.298.65.65 0 000 1.298z"
      ></path>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.2"
        d="M22.463 1.625h0a1.35 1.35 0 011.348 1.347v11.394a1.35 1.35 0 01-1.348 1.348h0H3.654h0a1.35 1.35 0 01-1.348-1.348h0V2.973v0a1.35 1.35 0 011.348-1.348h6.233a.308.308 0 110 .616H3.654a.732.732 0 00-.732.732v11.393a.732.732 0 00.732.732h18.809a.732.732 0 00.732-.732V2.973a.732.732 0 00-.732-.732h-8.884a.308.308 0 01-.218-.526m9.102-.09l-9.102.09s0 0 0 0m9.102-.09h-8.884m8.884 0h-8.884m-.218.09a.308.308 0 01.218-.09m-.218.09l.218-.09"
      ></path>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.2"
        d="M10.821 2.24h1.768a.308.308 0 100-.615h-1.768a.308.308 0 100 .616z"
      ></path>
      <path
        fill="#3FAFE9"
        stroke="#3FAFE9"
        strokeWidth="0.1"
        d="M8.328 4.37h0a4.308 4.308 0 104.308 4.308v0A4.312 4.312 0 008.328 4.37zM4.537 8.678h0A3.796 3.796 0 018.07 4.894v3.742l-1.176 3.552a3.784 3.784 0 01-2.357-3.51zm3.791 3.791c-.318 0-.635-.04-.944-.119l1.13-3.414h3.597a3.797 3.797 0 01-3.783 3.534zm.258-4.05V4.895a3.798 3.798 0 013.525 3.526H8.586zm4.987 4.361h8.216a.258.258 0 100-.516h-.886v-3.47a.258.258 0 00-.258-.258H19.24a.258.258 0 00-.258.258v3.47h-.368V6.09a.258.258 0 00-.258-.258h-1.404a.258.258 0 00-.258.258v6.174h-.368v-4.51a.258.258 0 00-.258-.258h-1.404a.258.258 0 00-.258.258v4.51h-.576V4.818a.258.258 0 00-.516 0v7.704a.258.258 0 00.258.258zM19.5 9.052h.888v3.212h-.888V9.052zM17.21 6.348h.888v5.916h-.888V6.348zm-2.288 1.664h.888v4.252h-.888V8.012z"
      ></path>
    </svg>
  );
}

export default LoanMgtService;
