import Navbar from "../../Navbar/Company";
import Footer from "../../Footer/Main";
import "./style.css";
import Body from "./Body";
import Hero from "./Hero";
import { createContext, useState } from "react";

export const ActiveStoryContext = createContext();

const Main = () => {
  const [active, setActive] = useState(1);

  return (
    <ActiveStoryContext.Provider value={[active, setActive]}>
      <Navbar />
      <Hero />
      <Body />
      <Footer />
    </ActiveStoryContext.Provider>
  );
};

export default Main;
