import React from "react";

function LoanManagementSystem() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      fill="none"
      viewBox="0 0 24 20"
    >
      <path
        fill="#24BAF2"
        d="M13.158 9.951c.297.128.604.215.914.263l.099.817a4.515 4.515 0 002.111-.28l-.098-.818c.292-.129.568-.294.822-.493l.62.505a4.966 4.966 0 001.305-1.765l-.62-.506c.12-.308.203-.63.25-.959l.774-.103a5.208 5.208 0 00-.272-2.216l-.77.102a4.18 4.18 0 00-.469-.864l.48-.65a4.78 4.78 0 00-.766-.787 4.758 4.758 0 00-.915-.583l-.48.651a3.77 3.77 0 00-.914-.262l-.099-.817a4.51 4.51 0 00-2.112.28l.1.818a3.871 3.871 0 00-.824.493l-.62-.506a4.953 4.953 0 00-1.305 1.766l.62.505a4.29 4.29 0 00-.249.96l-.775.102c-.07.751.023 1.51.273 2.217l.77-.102c.122.306.28.597.469.864l-.48.649a4.707 4.707 0 001.68 1.371l.481-.652zm.188-2.076a2.265 2.265 0 01-.785-1.482 2.317 2.317 0 01.431-1.634 2.11 2.11 0 011.4-.846 2.054 2.054 0 011.562.43c.447.362.737.897.808 1.485a2.317 2.317 0 01-.43 1.65 2.108 2.108 0 01-1.415.847 2.056 2.056 0 01-1.571-.45zm4.26 7.84c.135.043.273.07.41.086l.144.649a2.774 2.774 0 001.48-.363l-.145-.65c.236-.159.441-.362.606-.598l.609.192a3.085 3.085 0 00.439-1.528l-.609-.193a2.482 2.482 0 00-.19-.85l.465-.457a2.98 2.98 0 00-1.042-1.162l-.463.456a2.188 2.188 0 00-.797-.252l-.145-.65a2.78 2.78 0 00-1.48.364l.145.65c-.235.158-.44.361-.605.598l-.61-.193a3.095 3.095 0 00-.438 1.528l.608.193c.013.293.077.581.19.85l-.464.457c.242.478.602.88 1.042 1.163l.462-.456c.124.067.254.123.387.166zm-.36-2.615c.08-.284.266-.522.515-.662s.54-.17.81-.085c.271.086.498.28.631.542a1.168 1.168 0 01-.114 1.236 1.078 1.078 0 01-.32.277 1.03 1.03 0 01-.81.085 1.049 1.049 0 01-.367-.205 1.108 1.108 0 01-.265-.337 1.159 1.159 0 01-.08-.85z"
      ></path>
      <path
        stroke="#2C007D"
        d="M10.424 7.726l.054.253-.667-.05a85.829 85.829 0 00-1.984-.106l-.945-.045a52.407 52.407 0 01-1.39-.083 8.269 8.269 0 01-.469-.047 1.815 1.815 0 01-.179-.033.773.773 0 01-.108-.035h-.002a.54.54 0 01-.108-.06c-1.211 1.086-2.417 2.853-3.218 4.76h0C.495 14.449.29 16.313.705 17.56c.145.434.397.89.838 1.243.435.349 1.095.63 2.114.648h0c.458.008 2.08.037 3.75.05h.007l3.01-11.776zm0 0c1.135 1.096 2.244 2.763 2.997 4.553h0c.912 2.17 1.117 4.034.702 5.282l-3.7-9.835zm.747 11.726h0c1.02-.019 1.68-.299 2.115-.648.44-.353.693-.809.837-1.242l-2.952 1.89zm0 0c-.944.017-2.426.04-3.756.05l3.756-.05zm-1.296-14.8H4.954L3.934.654c.05.018.104.044.164.08.147.087.294.206.475.355l.005.005c.114.095.26.215.402.311.074.05.159.1.25.141.087.039.21.081.353.081a.878.878 0 00.353-.08c.09-.04.176-.092.25-.142.142-.096.288-.216.402-.311l.006-.005s0 0 0 0a3.9 3.9 0 01.474-.355.686.686 0 01.346-.116c.096 0 .205.032.347.116.147.086.293.206.473.355L9.875 4.65zm0 0l1.02-3.997c-.05.018-.104.044-.164.08a3.904 3.904 0 00-.474.355s0 0 0 0l-.006.005c-.114.095-.26.215-.403.311-.073.05-.158.101-.25.141a.878.878 0 01-.352.081.878.878 0 01-.353-.08 1.659 1.659 0 01-.25-.142 5.238 5.238 0 01-.403-.311l-.006-.005 1.641 3.561z"
      ></path>
      <path
        fill="#2C007D"
        fillRule="evenodd"
        d="M10.273 7.725H4.556c-.856 0-1.552-.731-1.552-1.629 0-.899.696-1.63 1.552-1.63h5.717c.855 0 1.551.731 1.551 1.63 0 .898-.696 1.63-1.551 1.63z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#24BAF2"
        d="M10.795 14.315h-.996V13.31h.996c.15 0 .27-.127.27-.284a.278.278 0 00-.27-.284h-.996v-1.394c0-.204-.038-.353-.113-.444-.072-.086-.166-.127-.297-.127a.342.342 0 00-.286.126c-.074.091-.112.241-.112.445v1.394H7.118l-.796-1.265a53.99 53.99 0 00-.194-.32 1.42 1.42 0 00-.163-.222.52.52 0 00-.158-.116.504.504 0 00-.217-.042c-.11 0-.21.032-.306.097a.526.526 0 00-.2.244c-.036.094-.054.24-.054.426v1.198h-.986c-.15 0-.27.127-.27.284 0 .157.12.284.27.284h.986v1.005h-.986c-.15 0-.27.127-.27.284 0 .157.12.284.27.284h.986v1.591c0 .198.04.346.117.44.075.09.17.13.297.13a.364.364 0 00.295-.13c.08-.093.12-.24.12-.44v-1.59h1.694l.917 1.475c.065.099.13.196.196.293.059.087.123.163.192.227a.594.594 0 00.44.166c.247 0 .501-.08.501-.67v-1.492h.996c.15 0 .27-.127.27-.284a.278.278 0 00-.27-.284zM8.99 13.31v1.005h-.883l-.632-1.005h1.515zm-3.132-1.154l.364.586h-.364v-.586zm0 2.159V13.31h.717l.624 1.005H5.86zm3.132 1.402l-.525-.834h.525v.834z"
      ></path>
    </svg>
  );
}

export default LoanManagementSystem;
