import React from "react";

function Installments() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="25"
      fill="none"
      viewBox="0 0 26 25"
    >
      <path
        stroke="#20336B"
        d="M4.652 14.148c.288 0 .522.247.522.552v7.715c0 .304-.234.55-.522.55h-3.13c-.288 0-.522-.246-.522-.55V14.7c0-.305.234-.552.522-.552h3.13zM5.176 21.382c10.956 3.857 7.304 3.857 19.826-2.755-.721-1.026-1.972-1.466-3.13-1.102l-4.504 1.506"
      ></path>
      <path
        stroke="#20336B"
        d="M5.176 15.252h3.13c2.456 0 4.174 2.204 4.696 2.204h3.652c1.043 0 1.043 2.204 0 2.204h-5.74"
      ></path>
      <path
        fill="#24BAF2"
        d="M19.102 6.983h-1.029V5.94h1.029c.154 0 .28-.132.28-.295a.288.288 0 00-.28-.296h-1.029V3.9c0-.212-.04-.367-.117-.462-.074-.09-.172-.131-.307-.131a.352.352 0 00-.295.13c-.077.095-.116.25-.116.463v1.448h-1.935l-.822-1.314a59.066 59.066 0 00-.201-.333 1.476 1.476 0 00-.168-.23.537.537 0 00-.164-.121.517.517 0 00-.224-.043.544.544 0 00-.316.1.546.546 0 00-.207.253 1.363 1.363 0 00-.056.444v1.244h-1.018a.288.288 0 00-.28.296c0 .163.126.295.28.295h1.019v1.044h-1.019a.288.288 0 00-.28.296c0 .163.126.295.28.295h1.019v1.654c0 .205.04.359.121.456a.37.37 0 00.307.137.375.375 0 00.305-.137c.082-.095.123-.249.123-.456V7.574h1.75l.948 1.534.202.305c.061.09.128.17.198.235a.613.613 0 00.455.173c.256 0 .518-.083.518-.697v-1.55h1.029c.154 0 .28-.132.28-.295a.288.288 0 00-.28-.296zM17.238 5.94v1.044h-.912l-.653-1.044h1.565zm-3.236-1.2l.376.61h-.376v-.61zm0 2.244V5.94h.741l.645 1.044h-1.386zm3.236 1.457l-.542-.866h.542v.866z"
      ></path>
      <path
        stroke="#20336B"
        d="M21.37 6.613c0 3.402-2.605 6.113-5.762 6.113-3.156 0-5.76-2.71-5.76-6.113C9.848 3.21 12.452.5 15.608.5c3.157 0 5.761 2.71 5.761 6.113z"
      ></path>
    </svg>
  );
}

export default Installments;
