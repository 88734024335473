import "./style.css";
import hero from "../../../assets/images/gateway-hero.svg";
import hero2 from "../../../assets/images/gateway-hero-2.svg";

const Hero = () => {
  return (
    <section className="gateway">
      <div>
        <h1 className="title">
          Power your business with <b>Montra Pay Payment Gateway</b>
        </h1>
        <p>
          Simply integrate Montra Payment Gateway with your website or Mobile
          App for your customers to make payments using their :
        </p>
        <ul className="list">
          <li>Debit &amp; Credit Cards</li>
          <li>
            Bank Accounts by scanning QR code using their Mobile Banking Apps
          </li>
          <li>
            Montra App where they can make payments using Montra account and
            even using Installments
          </li>
          <li>USSD to make payment using USSD app</li>
        </ul>
        <br />
        <img className="diagram" src={hero2} alt="hero2" />
        <button className="cta">Contact Sales</button>
      </div>
      <img src={hero} alt="hero" />
    </section>
  );
};

export default Hero;
