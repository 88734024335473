import React from "react";
import inventory1 from "../../../assets/images/inventory1.svg";
import inventory2 from "../../../assets/images/inventory2.svg";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";

const Hero = () => {
  return (
    <>
      <div className="inventory">
        <div className="row">
          <div className="col">
            <h1>
             <span>Buy, Sell & Manage</span>  your Inventories & even <span>Place Orders</span> to the
              linked Distributors
            </h1>
            <ul>
              <li>
                You can buy inventories from other Businesses on Montra for
                which they can send invoices to you so that you can make payment
                using your Montra Business Account. Similarly, you can send
                invoices to other buyers on Montra App and get paid instantly
              </li>
              <li>
                You can also place Orders for the products you want to buy from
                the Distributors you have already linked to your Montra App.{" "}
              </li>
            </ul>
            <img className="inventory1" src={inventory1} alt="" />
            <div className="app-download">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
          </div>
          <div className="col inventory-hero2">
            <img  src={inventory2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
