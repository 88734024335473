import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import logo from "../../assets/images/montralogo.svg";
import "./mobilemenu.css";

const Mobilemenu = ({ setOpen }) => {
  const [openSolutions, setOpenSolutions] = useState(false);

  return (
    <>
      <div className="mobile-menu">
        <div className="mobile-menu-header">
          <div>
            <img src={logo} alt="" />
          </div>
          <button onClick={() => setOpen(false)}>
            <i class="bi bi-x"></i>
          </button>
        </div>

        <div className="mobile-menu-first">
          <div>
            <Link smooth to="/Products/overview" className=" main-active">
              Products
            </Link>
          </div>
          <div>
            <Link smooth to="/Solutions/Duo/overview" className=" main-active">
              Solutions
            </Link>
            <ul className="menu-list">
              <li>
                <Link smooth to="/Solutions/Duo/overview">
                  Duo Card
                </Link>
              </li>
              <li>
                <Link smooth to="/Solution/Verified/Authapp">
                  Verifyed
                </Link>
              </li>
              <li>
                <Link smooth to="/Solutions/Supplychain/overview">
                  Montra Supply Chain
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <Link smooth to="/Platform/Moneylink/cip" className=" main-active">
              Platforms
            </Link>
          </div>
          <div>
            <Link smooth to="/Company/Overview" className=" main-active">
              Company
            </Link>
          </div>
          <div>
            <Link smooth to="/" className=" main-active">
              Resources
            </Link>
          </div>
          <div>
            <Link smooth to="/" className=" main-active">
              Help
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mobilemenu;
