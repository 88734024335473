import React, { useState, useEffect } from "react";
import "./style.css";

import MontraSupplyChainSKE from "../../../../assets/images/montra-supply-chain-sdk.svg";
import ERPSKE from "../../../../assets/images/erp.svg";
import DistributionCompanySKE from "../../../../assets/images/distribution-company-hand.svg";
import DistributorsSKE from "../../../../assets/images/distributors.svg";
import OutletsUnderDirectSKE from "../../../../assets/images/outlets-under-direct.svg";
import MontraAppSKE from "../../../../assets/images/montra-app.svg";
import OutlestUnderIndirectSKE from "../../../../assets/images/outlets-under-indirect.svg";

import DistributionCompany from "../../../../assets/svg/distribution-company";
import Distributors from "../../../../assets/svg/distributors";
import OutletsUnderDirect from "../../../../assets/svg/outlets-under-direct";
import MontraApp from "../../../../assets/svg/montra-app";
import OutletsUnderIndirect from "../../../../assets/svg/outlets-under-indirect";
import ERP from "../../../../assets/svg/erp";
import SupplyChainSDK from "../../../../assets/svg/supply-chain-sdk";

import YU14 from "../../../../assets/svg/yu14";
import YU15 from "../../../../assets/svg/yu15";
import YU16 from "../../../../assets/svg/yu16";
import YU17 from "../../../../assets/svg/yu17";

const Main = ({ index }) => {
  const [showS, setShowS] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setShowS((prev) => {
        return !prev;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="yu-relative">
      <div className="yu-flex gap-24 ml40">
        <div className="qwerty">
          <div className="ske">
            <img
              src={MontraSupplyChainSKE}
              alt="Montra Supply Chain SDK"
            />
            <p>Montra Supply Chain SDK</p>
          </div>
        </div>
        <div className="qwerty">
          <div className="ske">
            <img
              src={ERPSKE}
              alt="ERP SDK"
            />
            <p>ERP</p>
          </div>
          <div className="ske mt36">
            <img
              src={DistributionCompanySKE}
              alt="Distribution Company"
            />
            <p>DDistribution Company Handheld/App</p>
          </div>
          <div className="ske mt82">
            <img
              src={DistributorsSKE}
              alt=""
            />
            <p>Distributors</p>
          </div>
        </div>
        <div className="qwerty">
          <div className="ske">
            <img
              src={OutletsUnderDirectSKE}
              alt=""
            />
            <p>Outlets Under Direct</p>
          </div>
          <div className="ske mt94">
            <img
              src={MontraAppSKE}
              alt=""
            />
            <p>
              Montra App Distributors, Direct & Indirect Outlets profited as
              Users
            </p>
          </div>
        </div>
        <div className="qwerty">
          <div className="ske">
            <img
              src={OutlestUnderIndirectSKE}
              alt=""
            />
            <p>Outlets Under Indirect Coverage</p>
          </div>
        </div>
      </div>
      {showS && (
        <div className="yu-flex gap-24 yu-absolute mt24 font12 ml40">
          <div className="qwerty">
            <div className="ske-b">
              <SupplyChainSDK />
              <p>Montra Supply Chain SDK</p>
            </div>
          </div>
          <div className="qwerty">
            <div className="ske-b">
              <ERP />
              <p>ERP</p>
            </div>
            <div className="ske-b mt36">
              <DistributionCompany />
              <p>Distribution Company Handheld/App</p>
            </div>
            <div className="ske-b mt82">
              <Distributors />
              <p>Distributors</p>
            </div>
          </div>
          <div className="qwerty">
            <div className="ske-b">
              <OutletsUnderDirect />
              <p>Outlets Under Direct</p>
            </div>
            <div className="ske-b mt94">
              <MontraApp />
              <p>
                Montra App Distributors, Direct & Indirect Outlets profited as
                Users
              </p>
            </div>
          </div>
          <div className="qwerty">
            <div className="ske-b">
              <OutletsUnderIndirect />
              <p>Outlets Under Indirect Coverage</p>
            </div>
          </div>
          <div className="yu-absolute mt96 ml40 z-back">
            {index === 1 ? (
              <YU14 />
            ) : index === 2 ? (
              <YU15 />
            ) : index === 3 ? (
              <YU16 />
            ) : (
              <YU17 />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;
