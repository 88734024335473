import React from "react";
import YU7 from "../../../assets/svg/yu7.jsx";
import YU8 from "../../../assets/svg/yu8.jsx";
import YU9 from "../../../assets/svg/yu9.jsx";
import YU10 from "../../../assets/svg/yu10.jsx";
import YU11 from "../../../assets/svg/yu11.jsx";
import YU12 from "../../../assets/svg/yu12.jsx";
import YU13 from "../../../assets/svg/yu13.jsx";
import "./style.css";
import MontraCustomers from "../../../assets/svg/montra-customers.jsx";
import MontraMerchants from "../../../assets/svg/montra-merchants.jsx";
import QRScan from "../../../assets/svg/qr-scan.jsx";
import TapAndPay from "../../../assets/svg/tap-and-pay.jsx";
import POS from "../../../assets/svg/pos.jsx";
import DebitOrCredit from "../../../assets/svg/debit-or-credit.jsx";
import BankTransfer from "../../../assets/svg/bank-transfer.jsx";
import MobileOrContact from "../../../assets/svg/mobile-or-contact.jsx";
import CashWithdrawal from "../../../assets/svg/cash-withdrawal.jsx";
import CashDeposit from "../../../assets/svg/cash-deposit.jsx";
import CashPayment from "../../../assets/svg/cash-payment.jsx";
import BillPayment from "../../../assets/svg/bill-payment.jsx";
import MontraAccount from "../../../assets/svg/montra-account.jsx";

export const Animation1 = ({ className }) => {
  return (
    <div className={`yu-flex gap-92 yu-absolute ${className}`}>
      <div className="yu-mr-3 z-1">
        <div className="ske-b mt24">
          <MontraMerchants />
          <p>Montra Merchants</p>
        </div>
      </div>
      <div className="yu-mr-3 z-1">
        <div className="ske-b mt24">
          <QRScan />
          <p>QR Scan</p>
        </div>
      </div>
      <div className="yu-mr-3 z-1">
        <div className="ske-b mt24">
          <MontraCustomers />
          <p>Montra Customers</p>
        </div>
      </div>
      <div className="yu-absolute yu-mt-x ">
        <YU7 />
      </div>
    </div>
  );
};

export const Animation2 = ({ className }) => {
  return (
    <div className={`yu-flex gap-92 yu-absolute ${className}`}>
      <div className="yu-mr-3 z-1">
        <div className="ske-b mt24">
          <MontraMerchants />
          <p>Montra Merchants</p>
        </div>
      </div>
      <div className="yu-mr-3 z-1 mt120">
        <div className="ske-b mt48">
          <TapAndPay />
          <p>Tap & Pay</p>
        </div>
        <div className="ske-b mt48">
          <POS />
          <p>POS</p>
        </div>
      </div>
      <div className="yu-mr-3 z-1 mt190">
        <div className="ske-b mt48">
          <DebitOrCredit />
          <p>Debit Or Credit</p>
        </div>
      </div>
      <div className="yu-absolute mt216 ml96">
        <YU8 />
      </div>
    </div>
  );
};

export const Animation3 = ({ className }) => {
  return (
    <div className={`yu-flex gap-92 yu-absolute ${className}`}>
      <div className="yu-mr-3 z-1">
        <div className="ske-b mt24">
          <MontraMerchants />
          <p>Montra Merchants</p>
        </div>
      </div>
      <div className="yu-mr-3 z-1 mt468">
        <div className="ske-b">
          <QRScan />
          <p>QR Scan</p>
        </div>
        <div className="ske-b mt48">
          <BankTransfer />
          <p>Bank Transfer</p>
        </div>
      </div>
      <div className="yu-mr-3 z-1 mt504">
        <div className="ske-b mt24">
          <MobileOrContact />
          <p>Any Mobile Banking App</p>
        </div>
      </div>

      <div className="yu-absolute mt325 ml48">
        <YU9 />
      </div>
    </div>
  );
};

export const Animation4 = ({ className }) => {
  return (
    <div className={`yu-flex gap-72 yu-absolute mt24 ${className}`}>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <MontraCustomers />
          <p>Montra Customers</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <CashWithdrawal />
          <p>Cash Withdrawal</p>
        </div>
        <div className="ske-b mt8">
          <CashDeposit />
          <p>Cash Deposit</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <QRScan />
          <p>QR Scan</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <MontraMerchants />
          <p>Montra Merchants</p>
        </div>
      </div>
      <div className="yu-absolute mt48 ml96">
        <YU10 />
      </div>
    </div>
  );
};

export const Animation5 = ({ className }) => {
  return (
    <div className={`yu-flex gap-72 yu-absolute mt24 ${className}`}>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt168">
          <DebitOrCredit />
          <p>Any Debit or Credit Card</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt220">
          <CashDeposit />
          <p>Cash Deposit</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt116">
          <TapAndPay />
          <p>Tap & Pay</p>
        </div>
        <div className="ske-b mt8">
          <POS />
          <p>POS</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <MontraMerchants />
          <p>Montra Merchants</p>
        </div>
      </div>
      <div className="yu-absolute mt215 ml96">
        <YU11 />
      </div>
    </div>
  );
};

export const Animation6 = ({ className }) => {
  return (
    <div className={`yu-flex gap-72 yu-absolute mt24 ${className}`}>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt336">
          <MobileOrContact />
          <p>Any Mobile Banking App</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt336">
          <CashWithdrawal />
          <p>Cash Withdrawal</p>
        </div>
        <div className="ske-b mt8">
          <CashDeposit />
          <p>Cash Deposit</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt336">
          <QRScan />
          <p>QR Scan</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <MontraMerchants />
          <p>Montra Merchants</p>
        </div>
      </div>

      <div className="yu-absolute mt378 ml96">
        <YU12 />
      </div>
    </div>
  );
};

export const Animation7 = ({ className }) => {
  return (
    <div className={`yu-flex gap-72 yu-absolute mt24 ${className}`}>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt556">
          <CashPayment />
          <p>Cash Payments</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt556">
          <BillPayment />
          <p>Recharge & Bill Payments</p>
        </div>
        <div className="ske-b mt8">
          <BankTransfer />
          <p>Bank Transfer</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt556">
          <MontraAccount />
          <p>Montra Agent Account</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <MontraMerchants />
          <p>Montra Merchants</p>
        </div>
      </div>
      <div className="yu-absolute mt424 ml96">
        <YU13 />
      </div>
    </div>
  );
};
