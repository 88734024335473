import React from "react";
import financehero1 from "../../../assets/images/financehero1.svg"
import financehero2 from "../../../assets/images/financehero.svg"
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";

const Hero = () => {
  return (
    <>
      <div className="finance-hero">
        <div className="row">
          <div className="col finance-hero-left">
            <h1>Financial products for every step of your journey</h1>
            <img className="finance-hero-img" src={financehero1} alt="" />
            <div className="app-download">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
          </div>
          <div className="col finance-hero-right">
            <img src={financehero2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
