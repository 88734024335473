import React from "react";

function BankTransfer() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#223872"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.598 23.016l.59-1.703a.332.332 0 01.316-.22h12.187c.143 0 .27.09.316.22l.59 1.7"
      ></path>
      <path
        stroke="#223872"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.93 14.057h11.333v7.04H2.93v-7.04z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#223872"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.263 23.016H.93M4.93 14.057v7.04M6.93 14.057v7.04M10.266 14.057v7.04M12.266 14.057v7.04"
      ></path>
      <path
        stroke="#223872"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.51 12.385a.314.314 0 00-.151-.379L8.76 8.385a.345.345 0 00-.333 0l-6.6 3.619a.314.314 0 00-.15.379l.509 1.455c.045.13.172.218.316.218h12.186c.144 0 .271-.088.316-.218l.504-1.453z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#223872"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.484 12.137H1.704"
      ></path>
      <path
        stroke="#223872"
        d="M13.563 5.91a4.91 4.91 0 119.82 0 4.91 4.91 0 01-9.82 0z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#00BAF2"
        strokeLinecap="square"
        d="M15.563 5.355h6.06M15.563 6.416h6.06M20.409 8.008h-.606l-2.425-4.242h-.644v4.242M20.45 8.008V3.766"
      ></path>
      <path stroke="#223872" d="M18.719 11v5.862a1 1 0 01-1 1h-3.117"></path>
      <path
        fill="#223872"
        d="M13.655 17.83l2.105-1.524-.094 3.168-2.01-1.645z"
      ></path>
    </svg>
  );
}

export default BankTransfer;
