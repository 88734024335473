import React from 'react';
import Navbar from '../../Navbar/Businessnav'
import './style.css';
import Productfooter from '../../Footer/Productfooter';
import Hero from './Hero';
import Body from './Body';

const Main = () => {
  return (
    <>
    <Navbar />
    <Hero />
    <Body />
    <Productfooter />
    </>
  )
}

export default Main