import React, { useState } from "react";
import Navbar from "../Navbar/Main";
import { HashLink as Link } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import supplychainlogo from "../../assets/images/supplychainlogo.svg";
import "./supplychain.css";

const Supplychain = () => {
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);

  const handleButtonClick = () => setSubMenuVisible(!isSubMenuVisible);

  const handleSubMenuClick = (e) => e.stopPropagation();

  const handleMouseLeave = () => {
    setTimeout(() => {
      setSubMenuVisible(false);
    }, 3000);
  };

  return (
    <>
      <div className="product-nav">
        <Navbar />
      </div>

      <div className="second-navbar navbar">
        <div className="solutions-logo">
          <img src={supplychainlogo} alt="" />
        </div>

        <div className="nav-link sup-nav-link">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink
                to="/Solutions/Supplychain/overview"
                className="nav-link"
                activeClassName="sol-active"
              >
                Overview
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Solutions/Supplychain/payment"
                className="nav-link"
                activeClassName="sol-active"
              >
                Payments
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Solutions/Supplychain/collection"
                className="nav-link"
                activeClassName="sol-active"
              >
                Collections
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Solutions/Supplychain/Sales"
                className="nav-link"
                activeClassName="sol-active"
              >
                Sales Promotions
              </NavLink>
            </li>
            <li className="nav-item">
              <button
                style={{ color: "#6E6E6E" }}
                className="more"
                onClick={handleButtonClick}
                onMouseEnter={() => setSubMenuVisible(true)}
                onMouseLeave={handleMouseLeave}
              >
                More
              </button>
              {isSubMenuVisible && (
                <div
                  className="submenu"
                  style={{ position: "absolute" }}
                  onClick={handleSubMenuClick}
                  onMouseEnter={() => setSubMenuVisible(true)}
                  onMouseLeave={handleMouseLeave}
                >
                  {/* Your submenu content goes here */}
                  <NavLink
                    to="/Solutions/Supplychain/Orderfulfilment"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Order Fulfilment
                  </NavLink>
                  <NavLink
                    to="/Solutions/Supplychain/Distributionanalytics"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Distribution Analytics
                  </NavLink>
                </div>
              )}
            </li>

            <li className="nav-item">
              <button className="sup-button">
                Partner Sign in <i className="bi bi-chevron-right"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Supplychain;
