import { useContext } from "react";
import { ActiveStoryContext } from "./Main";
import story1 from "../../../assets/images/story1.svg";
import story2 from "../../../assets/images/story2.svg";
import story3 from "../../../assets/images/story3.svg";
import story4 from "../../../assets/images/story4.svg";
import story5 from "../../../assets/images/story5.svg";
import story6 from "../../../assets/images/story6.svg";

const Hero = () => {
  const [active, setActive] = useContext(ActiveStoryContext);
  const stories = [
    {
      id: 1,
      year: "2019",
      image: story1,
    },
    {
      id: 2,
      year: "2020",
      image: story2,
    },
    {
      id: 3,
      year: "2021",
      image: story3,
    },
    {
      id: 4,
      year: "2022",
      image: story4,
    },
    {
      id: 5,
      year: "2023",
      image: story5,
    },
    {
      id: 6,
      year: "2024",
      image: story6,
    },
  ];

  return (
    <>
      <div className="story-hero">
        <img src={stories[active - 1].image} alt="" />
        <div className="year-btns">
          {stories.map((item) => (
            <div className="year" key={item.id}>
              <div className="connect-group">
                <input
                  name="currentYear"
                  className="year-radio"
                  onChange={() => setActive(item.id)}
                  type="radio"
                />
                <div className="connector"></div>
              </div>
              <label>{item.year}</label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Hero;
