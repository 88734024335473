import React from "react";
import Animation from "../Animation/Main"
import "./style.css";

const Hero = () => {
  return (
    <>
      <div className="sup-payment-hero">
        <div className="row">
          <div className="col">
            <h1 className="title">
              Your outlets can now <b>place direct orders</b> with your
              distributor through the Montra App,{" "}
              <b>enabling seamless invoicing</b> via the Montra Supply Chain SDK
            </h1>
            <button>Contact Sales</button>
          </div>
          <div className="col">
          <Animation />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
