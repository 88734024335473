import React from "react";
import debitcard from "../../../../assets/images/debitcard.svg";
import creditcard from "../../../../assets/images/creditcard.svg";
import virtualcard from "../../../../assets/images/virtualcard.svg";
import ci1 from "../../../../assets/images/ci1.svg";
import ci2 from "../../../../assets/images/ci2.svg";
import ci3 from "../../../../assets/images/ci3.svg";
import ci4 from "../../../../assets/images/ci4.svg";
import ci5 from "../../../../assets/images/ci5.svg";

const Body = () => {
  return (
    <>
      <div className="cip-body">
        <div className="cip-body-1">
          <h1>Card for every Payment Need</h1>
          <div className="card-types">
            <div className="card-type">
              <img src={debitcard} alt="" />
              <h6>Debit Cards</h6>
              <p>
                Customizable top-of-the-line debit cards with digital
                onboarding, limit configuration, fund transfer customization and
                more
              </p>
            </div>
            <div className="card-type">
              <img src={creditcard} alt="" />
              <h6>Credit Cards</h6>
              <p>
                Complete API stack for credit card issuance, mobile-app support
                and inbuilt security & compliance
              </p>
              <div className="credit-feature">
                <div>
                  <img src={ci1} alt="" />
                </div>
                <div>
                  <span>100% Secure</span>
                </div>
              </div>
              <div className="credit-feature">
                <div>
                  <img src={ci2} alt="" />
                </div>
                <div>
                  <span>End-to-End Support</span>
                </div>
              </div>
              <div className="credit-feature">
                <div>
                  <img src={ci3} alt="" />
                </div>
                <div>
                  <span>Fully Customizable</span>
                </div>
              </div>
              <div className="credit-feature">
                <div>
                  <img src={ci4} alt="" />
                </div>
                <div>
                  <span>Improved Customer Loyalty</span>
                </div>
              </div>
              <div className="credit-feature">
                <div>
                  <img src={ci5} alt="" />
                </div>
                <div>
                  <span>Custom Deployment Stack</span>
                </div>
              </div>
            </div>
            <div className="card-type">
              <img src={virtualcard} alt="" />
              <h6>Virtual Cards</h6>
              <p>
                A virtual card is a digital card that only exists on your phone.
                Get all the benefits of any card form i.e., Debit, Credit or
                Pre-paid & spend online, in-store and abroad while saving on
                currency conversion fees, with the extra layer of security of
                our virtual cards
              </p>
            </div>
          </div>
        </div>
        <div className="prepaid-card">
          <div className="prepaid-card-header">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="31"
              viewBox="0 0 50 31"
              fill="none"
            >
              <mask
                id="mask0_1108_5915"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="50"
                height="31"
              >
                <path d="M0.5 0H49.5V30.2055H0.5V0Z" fill="white" />
              </mask>
              <g mask="url(#mask0_1108_5915)">
                <path
                  d="M49.2748 3.90437V26.0242C49.2748 26.4585 49.2038 26.8781 49.0689 27.2683C48.5541 28.8134 47.092 29.9277 45.3696 29.9277H4.40523C2.24931 29.9277 0.5 28.1811 0.5 26.0251V3.90523C0.5 2.18533 1.61344 0.723253 3.15944 0.205904C3.55221 0.0709422 3.9692 3.20757e-07 4.40523 3.20757e-07H45.3696C47.5263 -0.000864817 49.2748 1.74845 49.2748 3.90437Z"
                  fill="url(#paint0_linear_1108_5915)"
                />
                <path
                  d="M12.9584 15.3983H5.18255C4.79669 15.3983 4.48438 15.086 4.48438 14.7001V7.92087C4.48438 7.53501 4.79669 7.22266 5.18255 7.22266H12.9584C13.3442 7.22266 13.6566 7.53501 13.6566 7.92087V14.7001C13.6566 15.086 13.3442 15.3983 12.9584 15.3983Z"
                  fill="#2C007D"
                />
                <path
                  d="M8.58476 22.8223H5.1764C4.82015 22.8223 4.53125 22.3714 4.53125 21.8154C4.53125 21.2595 4.82015 20.8086 5.1764 20.8086H8.58476C8.94096 20.8086 9.22987 21.2595 9.22987 21.8154C9.22941 22.3714 8.94096 22.8223 8.58476 22.8223Z"
                  fill="white"
                />
                <path
                  d="M14.616 22.8223H11.2077C10.8514 22.8223 10.5625 22.3714 10.5625 21.8154C10.5625 21.2595 10.8514 20.8086 11.2077 20.8086H14.616C14.9722 20.8086 15.2611 21.2595 15.2611 21.8154C15.2611 22.3714 14.9722 22.8223 14.616 22.8223Z"
                  fill="white"
                />
                <path
                  d="M21.3425 22.8223H17.9343C17.578 22.8223 17.2891 22.3714 17.2891 21.8154C17.2891 21.2595 17.578 20.8086 17.9343 20.8086H21.3425C21.6988 20.8086 21.9877 21.2595 21.9877 21.8154C21.9877 22.3714 21.6988 22.8223 21.3425 22.8223Z"
                  fill="white"
                />
                <path
                  d="M27.3738 22.8223H23.9655C23.6092 22.8223 23.3203 22.3714 23.3203 21.8154C23.3203 21.2595 23.6092 20.8086 23.9655 20.8086H27.3738C27.7301 20.8086 28.019 21.2595 28.019 21.8154C28.0185 22.3714 27.7301 22.8223 27.3738 22.8223Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.0768 9.50834H13.6574V9.26262H12.0768C11.7983 9.26262 11.5708 9.49012 11.5708 9.77046V11.1875H10.5957V10.3008C10.5957 10.0179 10.3656 9.78862 10.0836 9.78862H9.19413V7.22266H8.94848V9.78862H8.08157C7.79959 9.78862 7.56942 10.0187 7.56942 10.3008V11.1875H6.5711V9.77046C6.5711 9.49012 6.34443 9.26262 6.06499 9.26262H4.48438V9.50834H6.06499C6.2086 9.50834 6.3254 9.62601 6.3254 9.77131V12.8521C6.3254 12.9974 6.2086 13.1151 6.06499 13.1151H4.48438V13.3608H6.06499C6.34357 13.3608 6.5711 13.1332 6.5711 12.853V11.435H7.56942V12.3217C7.56942 12.6047 7.79959 12.8348 8.08157 12.8348H8.94848V15.4008H9.19413V12.8348H10.0836C10.3656 12.8348 10.5957 12.6047 10.5957 12.3217V11.435H11.5708V12.853C11.5708 13.1332 11.7974 13.3608 12.0768 13.3608H13.6574V13.1151H12.0768C11.9332 13.1151 11.8164 12.9974 11.8164 12.8521V9.77131C11.8164 9.62686 11.9332 9.50834 12.0768 9.50834ZM10.35 12.3209C10.35 12.4679 10.2306 12.5882 10.0827 12.5882H8.08079C7.93366 12.5882 7.81344 12.4679 7.81344 12.3209V10.3025C7.81344 10.1555 7.93287 10.0352 8.08079 10.0352H10.0827C10.2297 10.0352 10.35 10.1555 10.35 10.3025V12.3209Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.1"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M40.0957 18.122C39.1787 18.8109 38.5855 19.9076 38.5855 21.1429C38.5855 22.3782 39.1787 23.4749 40.0957 24.1637C39.4647 24.6377 38.6804 24.9185 37.8304 24.9185C35.7451 24.9185 34.0547 23.2281 34.0547 21.1429C34.0547 19.0576 35.7451 17.3672 37.8304 17.3672C38.6804 17.3672 39.4647 17.6481 40.0957 18.122Z"
                  fill="#BF9BFF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M40.1016 18.122C41.0186 18.8109 41.6118 19.9076 41.6118 21.1429C41.6118 22.3782 41.0186 23.4749 40.1016 24.1637C40.7326 24.6377 41.5169 24.9185 42.3669 24.9185C44.4522 24.9185 46.1426 23.2281 46.1426 21.1429C46.1426 19.0576 44.4522 17.3672 42.3669 17.3672C41.5169 17.3672 40.7326 17.6481 40.1016 18.122Z"
                  fill="#BF9BFF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M40.1041 24.1627C41.0211 23.4739 41.6143 22.3772 41.6143 21.1419C41.6143 19.9067 41.0211 18.8099 40.1041 18.1211C39.1869 18.8099 38.5938 19.9067 38.5938 21.1419C38.5938 22.3772 39.1869 23.4739 40.1041 24.1627Z"
                  fill="#2C007D"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_1108_5915"
                  x1="46.8151"
                  y1="1.34246"
                  x2="8.25073"
                  y2="62.207"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#CDE1FF" />
                  <stop offset="0.501376" stop-color="#BF9BFF" />
                  <stop offset="1" stop-color="#6E1DFF" />
                </linearGradient>
              </defs>
            </svg>
            <h6>Prepaid Cards</h6>
            <p>
              We build single/multiuse reloadable cards, multicurrency forex
              cards, Prepaid cards for JIT-based lending, fleet, travel,
              corporate cards.
            </p>
          </div>

          <div className="cip-cards">
            <div className="cip-card-1">
              <div className="cip-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="37"
                  viewBox="0 0 48 37"
                  fill="none"
                >
                  <mask
                    id="mask0_1162_5312"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="48"
                    height="37"
                  >
                    <path d="M0 0.205078H48V36.2051H0V0.205078Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_1162_5312)">
                    <path
                      d="M41.5226 15.1528V32.8594C41.5226 33.2071 41.4622 33.5429 41.3473 33.8553C40.9091 35.0921 39.6643 35.9841 38.198 35.9841H3.32457C1.4892 35.9841 0 34.5859 0 32.8601V15.1534C0 13.7767 0.947878 12.6063 2.26401 12.1922C2.59839 12.0841 2.95337 12.0273 3.32457 12.0273H38.198C40.0341 12.0267 41.5226 13.4269 41.5226 15.1528Z"
                      fill="url(#paint0_linear_1162_5312)"
                    />
                    <path
                      d="M10.6125 24.3531H3.9928C3.66432 24.3531 3.39844 24.1031 3.39844 23.7941V18.3675C3.39844 18.0586 3.66432 17.8086 3.9928 17.8086H10.6125C10.941 17.8086 11.2068 18.0586 11.2068 18.3675V23.7941C11.2068 24.1031 10.941 24.3531 10.6125 24.3531Z"
                      fill="#2C007D"
                    />
                    <path
                      d="M6.88047 30.2936H3.97892C3.67564 30.2936 3.42969 29.9327 3.42969 29.4877C3.42969 29.0426 3.67564 28.6816 3.97892 28.6816H6.88047C7.18372 28.6816 7.42967 29.0426 7.42967 29.4877C7.42931 29.9327 7.18372 30.2936 6.88047 30.2936Z"
                      fill="white"
                    />
                    <path
                      d="M12.0211 30.2936H9.11952C8.81627 30.2936 8.57031 29.9327 8.57031 29.4877C8.57031 29.0426 8.81627 28.6816 9.11952 28.6816H12.0211C12.3244 28.6816 12.5703 29.0426 12.5703 29.4877C12.5703 29.9327 12.3244 30.2936 12.0211 30.2936Z"
                      fill="white"
                    />
                    <path
                      d="M17.7398 30.2936H14.8383C14.535 30.2936 14.2891 29.9327 14.2891 29.4877C14.2891 29.0426 14.535 28.6816 14.8383 28.6816H17.7398C18.0431 28.6816 18.289 29.0426 18.289 29.4877C18.289 29.9327 18.0431 30.2936 17.7398 30.2936Z"
                      fill="white"
                    />
                    <path
                      d="M22.8805 30.2936H19.979C19.6756 30.2936 19.4297 29.9327 19.4297 29.4877C19.4297 29.0426 19.6756 28.6816 19.979 28.6816H22.8805C23.1838 28.6816 23.4297 29.0426 23.4297 29.4877C23.4293 29.9327 23.1838 30.2936 22.8805 30.2936Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.86198 19.6382H11.2076V19.4415H9.86198C9.62484 19.4415 9.43114 19.6237 9.43114 19.848V20.9825H8.6011V20.2726C8.6011 20.0461 8.40521 19.8626 8.16509 19.8626H7.40791V17.8086H7.19877V19.8626H6.46079C6.22073 19.8626 6.0248 20.0468 6.0248 20.2726V20.9825H5.17489V19.848C5.17489 19.6237 4.98193 19.4415 4.74404 19.4415H3.39844V19.6382H4.74404C4.86629 19.6382 4.96572 19.7325 4.96572 19.8488V22.3149C4.96572 22.4312 4.86629 22.5254 4.74404 22.5254H3.39844V22.7221H4.74404C4.98119 22.7221 5.17489 22.5399 5.17489 22.3156V21.1805H6.0248V21.8903C6.0248 22.1168 6.22073 22.301 6.46079 22.301H7.19877V24.3551H7.40791V22.301H8.16509C8.40521 22.301 8.6011 22.1168 8.6011 21.8903V21.1805H9.43114V22.3156C9.43114 22.5399 9.62411 22.7221 9.86198 22.7221H11.2076V22.5254H9.86198C9.73974 22.5254 9.64027 22.4312 9.64027 22.3149V19.8488C9.64027 19.7331 9.73974 19.6382 9.86198 19.6382ZM8.39197 21.8897C8.39197 22.0073 8.29032 22.1036 8.16436 22.1036H6.46012C6.3349 22.1036 6.23252 22.0073 6.23252 21.8897V20.274C6.23252 20.1563 6.33417 20.0599 6.46012 20.0599H8.16436C8.28953 20.0599 8.39197 20.1563 8.39197 20.274V21.8897Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.1"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33.7132 26.5339C32.9324 27.0853 32.4274 27.9632 32.4274 28.952C32.4274 29.9409 32.9324 30.8188 33.7132 31.3702C33.1759 31.7496 32.5082 31.9744 31.7846 31.9744C30.0094 31.9744 28.5703 30.6213 28.5703 28.952C28.5703 27.2829 30.0094 25.9297 31.7846 25.9297C32.5082 25.9297 33.1759 26.1545 33.7132 26.5339Z"
                      fill="#BF9BFF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33.7109 26.5339C34.4917 27.0853 34.9967 27.9632 34.9967 28.952C34.9967 29.9409 34.4917 30.8188 33.7109 31.3702C34.2482 31.7496 34.9159 31.9744 35.6395 31.9744C37.4147 31.9744 38.8538 30.6213 38.8538 28.952C38.8538 27.2829 37.4147 25.9297 35.6395 25.9297C34.9159 25.9297 34.2482 26.1545 33.7109 26.5339Z"
                      fill="#BF9BFF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33.7232 31.3694C34.504 30.8181 35.009 29.9402 35.009 28.9513C35.009 27.9625 34.504 27.0846 33.7232 26.5332C32.9425 27.0846 32.4375 27.9625 32.4375 28.9513C32.4375 29.9402 32.9425 30.8181 33.7232 31.3694Z"
                      fill="#2C007D"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M36.6491 2.6054C37.7302 3.07267 38.3718 4.07086 38.385 5.11943C39.2398 4.44605 40.4634 4.25405 41.5445 4.72132L45.2159 6.30824L45.4755 6.42051L45.3562 6.66457L44.7936 7.81538C44.4189 8.58181 43.8196 9.17633 43.1081 9.57899L44.752 10.2895C45.5709 10.6435 45.93 11.5555 45.5536 12.3256L44.4285 14.6272L44.3091 14.8713L44.0495 14.759L26.9159 7.3533L26.6562 7.24109L26.7756 6.99697L27.9007 4.69546C28.2772 3.92542 29.2471 3.58781 30.0661 3.94178L31.71 4.65235C31.5718 3.88241 31.6614 3.06757 32.0361 2.30112L32.5986 1.15035L32.718 0.90625L32.9775 1.01846L36.6491 2.6054ZM38.8925 7.75688L39.8567 8.17366C40.9754 8.65717 42.2886 8.26364 42.9159 7.29943L40.7432 6.36035C40.2122 6.13079 39.5798 6.35092 39.3357 6.8502L38.8925 7.75688ZM34.9614 6.05772L35.9257 6.4745L36.3688 5.56782C36.613 5.06856 36.3788 4.47388 35.8479 4.24439L33.6751 3.30525C33.3053 4.38076 33.8427 5.57417 34.9614 6.05772Z"
                      fill="url(#paint1_linear_1162_5312)"
                      stroke="#2C007D"
                    />
                    <path
                      d="M31.3846 21.0417L31.125 20.9295L31.2443 20.6854L35.8149 11.3359L35.9342 11.0918L36.1939 11.204L44.1497 14.6428L44.4093 14.755L44.29 14.9991L41.0253 21.6773C40.2255 23.3133 38.0313 23.9146 36.1581 23.105L31.3846 21.0417Z"
                      fill="url(#paint2_linear_1162_5312)"
                      stroke="#2C007D"
                    />
                    <path
                      d="M31.1328 20.6386L31.0135 20.8827L30.7539 20.7705L25.6781 18.5765C23.7095 17.7256 22.6536 15.7353 23.4598 14.0864L26.7245 7.40819L26.8439 7.16406L27.1034 7.27627L35.5632 10.9328L35.8227 11.045L35.7034 11.2892L31.1328 20.6386Z"
                      fill="url(#paint3_linear_1162_5312)"
                      stroke="#2C007D"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1162_5312"
                      x1="39.4286"
                      y1="13.102"
                      x2="9.40505"
                      y2="63.4955"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.501376" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1162_5312"
                      x1="38.9774"
                      y1="3.90746"
                      x2="35.3775"
                      y2="17.3442"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1162_5312"
                      x1="40.0525"
                      y1="13.1675"
                      x2="32.1996"
                      y2="30.6896"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_1162_5312"
                      x1="31.2139"
                      y1="9.34862"
                      x2="21.2572"
                      y2="25.7456"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                  </defs>
                </svg>
                <h6>Gift Card</h6>
                <p>
                  Launch ready-to-use gift cards with predefined limits,
                  rewards. Enjoy improved patronage, brand visibility.
                </p>
              </div>
              <div className="cip-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="41"
                  height="37"
                  viewBox="0 0 41 37"
                  fill="none"
                >
                  <mask
                    id="mask0_1162_5255"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="41"
                    height="37"
                  >
                    <path
                      d="M0 0.205078H40.2845V36.2051H0V0.205078Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_1162_5255)">
                    <path
                      d="M37.0538 16.338V33.0471C37.0538 33.3752 37 33.6921 36.8974 33.9868C36.5063 35.154 35.3956 35.9958 34.0871 35.9958H2.96678C1.32893 35.9958 0 34.6763 0 33.0477V16.3386C0 15.0395 0.845865 13.935 2.02035 13.5442C2.31874 13.4422 2.63552 13.3887 2.96678 13.3887H34.0871C35.7255 13.388 37.0538 14.7094 37.0538 16.338Z"
                      fill="url(#paint0_linear_1162_5255)"
                    />
                    <path
                      d="M9.46892 25.0215H3.56165C3.26851 25.0215 3.03125 24.7855 3.03125 24.494V19.3731C3.03125 19.0816 3.26851 18.8457 3.56165 18.8457H9.46892C9.76202 18.8457 9.99929 19.0816 9.99929 19.3731V24.494C9.99929 24.7855 9.76202 25.0215 9.46892 25.0215Z"
                      fill="#2C007D"
                    />
                    <path
                      d="M6.13411 30.6285H3.54481C3.27416 30.6285 3.05469 30.2879 3.05469 29.868C3.05469 29.448 3.27416 29.1074 3.54481 29.1074H6.13411C6.4047 29.1074 6.6242 29.448 6.6242 29.868C6.62385 30.2879 6.4047 30.6285 6.13411 30.6285Z"
                      fill="white"
                    />
                    <path
                      d="M10.7278 30.6285H8.13853C7.86787 30.6285 7.64844 30.2879 7.64844 29.868C7.64844 29.448 7.86787 29.1074 8.13853 29.1074H10.7278C10.9984 29.1074 11.2179 29.448 11.2179 29.868C11.2179 30.2879 10.9984 30.6285 10.7278 30.6285Z"
                      fill="white"
                    />
                    <path
                      d="M15.8215 30.6285H13.2323C12.9617 30.6285 12.7422 30.2879 12.7422 29.868C12.7422 29.448 12.9617 29.1074 13.2323 29.1074H15.8215C16.0922 29.1074 16.3117 29.448 16.3117 29.868C16.3117 30.2879 16.0922 30.6285 15.8215 30.6285Z"
                      fill="white"
                    />
                    <path
                      d="M20.4153 30.6285H17.8261C17.5554 30.6285 17.3359 30.2879 17.3359 29.868C17.3359 29.448 17.5554 29.1074 17.8261 29.1074H20.4153C20.686 29.1074 20.9054 29.448 20.9054 29.868C20.9051 30.2879 20.686 30.6285 20.4153 30.6285Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.79922 20.5723H9.99998V20.3867H8.79922C8.58755 20.3867 8.41467 20.5586 8.41467 20.7703V21.8408H7.67395V21.1709C7.67395 20.9572 7.49918 20.784 7.28492 20.784H6.60924V18.8457H6.4226V20.784H5.76401C5.54981 20.784 5.37495 20.9579 5.37495 21.1709V21.8408H4.61652V20.7703C4.61652 20.5586 4.44432 20.3867 4.23203 20.3867H3.03125V20.5723H4.23203C4.34113 20.5723 4.42986 20.6612 4.42986 20.771V23.0981C4.42986 23.2079 4.34113 23.2968 4.23203 23.2968H3.03125V23.4824H4.23203C4.44366 23.4824 4.61652 23.3106 4.61652 23.0988V22.0277H5.37495V22.6975C5.37495 22.9113 5.54981 23.085 5.76401 23.085H6.4226V25.0234H6.60924V23.085H7.28492C7.49918 23.085 7.67395 22.9113 7.67395 22.6975V22.0277H8.41467V23.0988C8.41467 23.3106 8.58692 23.4824 8.79922 23.4824H9.99998V23.2968H8.79922C8.69005 23.2968 8.60136 23.2079 8.60136 23.0981V20.771C8.60136 20.6619 8.69005 20.5723 8.79922 20.5723ZM7.48732 22.6968C7.48732 22.808 7.39663 22.8988 7.28423 22.8988H5.76343C5.65164 22.8988 5.56031 22.808 5.56031 22.6968V21.1722C5.56031 21.0611 5.65103 20.9703 5.76343 20.9703H7.28423C7.39593 20.9703 7.48732 21.0611 7.48732 21.1722V22.6968Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.1"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M30.0894 27.078C29.3926 27.5983 28.942 28.4268 28.942 29.3599C28.942 30.293 29.3926 31.1215 30.0894 31.6418C29.61 31.9999 29.0141 32.212 28.3683 32.212C26.7842 32.212 25.5 30.9351 25.5 29.3599C25.5 27.7848 26.7842 26.5078 28.3683 26.5078C29.0141 26.5078 29.61 26.7199 30.0894 27.078Z"
                      fill="#BF9BFF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M30.0859 27.078C30.7826 27.5983 31.2332 28.4268 31.2332 29.3599C31.2332 30.293 30.7826 31.1215 30.0859 31.6418C30.5653 31.9999 31.1612 32.212 31.807 32.212C33.3911 32.212 34.6753 30.9351 34.6753 29.3599C34.6753 27.7848 33.3911 26.5078 31.807 26.5078C31.1612 26.5078 30.5653 26.7199 30.0859 27.078Z"
                      fill="#BF9BFF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M30.077 31.6439C30.7737 31.1235 31.2244 30.2951 31.2244 29.362C31.2244 28.4288 30.7737 27.6004 30.077 27.0801C29.3803 27.6004 28.9297 28.4288 28.9297 29.362C28.9297 30.2951 29.3803 31.1235 30.077 31.6439Z"
                      fill="#2C007D"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M29.3233 1.36912L29.8332 7.90987C29.8286 7.91005 29.823 7.91022 29.8179 7.91045C29.755 7.91262 29.672 7.91417 29.5783 7.91417C29.4846 7.91417 29.4021 7.91262 29.3392 7.91045C29.3336 7.91022 29.328 7.91005 29.3233 7.90982V1.36912ZM29.3198 0.958984V0.965817H29.0683H28.559H28.3041V1.21934V7.90364C28.2936 7.90839 28.2744 7.91417 28.2478 7.91417C28.2213 7.91417 28.2022 7.90839 28.1916 7.90364V1.21934V0.965817H27.9366H27.484H27.2291V1.21934V7.90982C27.2285 7.90999 27.2279 7.91022 27.2273 7.91045C27.2204 7.91262 27.2113 7.91417 27.2011 7.91417C27.1908 7.91417 27.1818 7.91262 27.1749 7.91045C27.1743 7.91022 27.1737 7.90999 27.1731 7.90982V1.27561V0.959585L26.8629 1.02813C26.7093 1.06207 26.5754 1.11791 26.4675 1.20828C26.357 1.3008 26.2917 1.4144 26.2519 1.53302C26.2139 1.6463 26.1978 1.76887 26.187 1.88492C26.1821 1.93619 26.178 1.99018 26.1738 2.04415C26.1731 2.0523 26.1726 2.06046 26.1719 2.06861C26.1671 2.13164 26.162 2.19596 26.1552 2.26315L26.1548 2.26725L26.1545 2.27137L25.7584 8.12275L25.7578 8.13127V8.1398C25.7578 9.42005 26.5331 10.2203 27.4519 10.5233C27.4066 12.9033 27.2707 13.7468 26.8908 16.1059C26.7551 16.9489 26.5881 17.9854 26.3831 19.3547L26.3829 19.3563C26.2077 20.5755 26.3678 21.5425 26.7219 22.2225C27.0751 22.9008 27.6329 23.3072 28.235 23.3375C28.8441 23.3682 29.4144 23.0102 29.773 22.335C30.1293 21.664 30.2872 20.6707 30.1132 19.3589L30.113 19.3573C29.9868 18.4504 29.879 17.6893 29.7862 17.0336C29.3473 13.9347 29.2414 13.1875 29.106 10.5115C29.5241 10.3546 29.9204 10.1098 30.2196 9.75C30.567 9.33222 30.7683 8.77721 30.7376 8.0725L30.7373 8.06632L30.3417 2.2798C30.3417 2.27189 30.3417 2.26397 30.3417 2.25603C30.3418 2.00263 30.342 1.7369 30.2618 1.52422C30.2159 1.40253 30.1428 1.29193 30.03 1.2033C29.9198 1.11676 29.7855 1.06188 29.6328 1.02813L29.3198 0.958984Z"
                      fill="url(#paint1_linear_1162_5255)"
                      stroke="#2C007D"
                    />
                    <path
                      d="M36.7925 11.7183C38.3639 11.3058 39.5054 9.60737 39.5618 7.69783L39.5619 7.69417V7.69039C39.5619 6.54947 39.1686 4.89625 38.5492 3.52842C38.239 2.84322 37.8651 2.21416 37.4427 1.75216C37.024 1.29427 36.5256 0.966797 35.9684 0.966797C35.4017 0.966797 34.8971 1.28217 34.4731 1.7317C34.0467 2.1837 33.6739 2.79956 33.3669 3.47139C32.7536 4.81284 32.375 6.438 32.375 7.57783C32.375 9.59324 33.4588 11.306 35.0838 11.719C35.0151 13.6237 34.9243 14.1507 34.6992 15.4569C34.5556 16.2901 34.3573 17.4406 34.0752 19.4682C33.8861 20.6575 34.0273 21.5998 34.3633 22.2622C34.6991 22.9242 35.2406 23.3192 35.8296 23.3454C36.4238 23.3719 36.9822 23.0197 37.337 22.3629C37.69 21.7092 37.8522 20.7443 37.6925 19.4739L37.6916 19.4681C37.4846 18.0858 37.328 17.1154 37.2087 16.3754C36.8797 14.3368 36.8331 14.0481 36.7925 11.7183Z"
                      fill="url(#paint2_linear_1162_5255)"
                      stroke="#2C007D"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1162_5255"
                      x1="35.1852"
                      y1="14.4028"
                      x2="6.12498"
                      y2="60.5285"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.501376" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1162_5255"
                      x1="28.2492"
                      y1="1.21934"
                      x2="28.2492"
                      y2="23.0858"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CCDFFF" />
                      <stop offset="0.359375" stop-color="#C5BBFF" />
                      <stop offset="1" stop-color="#BA93FF" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1162_5255"
                      x1="35.9685"
                      y1="1.22032"
                      x2="35.9685"
                      y2="23.0932"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CCDFFF" />
                      <stop offset="0.359375" stop-color="#C5BBFF" />
                      <stop offset="1" stop-color="#BA93FF" />
                    </linearGradient>
                  </defs>
                </svg>
                <h6>Meal Pass</h6>
                <p>
                  Meal cards are restricted use cards that can be issued to
                  employees to use towards food purchases. Meal cards give the
                  employee freedom of choice and even let them order delivery
                  from sites that deliver food.
                </p>
              </div>
              <div className="cip-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="47"
                  height="37"
                  viewBox="0 0 47 37"
                  fill="none"
                >
                  <mask
                    id="mask0_1162_5203"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="47"
                    height="37"
                  >
                    <path d="M0 0.205078H47V36.2051H0V0.205078Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_1162_5203)">
                    <path
                      d="M41.6491 13.8141V32.645C41.6491 33.0147 41.5886 33.3719 41.4733 33.704C41.0337 35.0194 39.7853 35.9681 38.3145 35.9681H3.3347C1.49374 35.9681 0 34.4811 0 32.6457V13.8148C0 12.3506 0.950768 11.1059 2.27092 10.6655C2.6063 10.5506 2.96237 10.4902 3.3347 10.4902H38.3145C40.1561 10.4895 41.6491 11.9787 41.6491 13.8141Z"
                      fill="url(#paint0_linear_1162_5203)"
                    />
                    <path
                      d="M10.6423 23.6006H4.00242C3.67294 23.6006 3.40625 23.3347 3.40625 23.0062V17.235C3.40625 16.9065 3.67294 16.6406 4.00242 16.6406H10.6423C10.9718 16.6406 11.2384 16.9065 11.2384 17.235V23.0062C11.2384 23.3347 10.9718 23.6006 10.6423 23.6006Z"
                      fill="#2C007D"
                    />
                    <path
                      d="M6.90662 29.9193H3.99621C3.69201 29.9193 3.44531 29.5355 3.44531 29.0622C3.44531 28.5889 3.69201 28.2051 3.99621 28.2051H6.90662C7.21081 28.2051 7.45753 28.5889 7.45753 29.0622C7.45711 29.5355 7.21081 29.9193 6.90662 29.9193Z"
                      fill="white"
                    />
                    <path
                      d="M12.0628 29.9193H9.15247C8.84828 29.9193 8.60156 29.5355 8.60156 29.0622C8.60156 28.5889 8.84828 28.2051 9.15247 28.2051H12.0628C12.3671 28.2051 12.6138 28.5889 12.6138 29.0622C12.6138 29.5355 12.3671 29.9193 12.0628 29.9193Z"
                      fill="white"
                    />
                    <path
                      d="M17.7894 29.9193H14.879C14.5748 29.9193 14.3281 29.5355 14.3281 29.0622C14.3281 28.5889 14.5748 28.2051 14.879 28.2051H17.7894C18.0936 28.2051 18.3403 28.5889 18.3403 29.0622C18.3403 29.5355 18.0936 29.9193 17.7894 29.9193Z"
                      fill="white"
                    />
                    <path
                      d="M22.9534 29.9193H20.0431C19.7388 29.9193 19.4922 29.5355 19.4922 29.0622C19.4922 28.5889 19.7388 28.2051 20.0431 28.2051H22.9534C23.2577 28.2051 23.5044 28.5889 23.5044 29.0622C23.504 29.5355 23.2577 29.9193 22.9534 29.9193Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.88948 18.5864H11.2392V18.3772H9.88948C9.65163 18.3772 9.45732 18.571 9.45732 18.8096V20.016H8.62477V19.2611C8.62477 19.0202 8.42826 18.8251 8.18743 18.8251H7.42799V16.6406H7.21822V18.8251H6.47794C6.23717 18.8251 6.0406 19.021 6.0406 19.2611V20.016H5.18811V18.8096C5.18811 18.571 4.99456 18.3772 4.75594 18.3772H3.40625V18.5864H4.75594C4.87858 18.5864 4.9783 18.6866 4.9783 18.8103V21.433C4.9783 21.5567 4.87858 21.6569 4.75594 21.6569H3.40625V21.8661H4.75594C4.99382 21.8661 5.18811 21.6724 5.18811 21.4338V20.2266H6.0406V20.9816C6.0406 21.2224 6.23717 21.4183 6.47794 21.4183H7.21822V23.6028H7.42799V21.4183H8.18743C8.42826 21.4183 8.62477 21.2224 8.62477 20.9816V20.2266H9.45732V21.4338C9.45732 21.6724 9.65091 21.8661 9.88948 21.8661H11.2392V21.6569H9.88948C9.76687 21.6569 9.6671 21.5567 9.6671 21.433V18.8103C9.6671 18.6873 9.76687 18.5864 9.88948 18.5864ZM8.41499 20.9808C8.41499 21.106 8.31302 21.2084 8.18666 21.2084H6.47722C6.35163 21.2084 6.24895 21.106 6.24895 20.9808V19.2626C6.24895 19.1373 6.35092 19.035 6.47722 19.035H8.18666C8.31225 19.035 8.41499 19.1373 8.41499 19.2626V20.9808Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.1"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33.8148 25.92C33.0317 26.5064 32.5251 27.44 32.5251 28.4916C32.5251 29.5432 33.0317 30.4769 33.8148 31.0633C33.2759 31.4668 32.6062 31.7059 31.8803 31.7059C30.0997 31.7059 28.6562 30.2669 28.6562 28.4916C28.6562 26.7165 30.0997 25.2773 31.8803 25.2773C32.6062 25.2773 33.2759 25.5164 33.8148 25.92Z"
                      fill="#BF9BFF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33.8203 25.92C34.6034 26.5064 35.1099 27.44 35.1099 28.4916C35.1099 29.5432 34.6034 30.4769 33.8203 31.0633C34.3591 31.4668 35.0289 31.7059 35.7547 31.7059C37.5353 31.7059 38.9788 30.2669 38.9788 28.4916C38.9788 26.7165 37.5353 25.2773 35.7547 25.2773C35.0289 25.2773 34.3591 25.5164 33.8203 25.92Z"
                      fill="#BF9BFF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33.8208 31.0671C34.604 30.4808 35.1105 29.5471 35.1105 28.4955C35.1105 27.4439 34.604 26.5103 33.8208 25.9238C33.0377 26.5103 32.5312 27.4439 32.5312 28.4955C32.5312 29.5471 33.0377 30.4808 33.8208 31.0671Z"
                      fill="#2C007D"
                    />
                    <path
                      d="M35.8497 13.2949L36.3736 12.2791C36.497 12.0399 36.4027 11.746 36.1625 11.6228C36.1624 11.6228 36.1625 11.6229 36.1625 11.6228L34.2755 10.6554C34.2275 10.6308 34.1762 10.6145 34.1234 10.6068L34.0822 10.8896L34.1257 10.6072C33.9238 10.5763 33.7159 10.6747 33.6173 10.8659L33.0933 11.8817C32.9697 12.1213 33.0647 12.415 33.3045 12.5379L35.1914 13.5054C35.3826 13.6034 35.6085 13.5637 35.7548 13.4222C35.7933 13.3853 35.8253 13.3422 35.8497 13.2949Z"
                      fill="url(#paint1_linear_1162_5203)"
                    />
                    <path
                      d="M36.1625 11.6228C36.4027 11.746 36.497 12.0399 36.3736 12.2791L35.8497 13.2949C35.8253 13.3422 35.7933 13.3853 35.7548 13.4222C35.6085 13.5637 35.3826 13.6034 35.1914 13.5054L33.3045 12.5379C33.0647 12.415 32.9697 12.1213 33.0933 11.8817L33.6173 10.8659C33.7159 10.6747 33.9238 10.5763 34.1257 10.6072L34.0822 10.8896L34.1234 10.6068C34.1762 10.6145 34.2275 10.6308 34.2755 10.6554L36.1625 11.6228ZM36.1625 11.6228C36.1624 11.6228 36.1625 11.6229 36.1625 11.6228Z"
                      stroke="#2C007D"
                    />
                    <path
                      d="M33.683 11.888L34.0411 12.0717C34.1997 12.153 34.3943 12.0908 34.4758 11.9327L34.6364 11.6214C34.7179 11.4633 34.6555 11.2694 34.497 11.1881L34.1388 11.0044C33.9803 10.9232 33.7857 10.9853 33.7042 11.1434L33.5436 11.4547C33.4621 11.6128 33.5245 11.8068 33.683 11.888Z"
                      fill="url(#paint2_linear_1162_5203)"
                      stroke="#2C007D"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M34.1021 12.155C34.13 12.101 34.1478 12.0331 34.1408 11.9599C34.1342 11.8904 34.0972 11.7725 33.9725 11.7085L33.8155 11.6281C33.6908 11.5641 33.5732 11.6028 33.5127 11.6378C33.4488 11.6748 33.4039 11.7285 33.3761 11.7825C33.3482 11.8364 33.3305 11.9041 33.3375 11.9773C33.3441 12.0468 33.381 12.1647 33.5057 12.2287L33.6627 12.3092C33.7875 12.3732 33.9052 12.3344 33.9657 12.2994C34.0295 12.2625 34.0743 12.2088 34.1021 12.155ZM34.025 11.7749C34.0702 11.8161 34.0987 11.8614 34.1162 11.9021C34.131 11.9369 34.1379 11.9685 34.1405 11.9915C34.1487 12.0654 34.1277 12.1251 34.1029 12.1663C34.0782 12.2075 34.0338 12.257 33.959 12.2943C33.9357 12.3059 33.9018 12.3194 33.86 12.3293C33.8112 12.3409 33.7518 12.3474 33.6864 12.3397C33.6412 12.2986 33.6127 12.2533 33.5952 12.2126C33.5804 12.1777 33.5735 12.1461 33.5709 12.1232C33.5628 12.0493 33.5838 11.9896 33.6085 11.9483L33.6734 11.9769C33.6511 12.0139 33.6556 12.0538 33.6832 12.066L33.9479 12.1828C33.9755 12.1949 34.0159 12.1748 34.0382 12.1378C34.0603 12.1008 34.056 12.0608 34.0282 12.0487L33.7636 11.9319C33.7361 11.9197 33.6956 11.9401 33.6734 11.9769L33.6085 11.9483C33.6331 11.9073 33.6776 11.8576 33.7524 11.8204C33.7757 11.8088 33.8097 11.7952 33.8515 11.7854C33.9002 11.7738 33.9596 11.7673 34.025 11.7749Z"
                      fill="url(#paint3_linear_1162_5203)"
                    />
                    <path
                      d="M33.6085 11.9483C33.5838 11.9896 33.5628 12.0493 33.5709 12.1232C33.5735 12.1461 33.5804 12.1777 33.5952 12.2126C33.6127 12.2533 33.6412 12.2986 33.6864 12.3397C33.7518 12.3474 33.8112 12.3409 33.86 12.3293C33.9018 12.3194 33.9357 12.3059 33.959 12.2943C34.0338 12.257 34.0782 12.2075 34.1029 12.1663C34.1277 12.1251 34.1487 12.0654 34.1405 11.9915C34.1379 11.9685 34.131 11.9369 34.1162 11.9021C34.0987 11.8614 34.0702 11.8161 34.025 11.7749C33.9596 11.7673 33.9002 11.7738 33.8515 11.7854C33.8097 11.7952 33.7757 11.8088 33.7524 11.8204C33.6776 11.8576 33.6331 11.9073 33.6085 11.9483ZM33.6085 11.9483L33.6734 11.9769M33.6734 11.9769C33.6511 12.0139 33.6556 12.0538 33.6832 12.066L33.9479 12.1828C33.9755 12.1949 34.0159 12.1748 34.0382 12.1378C34.0603 12.1008 34.056 12.0608 34.0282 12.0487L33.7636 11.9319C33.7361 11.9197 33.6956 11.9401 33.6734 11.9769ZM34.1021 12.155C34.13 12.101 34.1478 12.0331 34.1408 11.9599C34.1342 11.8904 34.0972 11.7725 33.9725 11.7085L33.8155 11.6281C33.6908 11.5641 33.5732 11.6028 33.5127 11.6378C33.4488 11.6748 33.4039 11.7285 33.3761 11.7825C33.3482 11.8364 33.3305 11.9041 33.3375 11.9773C33.3441 12.0468 33.381 12.1647 33.5057 12.2287L33.6627 12.3092C33.7875 12.3732 33.9052 12.3344 33.9657 12.2994C34.0295 12.2625 34.0743 12.2088 34.1021 12.155Z"
                      stroke="#2C007D"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M34.1249 11.9279C34.1737 11.9164 34.2332 11.9099 34.2986 11.9175C34.3438 11.9587 34.3722 12.004 34.3896 12.0447C34.4046 12.0795 34.4115 12.1111 34.414 12.1341C34.4222 12.2081 34.4012 12.2678 34.3765 12.309C34.3517 12.3503 34.3074 12.3997 34.2324 12.437C34.2092 12.4485 34.1752 12.4621 34.1334 12.472C34.0847 12.4835 34.0253 12.49 33.9599 12.4823C33.9146 12.4412 33.8862 12.3959 33.8688 12.3552C33.8539 12.3203 33.8469 12.2887 33.8444 12.2658C33.8362 12.1919 33.8573 12.1322 33.882 12.0909C33.9067 12.0497 33.9511 12.0002 34.026 11.9629C34.0493 11.9514 34.0832 11.9378 34.1249 11.9279ZM33.7792 12.3713L33.9362 12.4518C34.0609 12.5157 34.1786 12.4771 34.2391 12.4421C34.3029 12.4051 34.3479 12.3514 34.3757 12.2973C34.4035 12.2434 34.4213 12.1757 34.4143 12.1025C34.4076 12.033 34.3708 11.9151 34.246 11.8511L34.089 11.7706C33.9642 11.7066 33.8466 11.7454 33.7861 11.7804C33.7223 11.8174 33.6773 11.8711 33.6495 11.9251C33.6217 11.9791 33.6039 12.0467 33.6109 12.1199C33.6176 12.1894 33.6544 12.3074 33.7792 12.3713ZM33.931 12.3272C33.8907 12.3002 33.8842 12.2117 33.9166 12.1298C33.949 12.0478 34.0079 12.0032 34.0483 12.0301L34.4341 12.2888C34.4744 12.3158 34.4809 12.4043 34.4485 12.4863C34.4161 12.5682 34.3573 12.6128 34.3169 12.5858L33.931 12.3272Z"
                      fill="url(#paint4_linear_1162_5203)"
                      stroke="#2C007D"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M34.4452 12.0861C34.4941 12.0746 34.5534 12.0681 34.6188 12.0756C34.6641 12.1169 34.6924 12.1621 34.7099 12.2028C34.7248 12.2377 34.7318 12.2693 34.7343 12.2923C34.7425 12.3663 34.7214 12.4259 34.6967 12.4672C34.672 12.5085 34.6276 12.5578 34.5527 12.5951C34.5294 12.6066 34.4955 12.6203 34.4538 12.6301C34.405 12.6416 34.3455 12.6481 34.2801 12.6406C34.2349 12.5993 34.2065 12.5542 34.1891 12.5133C34.1741 12.4785 34.1672 12.447 34.1647 12.4239C34.1565 12.35 34.1775 12.2903 34.2022 12.2491C34.227 12.2078 34.2713 12.1584 34.3462 12.1212C34.3695 12.1095 34.4035 12.096 34.4452 12.0861ZM34.0995 12.5294L34.2564 12.61C34.3812 12.6739 34.4989 12.6352 34.5594 12.6002C34.6232 12.5633 34.6681 12.5095 34.696 12.4556C34.7238 12.4015 34.7416 12.3339 34.7346 12.2606C34.7279 12.1912 34.691 12.0732 34.5663 12.0093L34.4093 11.9288C34.2846 11.8649 34.1669 11.9036 34.1064 11.9386C34.0425 11.9755 33.9977 12.0292 33.9698 12.0832C33.942 12.1372 33.9242 12.2049 33.9312 12.2781C33.9379 12.3476 33.9747 12.4655 34.0995 12.5294ZM34.2513 12.4854C34.211 12.4584 34.2045 12.3699 34.2369 12.2879C34.2693 12.2058 34.3282 12.1612 34.3685 12.1884L34.7543 12.4469C34.7948 12.4738 34.8013 12.5623 34.7688 12.6443C34.7364 12.7264 34.6774 12.771 34.6371 12.744L34.2513 12.4854Z"
                      fill="url(#paint5_linear_1162_5203)"
                      stroke="#2C007D"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M34.9693 12.6003C34.9972 12.5463 35.015 12.4785 35.008 12.4052C35.0014 12.3357 34.9645 12.2178 34.8398 12.1538L34.6828 12.0734C34.558 12.0094 34.4404 12.0481 34.3798 12.0831C34.316 12.12 34.2712 12.1738 34.2433 12.2278C34.2154 12.2818 34.1977 12.3494 34.2046 12.4226C34.2113 12.4922 34.2482 12.61 34.3729 12.674L34.53 12.7545C34.6547 12.8185 34.7724 12.7798 34.8329 12.7447C34.8967 12.7078 34.9415 12.6541 34.9693 12.6003ZM34.8923 12.2202C34.9375 12.2615 34.966 12.3066 34.9834 12.3474C34.9983 12.3823 35.0052 12.4139 35.0077 12.4368C35.016 12.5108 34.995 12.5705 34.9702 12.6117C34.9455 12.653 34.901 12.7024 34.8262 12.7396C34.803 12.7512 34.7689 12.7648 34.7272 12.7747C34.6785 12.7862 34.6191 12.7927 34.5536 12.7851C34.5083 12.7439 34.48 12.6986 34.4625 12.6579C34.4476 12.6231 34.4407 12.5915 34.4382 12.5686C34.4299 12.4945 34.451 12.4349 34.4758 12.3936L34.5405 12.4222C34.5183 12.4592 34.5227 12.4992 34.5505 12.5113L34.8151 12.6281C34.8428 12.6403 34.8832 12.6202 34.9054 12.5831C34.9276 12.5461 34.9231 12.5062 34.8955 12.4939L34.6309 12.3773C34.6032 12.3651 34.5627 12.3853 34.5405 12.4222L34.4758 12.3936C34.5004 12.3525 34.5448 12.303 34.6197 12.2657C34.6429 12.2541 34.677 12.2405 34.7187 12.2306C34.7675 12.2191 34.8269 12.2127 34.8923 12.2202Z"
                      fill="url(#paint6_linear_1162_5203)"
                    />
                    <path
                      d="M34.4758 12.3936C34.451 12.4349 34.4299 12.4945 34.4382 12.5686C34.4407 12.5915 34.4476 12.6231 34.4625 12.6579C34.48 12.6986 34.5083 12.7439 34.5536 12.7851C34.6191 12.7927 34.6785 12.7862 34.7272 12.7747C34.7689 12.7648 34.803 12.7512 34.8262 12.7396C34.901 12.7024 34.9455 12.653 34.9702 12.6117C34.995 12.5705 35.016 12.5108 35.0077 12.4368C35.0052 12.4139 34.9983 12.3823 34.9834 12.3474C34.966 12.3066 34.9375 12.2615 34.8923 12.2202C34.8269 12.2127 34.7675 12.2191 34.7187 12.2306C34.677 12.2405 34.6429 12.2541 34.6197 12.2657C34.5448 12.303 34.5004 12.3525 34.4758 12.3936ZM34.4758 12.3936L34.5405 12.4222M34.5405 12.4222C34.5183 12.4592 34.5227 12.4992 34.5505 12.5113L34.8151 12.6281C34.8428 12.6403 34.8832 12.6202 34.9054 12.5831C34.9276 12.5461 34.9231 12.5062 34.8955 12.4939L34.6309 12.3773C34.6032 12.3651 34.5627 12.3853 34.5405 12.4222ZM34.9693 12.6003C34.9972 12.5463 35.015 12.4785 35.008 12.4052C35.0014 12.3357 34.9645 12.2178 34.8398 12.1538L34.6828 12.0734C34.558 12.0094 34.4404 12.0481 34.3798 12.0831C34.316 12.12 34.2712 12.1738 34.2433 12.2278C34.2154 12.2818 34.1977 12.3494 34.2046 12.4226C34.2113 12.4922 34.2482 12.61 34.3729 12.674L34.53 12.7545C34.6547 12.8185 34.7724 12.7798 34.8329 12.7447C34.8967 12.7078 34.9415 12.6541 34.9693 12.6003Z"
                      stroke="#2C007D"
                    />
                    <path
                      d="M34.5312 11.3237L34.5795 11.2301L34.3247 11.0994L34.3133 11.0936L34.0585 10.9629L34.0101 11.0566L33.8045 10.9512L33.7133 11.1281L33.6734 11.2053L33.6676 11.2165L33.5821 11.3822L33.5764 11.3935L33.5366 11.4706L33.4453 11.6476L33.6509 11.753L33.6026 11.8467L33.8575 11.9773L33.8688 11.9831L34.1237 12.1138L34.172 12.02L34.3776 12.1255L34.4688 11.9486L34.5086 11.8715L34.5144 11.8601L34.5999 11.6945L34.6057 11.6832L34.6455 11.6061L34.7368 11.4292L34.5312 11.3237Z"
                      fill="url(#paint7_linear_1162_5203)"
                      stroke="#2C007D"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M35.5575 12.6374C35.5301 12.5838 35.4845 12.5377 35.4241 12.5082C35.2759 12.4361 35.094 12.4913 35.0179 12.6316C34.9416 12.772 35 12.9442 35.1481 13.0163C35.2086 13.0457 35.2747 13.054 35.3368 13.0439C35.2968 12.9659 35.2948 12.8715 35.3399 12.7885C35.385 12.7053 35.4673 12.652 35.5575 12.6374Z"
                      fill="url(#paint8_linear_1162_5203)"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M35.3588 12.5837L35.4556 13.1469L36.2075 13.0186L35.8746 12.3343L35.3588 12.5837ZM35.2158 12.8611L35.3124 13.4244L36.0644 13.2961L35.7315 12.6118L35.2158 12.8611ZM35.0103 13.0037C34.9352 12.9652 34.8777 12.9039 34.8432 12.833L35.8746 12.3343C35.8045 12.1901 35.6878 12.0659 35.5345 11.9873L35.0103 13.0037ZM35.5187 12.8411C35.4234 13.026 35.1957 13.0988 35.0103 13.0037L35.5345 11.9873C35.1569 11.7937 34.6935 11.942 34.4993 12.3184L35.5187 12.8411ZM35.3556 12.3342C35.5411 12.4292 35.6141 12.6562 35.5187 12.8411L34.4993 12.3184C34.3052 12.6948 34.4538 13.1569 34.8314 13.3505L35.3556 12.3342ZM35.146 12.2979C35.2311 12.2846 35.3226 12.2957 35.4045 12.3342L34.8314 13.3505C34.9991 13.4291 35.1841 13.4514 35.3573 13.4244L35.146 12.2979ZM35.7315 12.6118C35.7808 12.7132 35.7842 12.8383 35.7275 12.948L34.7081 12.4254C34.5929 12.6487 34.5989 12.9025 34.7001 13.1105L35.7315 12.6118ZM35.7275 12.948C35.6708 13.0579 35.567 13.1279 35.4556 13.1469L35.2622 12.0204C35.0337 12.0594 34.8232 12.2022 34.7081 12.4254L35.7275 12.948Z"
                      fill="#2C007D"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M35.4237 12.5842C35.4638 12.6623 35.4658 12.7566 35.4207 12.8397C35.3755 12.9228 35.2934 12.976 35.2031 12.9906C35.2306 13.0444 35.2761 13.0904 35.3366 13.1198C35.4847 13.192 35.6666 13.1367 35.7429 12.9964C35.819 12.8562 35.7607 12.6839 35.6125 12.6118C35.5521 12.5823 35.486 12.5741 35.4237 12.5842Z"
                      fill="url(#paint9_linear_1162_5203)"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M35.3508 12.5859L34.8351 12.8352L34.5024 12.1512L35.254 12.0228L35.3508 12.5859ZM35.2078 12.8633L34.6921 13.1127L34.3594 12.4286L35.111 12.3002L35.2078 12.8633ZM34.8392 12.499C34.7825 12.6088 34.7858 12.7338 34.8351 12.8352L35.8666 12.3366C35.9678 12.5446 35.9737 12.7984 35.8586 13.0216L34.8392 12.499ZM35.111 12.3002C34.9996 12.3192 34.8958 12.3892 34.8392 12.499L35.8586 13.0216C35.7434 13.2448 35.5331 13.3875 35.3046 13.4266L35.111 12.3002ZM35.7236 12.614C35.6891 12.5432 35.6315 12.4818 35.5564 12.4433L35.0322 13.4597C34.8788 13.3811 34.7623 13.2569 34.6921 13.1127L35.7236 12.614ZM35.5564 12.4433C35.371 12.3483 35.1433 12.4211 35.048 12.606L36.0674 13.1287C35.8733 13.5051 35.4098 13.6533 35.0322 13.4597L35.5564 12.4433ZM35.048 12.606C34.9526 12.7909 35.0256 13.0178 35.211 13.1129L35.7353 12.0966C36.1129 12.2901 36.2616 12.7522 36.0674 13.1287L35.048 12.606ZM35.211 13.1129C35.2931 13.1514 35.3846 13.1625 35.4698 13.1492L35.258 12.0227C35.4314 11.9956 35.6165 12.018 35.7842 12.0966L35.211 13.1129Z"
                      fill="#2C007D"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M35.2571 13.1535C35.4411 13.133 35.6085 13.0581 35.7004 12.9412C35.7923 12.8243 35.7879 12.6917 35.7067 12.582C35.5227 12.6025 35.3553 12.6773 35.2634 12.7943C35.1715 12.9112 35.1758 13.0437 35.2571 13.1535Z"
                      fill="url(#paint10_linear_1162_5203)"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M35.2179 12.8639L34.7022 13.1133L34.89 13.4991L35.3139 13.4272L35.2179 12.8639ZM35.3612 12.5864L35.8768 12.3369L35.6891 11.9512L35.2651 12.0231L35.3612 12.5864ZM34.8494 12.4995C34.9062 12.3895 35.0102 12.3194 35.1218 12.3005L35.3139 13.4272C35.5428 13.3884 35.7536 13.2457 35.8689 13.0221L34.8494 12.4995ZM34.8455 12.8358C34.7961 12.7344 34.7928 12.6092 34.8494 12.4995L35.8689 13.0221C35.9841 12.7988 35.978 12.545 35.8768 12.3369L34.8455 12.8358ZM35.7296 12.9508C35.6728 13.0607 35.5688 13.1308 35.4572 13.1497L35.2651 12.0231C35.0363 12.0619 34.8254 12.2046 34.7102 12.4281L35.7296 12.9508ZM35.7335 12.6145C35.783 12.7159 35.7863 12.8409 35.7296 12.9508L34.7102 12.4281C34.5949 12.6515 34.6009 12.9052 34.7022 13.1133L35.7335 12.6145Z"
                      fill="#2C007D"
                    />
                    <path
                      d="M36.4317 11.1248C36.4674 11.1702 36.4929 11.2203 36.5087 11.2723C36.5556 11.3 36.5983 11.3366 36.634 11.382L36.7857 11.5749L36.9625 11.7998L36.737 11.976L36.7202 11.9891C36.7292 12.1073 36.6807 12.2276 36.5801 12.3063L36.4511 12.4071L36.2256 12.5833L36.0488 12.3585L35.3409 11.4583L35.1641 11.2335L35.3895 11.0572L35.5186 10.9564C35.6192 10.8778 35.748 10.8596 35.8609 10.8964L35.8777 10.8834L36.1032 10.707L36.28 10.9319L36.4317 11.1248Z"
                      fill="url(#paint11_linear_1162_5203)"
                      stroke="#2C007D"
                    />
                    <path
                      d="M35.1768 12.3698L35 12.145L35.2255 11.9687L35.7495 11.5591L35.975 11.3828L36.1519 11.6077L36.4806 12.0257L36.6574 12.2506L36.4318 12.4268L36.0576 12.7194C35.8269 12.8996 35.5249 12.8125 35.374 12.6206L35.1768 12.3698Z"
                      fill="url(#paint12_linear_1162_5203)"
                      stroke="#2C007D"
                    />
                    <path
                      d="M35.5641 12.3862L35.3385 12.5625L35.1617 12.3377L34.9519 12.071C34.8073 11.887 34.7679 11.5647 35.0105 11.3751L35.3848 11.0825L35.6104 10.9062L35.7872 11.131L36.1367 11.5755L36.3135 11.8004L36.088 11.9767L35.5641 12.3862Z"
                      fill="url(#paint13_linear_1162_5203)"
                      stroke="#2C007D"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M24.6543 13.3939L24.3994 13.2633L24.2684 13.5174L23.2404 15.51L23.1094 15.7641L23.3642 15.8949L35.6753 22.2068L35.9302 22.3374L36.0613 22.0834L37.0892 20.0906L37.2203 19.8366L36.9654 19.7059L24.6543 13.3939ZM43.8394 7.82167L43.752 7.54972L43.4791 7.63691L42.1954 8.04711L41.9225 8.13436L42.01 8.40649L43.0132 11.525L40.1766 17.0241C40.1375 17.0999 40.1065 17.1621 40.0798 17.2158C40.0479 17.28 40.022 17.332 39.9953 17.3809C39.9803 17.4085 39.9683 17.4283 39.9594 17.4422C39.9146 17.4273 39.8447 17.3964 39.7312 17.3382L38.8503 16.8866C38.9563 16.5803 38.9649 16.2826 38.8594 16.006C38.7266 15.6576 38.4341 15.3995 38.0697 15.2126L37.8148 15.082L37.6838 15.336L36.0359 18.5307L35.9048 18.7848L36.1597 18.9155C36.517 19.0986 36.8953 19.1861 37.2552 19.0966C37.5417 19.0252 37.7816 18.8507 37.9705 18.5899L38.8523 19.0421C39.5412 19.3953 40.1419 19.4807 40.6735 19.2467C41.1877 19.0204 41.5634 18.5246 41.8855 17.9003L45.0315 11.8013L45.0858 11.6962L45.0496 11.5835L43.8394 7.82167ZM35.457 18.8955L35.7118 19.0261L35.8429 18.7721L41.717 7.38453C42.22 6.40931 41.8317 5.2025 40.8532 4.70085L34.6349 1.5127C33.6565 1.01105 32.446 1.39805 31.9428 2.37332L26.0688 13.7608L25.9377 14.0149L26.1926 14.1456L35.457 18.8955ZM33.7283 3.74276L39.5321 6.71845L37.9342 9.81606L32.1304 6.84045L33.7283 3.74276Z"
                      fill="url(#paint14_linear_1162_5203)"
                      stroke="#2C007D"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1162_5203"
                      x1="39.5488"
                      y1="11.6331"
                      x2="6.7612"
                      y2="63.5381"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.501376" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1162_5203"
                      x1="35.088"
                      y1="11.3933"
                      x2="34.0722"
                      y2="14.0462"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1162_5203"
                      x1="34.1868"
                      y1="11.3503"
                      x2="33.8523"
                      y2="12.0547"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_1162_5203"
                      x1="33.763"
                      y1="11.9224"
                      x2="33.686"
                      y2="12.0981"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint4_linear_1162_5203"
                      x1="34.0364"
                      y1="12.065"
                      x2="33.95"
                      y2="12.2366"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint5_linear_1162_5203"
                      x1="34.3567"
                      y1="12.2232"
                      x2="34.2703"
                      y2="12.3947"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint6_linear_1162_5203"
                      x1="34.6302"
                      y1="12.3677"
                      x2="34.5533"
                      y2="12.5434"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint7_linear_1162_5203"
                      x1="34.1879"
                      y1="11.3505"
                      x2="33.8923"
                      y2="12.0704"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint8_linear_1162_5203"
                      x1="35.2346"
                      y1="12.4803"
                      x2="34.9198"
                      y2="13.1282"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint9_linear_1162_5203"
                      x1="35.5032"
                      y1="12.6195"
                      x2="35.1609"
                      y2="13.2533"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint10_linear_1162_5203"
                      x1="35.3582"
                      y1="12.582"
                      x2="35.1131"
                      y2="13.1035"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint11_linear_1162_5203"
                      x1="36.3073"
                      y1="11.4297"
                      x2="35.5675"
                      y2="11.9529"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint12_linear_1162_5203"
                      x1="36.0906"
                      y1="11.993"
                      x2="35.23"
                      y2="12.8512"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint13_linear_1162_5203"
                      x1="35.7364"
                      y1="11.5296"
                      x2="34.6879"
                      y2="12.1552"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint14_linear_1162_5203"
                      x1="39.063"
                      y1="4.10424"
                      x2="25.8435"
                      y2="33.2952"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                  </defs>
                </svg>
                <h6>Fuel Card</h6>
                <p>
                  Track and monitor a wide range of fleet expenses including
                  toll payments, fuel expenses, travel, lodging expenses of your
                  fleet staff. Manage withdrawals, disbursement and
                  block/unblock cards seamlessly.
                </p>
              </div>
            </div>
            <div className="cip-card-2">
              <div className="cip-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="49"
                  height="37"
                  viewBox="0 0 49 37"
                  fill="none"
                >
                  <mask
                    id="mask0_1162_5293"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="49"
                    height="37"
                  >
                    <path d="M0 0.205078H49V36.2051H0V0.205078Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_1162_5293)">
                    <path
                      d="M44.507 12.2992V32.4068C44.507 32.8015 44.4423 33.183 44.3191 33.5376C43.8494 34.9422 42.5152 35.9551 40.9435 35.9551H3.56352C1.59624 35.9551 0 34.3674 0 32.4076V12.3C0 10.7365 1.01601 9.40746 2.42674 8.9372C2.78514 8.8145 3.16564 8.75 3.56352 8.75H40.9435C42.9115 8.74921 44.507 10.3394 44.507 12.2992Z"
                      fill="url(#paint0_linear_1162_5293)"
                    />
                    <path
                      d="M11.3732 22.7463H4.2777C3.92562 22.7463 3.64062 22.4624 3.64062 22.1116V15.9492C3.64062 15.5984 3.92562 15.3145 4.2777 15.3145H11.3732C11.7253 15.3145 12.0103 15.5984 12.0103 15.9492V22.1116C12.0103 22.4624 11.7253 22.7463 11.3732 22.7463Z"
                      fill="#2C007D"
                    />
                    <path
                      d="M7.3707 29.4946H4.26058C3.9355 29.4946 3.67188 29.0847 3.67188 28.5793C3.67188 28.0739 3.9355 27.6641 4.26058 27.6641H7.3707C7.69576 27.6641 7.95937 28.0739 7.95937 28.5793C7.95895 29.0847 7.69576 29.4946 7.3707 29.4946Z"
                      fill="white"
                    />
                    <path
                      d="M12.8863 29.4946H9.77617C9.45112 29.4946 9.1875 29.0847 9.1875 28.5793C9.1875 28.0739 9.45112 27.6641 9.77617 27.6641H12.8863C13.2114 27.6641 13.475 28.0739 13.475 28.5793C13.475 29.0847 13.2114 29.4946 12.8863 29.4946Z"
                      fill="white"
                    />
                    <path
                      d="M19.0113 29.4946H15.9012C15.5761 29.4946 15.3125 29.0847 15.3125 28.5793C15.3125 28.0739 15.5761 27.6641 15.9012 27.6641H19.0113C19.3364 27.6641 19.6 28.0739 19.6 28.5793C19.6 29.0847 19.3364 29.4946 19.0113 29.4946Z"
                      fill="white"
                    />
                    <path
                      d="M24.5269 29.4946H21.4169C21.0917 29.4946 20.8281 29.0847 20.8281 28.5793C20.8281 28.0739 21.0917 27.6641 21.4169 27.6641H24.5269C24.852 27.6641 25.1156 28.0739 25.1156 28.5793C25.1152 29.0847 24.852 29.4946 24.5269 29.4946Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.5687 17.3922H12.0111V17.1688H10.5687C10.3146 17.1688 10.1069 17.3757 10.1069 17.6305V18.9187H9.21721V18.1126C9.21721 17.8554 9.00724 17.647 8.74987 17.647H7.9383V15.3145H7.71413V17.647H6.92309C6.66578 17.647 6.45575 17.8562 6.45575 18.1126V18.9187H5.54475V17.6305C5.54475 17.3757 5.33792 17.1688 5.08293 17.1688H3.64062V17.3922H5.08293C5.21398 17.3922 5.32055 17.4992 5.32055 17.6313V20.4318C5.32055 20.5639 5.21398 20.6708 5.08293 20.6708H3.64062V20.8942H5.08293C5.33713 20.8942 5.54475 20.6874 5.54475 20.4326V19.1436H6.45575V19.9497C6.45575 20.2069 6.66578 20.416 6.92309 20.416H7.71413V22.7486H7.9383V20.416H8.74987C9.00724 20.416 9.21721 20.2069 9.21721 19.9497V19.1436H10.1069V20.4326C10.1069 20.6874 10.3138 20.8942 10.5687 20.8942H12.0111V20.6708H10.5687C10.4377 20.6708 10.3311 20.5639 10.3311 20.4318V17.6313C10.3311 17.5 10.4377 17.3922 10.5687 17.3922ZM8.99303 19.9489C8.99303 20.0826 8.88407 20.1919 8.74907 20.1919H6.92235C6.78809 20.1919 6.67839 20.0826 6.67839 19.9489V18.1142C6.67839 17.9805 6.78736 17.8711 6.92235 17.8711H8.74907C8.88327 17.8711 8.99303 17.9805 8.99303 18.1142V19.9489Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.1"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M36.1375 25.2233C35.3006 25.8495 34.7594 26.8464 34.7594 27.9693C34.7594 29.0922 35.3006 30.0892 36.1375 30.7153C35.5617 31.1462 34.8459 31.4015 34.0703 31.4015C32.1675 31.4015 30.625 29.8649 30.625 27.9693C30.625 26.0738 32.1675 24.5371 34.0703 24.5371C34.8459 24.5371 35.5617 24.7924 36.1375 25.2233Z"
                      fill="#BF9BFF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M36.1406 25.2233C36.9775 25.8495 37.5187 26.8464 37.5187 27.9693C37.5187 29.0922 36.9775 30.0892 36.1406 30.7153C36.7164 31.1462 37.4322 31.4015 38.2078 31.4015C40.1106 31.4015 41.6531 29.8649 41.6531 27.9693C41.6531 26.0738 40.1106 24.5371 38.2078 24.5371C37.4322 24.5371 36.7164 24.7924 36.1406 25.2233Z"
                      fill="#BF9BFF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M36.1359 30.7166C36.9728 30.0905 37.5141 29.0935 37.5141 27.9706C37.5141 26.8477 36.9728 25.8508 36.1359 25.2246C35.2991 25.8508 34.7578 26.8477 34.7578 27.9706C34.7578 29.0935 35.2991 30.0905 36.1359 30.7166Z"
                      fill="#2C007D"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33.8216 9.08602L33.8226 9.0873C37.2855 9.16559 40.0778 11.9488 40.1543 15.3988C40.3112 14.9158 40.3962 14.401 40.3962 13.8657C40.3962 11.0934 38.1402 8.84598 35.3573 8.84598C34.8212 8.84598 34.3055 8.93037 33.8216 9.08602ZM35.3048 5.84961C39.749 5.84961 43.3519 9.43858 43.3519 13.866C43.3519 18.2932 39.7492 21.8825 35.3048 21.8825C30.8605 21.8825 27.2578 18.2935 27.2578 13.866C27.2578 9.43882 30.8605 5.84961 35.3048 5.84961Z"
                      fill="url(#paint1_linear_1162_5293)"
                      stroke="#2C007D"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33.8048 5.34646L33.2031 5.45017L33.4795 4.90775C34.807 2.30251 37.5164 0.511718 40.6528 0.511719C45.0969 0.511719 48.6998 4.10069 48.6998 8.52813C48.6998 11.6524 46.902 14.3513 44.287 15.6741L43.7425 15.9496L43.8466 15.3501C43.9312 14.863 43.9836 14.3694 43.9836 13.8656C43.9836 9.08545 40.0936 5.21045 35.2954 5.21045H35.2122C34.735 5.21578 34.267 5.26678 33.8048 5.34646ZM40.7052 3.50816C40.1679 3.50816 39.6509 3.59298 39.166 3.74935C42.6312 3.82551 45.4257 6.60988 45.5019 10.0615C45.6575 9.58317 45.7425 9.0735 45.7442 8.54375V8.52794C45.7442 5.75556 43.488 3.50816 40.7052 3.50816Z"
                      fill="url(#paint2_linear_1162_5293)"
                      stroke="#2C007D"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1162_5293"
                      x1="42.2625"
                      y1="9.97034"
                      x2="7.26365"
                      y2="65.4186"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.501376" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1162_5293"
                      x1="35.3048"
                      y1="6.15469"
                      x2="34.8178"
                      y2="34.6228"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1162_5293"
                      x1="41.0731"
                      y1="0.816803"
                      x2="40.6125"
                      y2="27.7391"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.473958" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                  </defs>
                </svg>
                <h6>Purchase Card</h6>
                <p>
                  Launch prepaid cards for expense and petty cash management,
                  travel, entertainment, misc. purchases. Configure limits and
                  integrate online payments.
                </p>
              </div>
              <div className="cip-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="46"
                  height="37"
                  viewBox="0 0 46 37"
                  fill="none"
                >
                  <mask
                    id="mask0_1162_5237"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="46"
                    height="37"
                  >
                    <path
                      d="M0 0.205078H45.8221V36.2051H0V0.205078Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_1162_5237)">
                    <path
                      d="M41.1066 14.1645V32.7012C41.1066 33.0651 41.0468 33.4168 40.9331 33.7437C40.4993 35.0386 39.267 35.9724 37.8154 35.9724H3.29127C1.47429 35.9724 0 34.5086 0 32.7019V14.1652C0 12.7239 0.938383 11.4987 2.24133 11.0652C2.57236 10.952 2.92379 10.8926 3.29127 10.8926H37.8154C39.633 10.8919 41.1066 12.3578 41.1066 14.1645Z"
                      fill="url(#paint0_linear_1162_5237)"
                    />
                    <path
                      d="M10.509 23.7966H3.9556C3.63041 23.7966 3.36719 23.5349 3.36719 23.2115V17.5304C3.36719 17.2071 3.63041 16.9453 3.9556 16.9453H10.509C10.8341 16.9453 11.0974 17.2071 11.0974 17.5304V23.2115C11.0974 23.5349 10.8341 23.7966 10.509 23.7966Z"
                      fill="#2C007D"
                    />
                    <path
                      d="M6.80683 30.0176H3.93435C3.63411 30.0176 3.39062 29.6397 3.39062 29.1738C3.39062 28.7079 3.63411 28.3301 3.93435 28.3301H6.80683C7.10708 28.3301 7.35053 28.7079 7.35053 29.1738C7.35018 29.6397 7.10708 30.0176 6.80683 30.0176Z"
                      fill="white"
                    />
                    <path
                      d="M11.9005 30.0176H9.02807C8.72782 30.0176 8.48438 29.6397 8.48438 29.1738C8.48438 28.7079 8.72782 28.3301 9.02807 28.3301H11.9005C12.2008 28.3301 12.4443 28.7079 12.4443 29.1738C12.4443 29.6397 12.2008 30.0176 11.9005 30.0176Z"
                      fill="white"
                    />
                    <path
                      d="M17.5568 30.0176H14.6844C14.3841 30.0176 14.1406 29.6397 14.1406 29.1738C14.1406 28.7079 14.3841 28.3301 14.6844 28.3301H17.5568C17.8571 28.3301 18.1006 28.7079 18.1006 29.1738C18.1006 29.6397 17.8571 30.0176 17.5568 30.0176Z"
                      fill="white"
                    />
                    <path
                      d="M22.6505 30.0176H19.7781C19.4778 30.0176 19.2344 29.6397 19.2344 29.1738C19.2344 28.7079 19.4778 28.3301 19.7781 28.3301H22.6505C22.9508 28.3301 23.1943 28.7079 23.1943 29.1738C23.1939 29.6397 22.9508 30.0176 22.6505 30.0176Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.76601 18.8607H11.0981V18.6548H9.76601C9.5312 18.6548 9.33945 18.8455 9.33945 19.0804V20.2679H8.51771V19.5248C8.51771 19.2878 8.32379 19.0956 8.0861 19.0956H7.3365V16.9453H7.12948V19.0956H6.39885C6.16122 19.0956 5.96724 19.2885 5.96724 19.5248V20.2679H5.12584V19.0804C5.12584 18.8455 4.93481 18.6548 4.69931 18.6548H3.36719V18.8607H4.69931C4.82033 18.8607 4.91877 18.9594 4.91877 19.0811V21.6628C4.91877 21.7846 4.82033 21.8832 4.69931 21.8832H3.36719V22.0891H4.69931C4.93408 22.0891 5.12584 21.8985 5.12584 21.6635V20.4753H5.96724V21.2184C5.96724 21.4555 6.16122 21.6483 6.39885 21.6483H7.12948V23.7987H7.3365V21.6483H8.0861C8.32379 21.6483 8.51771 21.4555 8.51771 21.2184V20.4753H9.33945V21.6635C9.33945 21.8985 9.5305 22.0891 9.76601 22.0891H11.0981V21.8832H9.76601C9.64493 21.8832 9.54648 21.7846 9.54648 21.6628V19.0811C9.54648 18.9601 9.64493 18.8607 9.76601 18.8607ZM8.31069 21.2177C8.31069 21.3409 8.21006 21.4417 8.08534 21.4417H6.39821C6.27419 21.4417 6.17286 21.3409 6.17286 21.2177V19.5263C6.17286 19.4031 6.27349 19.3022 6.39821 19.3022H8.08534C8.20929 19.3022 8.31069 19.4031 8.31069 19.5263V21.2177Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.1"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33.3804 26.0779C32.6074 26.6551 32.1076 27.5742 32.1076 28.6093C32.1076 29.6445 32.6074 30.5636 33.3804 31.1408C32.8485 31.5381 32.1875 31.7734 31.4711 31.7734C29.7137 31.7734 28.2891 30.3568 28.2891 28.6093C28.2891 26.8619 29.7137 25.4453 31.4711 25.4453C32.1875 25.4453 32.8485 25.6807 33.3804 26.0779Z"
                      fill="#BF9BFF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33.375 26.0779C34.1479 26.6551 34.6479 27.5742 34.6479 28.6093C34.6479 29.6445 34.1479 30.5636 33.375 31.1408C33.9068 31.5381 34.5679 31.7734 35.2843 31.7734C37.0417 31.7734 38.4664 30.3568 38.4664 28.6093C38.4664 26.8619 37.0417 25.4453 35.2843 25.4453C34.5679 25.4453 33.9068 25.6807 33.375 26.0779Z"
                      fill="#BF9BFF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33.3744 31.143C34.1473 30.5658 34.6473 29.6467 34.6473 28.6115C34.6473 27.5764 34.1473 26.6573 33.3744 26.0801C32.6015 26.6573 32.1016 27.5764 32.1016 28.6115C32.1016 29.6467 32.6015 30.5658 33.3744 31.143Z"
                      fill="#2C007D"
                    />
                    <path
                      d="M25.9284 4.66037L25.658 4.46501L25.8965 4.23215L27.6917 2.47957L27.8256 2.34877L27.9989 2.42075L36.9632 6.14548L37.4159 5.69534C37.4158 5.69547 37.416 5.69521 37.4159 5.69534C39.7216 3.38659 41.4293 1.9641 42.6569 1.20425C43.2691 0.825311 43.7808 0.599088 44.2005 0.519191C44.6244 0.438503 44.9973 0.50166 45.2586 0.761441L45.2632 0.766119C45.5185 1.03208 45.5835 1.40226 45.5027 1.82698C45.423 2.24637 45.1966 2.75523 44.8176 3.36401C44.0578 4.58455 42.636 6.28235 40.3308 8.59091L40.3301 8.59161L39.8767 9.0425L43.6226 17.956L43.6958 18.1299L43.5616 18.2632L41.7664 20.0483L41.5311 20.2823L41.3368 20.0137L36.0036 12.6401C34.6388 13.8433 33.2796 14.9856 32.099 15.87L33.1892 19.7415L33.2338 19.8999L33.1168 20.0162L31.9091 21.217L31.6609 21.4638L31.4712 21.1703L29.2585 17.7479C29.0632 17.8392 28.8844 17.9066 28.7285 17.9445C28.6209 17.9707 28.5108 17.9861 28.407 17.9787C28.3037 17.9713 28.1795 17.9388 28.0819 17.8417C27.9843 17.7447 27.9516 17.6212 27.9442 17.5185C27.9367 17.4153 27.9523 17.3058 27.9786 17.1988C28.0166 17.0442 28.0842 16.8669 28.1756 16.6733L24.7359 14.5051L24.4375 14.317L24.6873 14.0686L25.895 12.8678L26.012 12.7515L26.1713 12.7958L30.0678 13.8807C30.9804 12.7089 32.1035 11.3572 33.3144 9.99634L25.9284 4.66037Z"
                      fill="url(#paint1_linear_1162_5237)"
                    />
                    <path
                      d="M37.4159 5.69534L36.9632 6.14548L27.9989 2.42075L27.8256 2.34877L27.6917 2.47957L25.8965 4.23215L25.658 4.46501L25.9284 4.66037L33.3144 9.99633C32.1035 11.3572 30.9804 12.7089 30.0678 13.8807L26.1713 12.7958L26.012 12.7515L25.895 12.8678L24.6873 14.0686L24.4375 14.317L24.7359 14.5051L28.1756 16.6733C28.0842 16.8669 28.0166 17.0442 27.9786 17.1988C27.9523 17.3058 27.9367 17.4153 27.9442 17.5185C27.9516 17.6212 27.9843 17.7447 28.0819 17.8417C28.1795 17.9388 28.3037 17.9713 28.407 17.9787C28.5108 17.9861 28.6209 17.9707 28.7285 17.9445C28.8844 17.9066 29.0632 17.8392 29.2585 17.7479L31.4712 21.1703L31.6609 21.4638L31.9091 21.217L33.1168 20.0162L33.2338 19.8999L33.1892 19.7415L32.099 15.87C33.2796 14.9856 34.6388 13.8433 36.0036 12.6401L41.3368 20.0137L41.5311 20.2823L41.7664 20.0483L43.5616 18.2632L43.6958 18.1299L43.6226 17.956L39.8767 9.0425L40.3301 8.59161L40.3308 8.59091C42.636 6.28235 44.0578 4.58455 44.8176 3.36401C45.1966 2.75523 45.423 2.24637 45.5027 1.82698C45.5835 1.40226 45.5185 1.03208 45.2632 0.766119L45.2586 0.761441C44.9973 0.50166 44.6244 0.438503 44.2005 0.519191C43.7808 0.599088 43.2691 0.825311 42.6569 1.20425C41.4293 1.9641 39.7216 3.38659 37.4159 5.69534ZM37.4159 5.69534C37.416 5.69521 37.4158 5.69547 37.4159 5.69534Z"
                      stroke="#2C007D"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1162_5237"
                      x1="39.0336"
                      y1="12.0176"
                      x2="6.79494"
                      y2="63.1883"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CDE1FF" />
                      <stop offset="0.501376" stop-color="#BF9BFF" />
                      <stop offset="1" stop-color="#6E1DFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1162_5237"
                      x1="44.1213"
                      y1="0.767362"
                      x2="28.3784"
                      y2="17.7323"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#CCDFFF" />
                      <stop offset="0.338542" stop-color="#C3B0FF" />
                      <stop offset="1" stop-color="#B78EFF" />
                    </linearGradient>
                  </defs>
                </svg>
                <h6>Travel Card</h6>
                <p>
                  Equip your employees with travel cards that are secure,
                  compliant, and reliable. Roll out features such as spend
                  analysis, multiple currency upload and companion mobile app.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="why-moneylink">
        <h1>Why MoneyLink?</h1>
        <div className="prepaid-card why-moneylink-body">
          <div className="cip-cards">
            <div className="cip-card-1">
              <div className="cip-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="42"
                  viewBox="0 0 36 42"
                  fill="none"
                >
                  <path
                    d="M28.8597 4.58008H28.7602H28.6607H28.5612H28.4617H28.3622L23.4865 6.66968C23.1879 6.76918 22.9889 6.96819 22.8894 7.2667C22.7899 7.56522 22.7899 7.76423 22.9889 8.06274C23.2874 8.56026 23.984 8.75927 24.581 8.56026L26.6706 7.66472C24.6805 11.1474 20.9988 14.8291 16.3221 17.7147C12.7399 20.0033 8.75976 21.6949 4.97858 22.5904C4.68007 22.6899 4.38156 22.7894 4.28205 23.0879C4.08304 23.2869 4.08304 23.5855 4.18255 23.884C4.38156 24.3815 4.97858 24.7795 5.67512 24.5805C9.75481 23.5855 13.934 21.7944 17.8147 19.4063C22.6904 16.3216 26.5711 12.5404 28.8597 8.75927L29.3572 10.3513C29.4567 10.6499 29.6557 10.8489 29.9543 10.9484C30.2528 11.0479 30.5513 11.1474 30.8498 11.0479C31.4468 10.8489 31.8448 10.3513 31.6458 9.75432L30.1533 5.37612C30.0538 5.0776 29.8547 4.77909 29.4567 4.58008H29.3572C29.0587 4.58008 29.0587 4.58008 28.8597 4.58008Z"
                    fill="#2C007D"
                  />
                  <path
                    d="M35.2339 17.6172H31.0547V41.0008H35.2339V17.6172Z"
                    stroke="#2C007D"
                    stroke-miterlimit="107.69"
                  />
                  <path
                    d="M26.8745 20.6035H22.6953V40.9025H26.8745V20.6035Z"
                    stroke="#2C007D"
                    stroke-miterlimit="107.69"
                  />
                  <path
                    d="M18.6167 24.2832H14.4375V41H18.6167V24.2832Z"
                    stroke="#2C007D"
                    stroke-miterlimit="107.69"
                  />
                  <path
                    d="M10.2495 28.2656H6.07031V40.9027H10.2495V28.2656Z"
                    stroke="#2C007D"
                    stroke-miterlimit="107.69"
                  />
                  <path
                    d="M8.66186 16.3237C12.8934 16.3237 16.3237 12.8934 16.3237 8.66186C16.3237 4.43033 12.8934 1 8.66186 1C4.43033 1 1 4.43033 1 8.66186C1 12.8934 4.43033 16.3237 8.66186 16.3237Z"
                    stroke="#FF5900"
                    stroke-miterlimit="107.69"
                  />
                  <path
                    d="M8.17188 4.58008V9.85382C8.17188 10.0528 8.37088 10.2518 8.56989 10.2518H12.6496"
                    stroke="#2C007D"
                    stroke-miterlimit="107.69"
                  />
                </svg>
                <h6>Faster Time to Market</h6>
                <p>
                  Launch your card program in days. MoneyLink is your partner
                  with end-to-end card program management.
                </p>
              </div>
              <div className="cip-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="44"
                  viewBox="0 0 60 44"
                  fill="none"
                >
                  <path
                    d="M57.6595 34.3555H17.0639C16.7819 34.3555 16.5 35.0966 16.5 35.8377V36.5789C16.5 37.32 16.7819 38.0612 17.0639 38.0612H57.6595C57.9414 38.0612 58.2233 37.32 58.2233 36.5789V35.0966C57.9414 35.0966 57.6595 34.3555 57.6595 34.3555Z"
                    stroke="#2C007D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.65538 26.666C4.4024 26.666 1 30.2961 1 34.8337C1 39.3713 4.4024 43.0014 8.65538 43.0014C12.9084 43.0014 16.3108 39.3713 16.3108 34.8337C16.3108 30.2961 12.9084 26.666 8.65538 26.666Z"
                    stroke="#FF5900"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.57994 12.1159H43.3355C43.6255 12.1159 43.9154 11.3747 43.9154 10.6336V9.89244C43.9154 9.15129 43.6255 8.41016 43.3355 8.41016H1.57994C1.28997 8.41016 1 9.15129 1 9.89244V11.3747C1 12.1159 1 12.1159 1.57994 12.1159Z"
                    stroke="#2C007D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M51.3428 17.3354C55.5959 17.3354 58.9983 13.7053 58.9983 9.1677C58.9983 4.6301 55.5959 1 51.3428 1C47.0899 1 43.6875 4.6301 43.6875 9.1677C43.6875 13.7053 47.0899 17.3354 51.3428 17.3354Z"
                    stroke="#FF5900"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <h6>Complete configuration & spend control</h6>
                <p>
                  Provide single-use, multi-use configurable cards for the
                  transaction category and control spend in the merchant
                  category.
                </p>
              </div>
              <div className="cip-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                >
                  <path
                    d="M18.9174 31.3853C17.0182 31.3853 15.2843 30.9725 13.633 30.2293C9.33939 28.2477 6.44948 23.9541 6.44948 19C6.44948 16.2752 7.35774 13.7981 8.76141 11.7339M23.5412 30.5596C22.1376 31.1376 20.5687 31.4678 18.9174 31.4678C17.0183 31.4678 15.2018 31.055 13.633 30.3119C9.42196 28.3304 6.44948 24.0366 6.44948 19.0825C6.44948 16.3578 7.27517 13.8807 8.76141 11.8165C10.9908 8.67888 14.7064 6.61466 18.9174 6.61466C22.7981 6.61466 26.266 8.43117 28.5779 11.2385C30.3118 13.3853 31.3852 16.11 31.3852 19.0825C31.3852 24.2844 28.0825 28.7431 23.5412 30.5596ZM23.5412 30.5596C28.1651 28.7431 31.3853 24.2844 31.3853 19C31.3853 16.0275 30.3119 13.3028 28.5779 11.1559C26.2661 8.34864 22.7982 6.53212 18.9174 6.53212M32.0458 6.69724C28.7431 3.22936 24.1192 1 18.9174 1C13.4679 1 8.59633 3.39449 5.29358 7.27523C2.5688 10.4128 1 14.5413 1 19C1 25.7706 4.71559 31.633 10.2477 34.688C12.8073 36.1743 15.8623 37 19 37C21.8899 37 24.6147 36.3394 27.0917 35.1009C32.9541 32.1284 37 26.1009 37 19.0825C36.8348 14.211 35.0183 9.91742 32.0458 6.69724Z"
                    stroke="#FF5900"
                    stroke-width="1.5"
                  />
                  <path
                    d="M8.84317 11.735C11.0725 8.59742 14.7881 6.5332 18.9991 6.5332C22.8798 6.5332 26.3477 8.34972 28.6597 11.157C30.3936 13.3038 31.467 16.0286 31.467 19.0011C31.467 24.2855 28.2469 28.7442 23.623 30.5607C22.2193 31.1387 20.6505 31.4689 18.9991 31.4689C17.1001 31.4689 15.2836 31.0561 13.7147 30.313C9.50373 28.3313 6.53125 24.0378 6.53125 19.0836"
                    stroke="#FF5900"
                    stroke-width="1.5"
                  />
                  <path
                    d="M18.9219 6.53157V1.08203"
                    stroke="#4D148C"
                    stroke-width="1.5"
                  />
                  <path
                    d="M18.9219 36.9163V31.4668"
                    stroke="#4D148C"
                    stroke-width="1.5"
                  />
                  <path d="M6.44954 19H1" stroke="#4D148C" stroke-width="1.5" />
                  <path
                    d="M36.8402 19H31.3906"
                    stroke="#4D148C"
                    stroke-width="1.5"
                  />
                </svg>
                <h6>Tokenization</h6>
                <p>
                  MoneyLink’s platform supports network branded tokenization
                  solutions with an in-house token generation platform to manage
                  the token life cycle.
                </p>
              </div>
              <div className="cip-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="43"
                  height="38"
                  viewBox="0 0 43 38"
                  fill="none"
                >
                  <path
                    d="M5.50461 20.4754C5.50461 19.9836 5.40625 19.4918 5.40625 19C5.40625 9.06557 13.4718 1 23.4062 1C33.3407 1 41.5046 9.06557 41.5046 19C41.5046 28.9344 33.439 37 23.5046 37"
                    stroke="#FF5900"
                    stroke-width="1.5"
                    stroke-miterlimit="107.69"
                  />
                  <path
                    d="M22.5234 8.37695V21.1638C22.5234 21.6556 22.9169 22.1474 23.507 22.1474H33.4415"
                    stroke="#FF5900"
                    stroke-width="1.5"
                    stroke-miterlimit="107.69"
                  />
                  <path
                    d="M20.7541 32.082H0.491803C0.196721 32.082 0 31.8852 0 31.5902V31.4918C0 31.1967 0.196721 31 0.491803 31H20.6557C20.9508 31 21.1475 31.1967 21.1475 31.4918V31.5902C21.1475 31.8852 20.9508 32.082 20.7541 32.082Z"
                    fill="#2C007D"
                  />
                  <path
                    d="M20.7503 26.5742H7.56993C7.27485 26.5742 7.07812 26.3774 7.07812 26.0824V25.984C7.07812 25.6889 7.27485 25.4922 7.56993 25.4922H20.6519C20.947 25.4922 21.1437 25.6889 21.1437 25.984V26.0824C21.1437 26.3774 20.947 26.5742 20.7503 26.5742Z"
                    fill="#2C007D"
                  />
                </svg>
                <h6>Just in time funding</h6>
                <p>Control and customize transactions in real time.</p>
              </div>
            </div>
            <div className="cip-card-2">
              <div className="cip-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="38"
                  viewBox="0 0 29 38"
                  fill="none"
                >
                  <path
                    d="M20.1484 1V6.50685C20.1484 7.41097 20.8881 8.0685 21.71 8.0685H27.2169"
                    stroke="#FF5900"
                    stroke-width="1.5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.9041 1H2.56165C1.65753 1 1 1.73973 1 2.56165V35.4384C1 36.3425 1.73973 37 2.56165 37H26.0685C26.9727 37 27.6302 36.2603 27.6302 35.4384V8.72603C27.6302 8.31508 27.4658 7.90412 27.137 7.65754L20.9726 1.49315C20.726 1.16438 20.3151 1 19.9041 1Z"
                    stroke="#FF5900"
                    stroke-width="1.5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.2929 21.6309L17.7587 25.0829L18.6628 29.8501L14.3066 27.5487L10.0327 29.8501L10.8546 25.0829L7.32031 21.6309L12.1697 20.9734L14.3066 16.6172L16.4436 20.9734L21.2929 21.6309Z"
                    stroke="#2C007D"
                    stroke-width="1.5"
                  />
                </svg>
                <h6>Compliant with Industry Standards</h6>
                <p>
                  We conform to the highest security standards of the payment
                  industry. Our cards are PCI DSS compliant with centralized
                  security updates, monitoring, patching, and remediation.
                </p>
              </div>
              <div className="cip-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                >
                  <path
                    d="M19 37C28.9411 37 37 28.9411 37 19C37 9.05888 28.9411 1 19 1C9.05888 1 1 9.05888 1 19C1 28.9411 9.05888 37 19 37Z"
                    stroke="#FF5900"
                    stroke-width="1.5"
                    stroke-miterlimit="107.69"
                  />
                  <path
                    d="M19.0019 31.5253C25.9195 31.5253 31.5273 25.9175 31.5273 19C31.5273 12.0824 25.9195 6.47461 19.0019 6.47461C12.0844 6.47461 6.47656 12.0824 6.47656 19C6.47656 25.9175 12.0844 31.5253 19.0019 31.5253Z"
                    stroke="#FF5900"
                    stroke-width="1.5"
                    stroke-miterlimit="107.69"
                  />
                  <path
                    d="M19.0001 25.719C22.7109 25.719 25.719 22.7109 25.719 19.0001C25.719 15.2894 22.7109 12.2812 19.0001 12.2812C15.2894 12.2812 12.2812 15.2894 12.2812 19.0001C12.2812 22.7109 15.2894 25.719 19.0001 25.719Z"
                    stroke="#4D148C"
                    stroke-width="1.5"
                    stroke-miterlimit="107.69"
                  />
                </svg>
                <h6>Better Brand Visibility</h6>
                <p>
                  We give you white-label cards with your branding and chosen
                  customization. Get more brand visibility and offer better
                  payment experiences.
                </p>
              </div>
              <div className="cip-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="39"
                  viewBox="0 0 40 39"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.46912 23.2344H7.13955C7.17036 23.2344 7.20086 23.2374 7.23107 23.2434C7.26128 23.2494 7.29062 23.2583 7.31908 23.2701C7.34754 23.2819 7.37457 23.2963 7.40018 23.3134C7.4258 23.3305 7.4495 23.35 7.47128 23.3718C7.49305 23.3936 7.5125 23.4173 7.52961 23.4429C7.54673 23.4685 7.56118 23.4955 7.57297 23.524C7.58476 23.5524 7.59365 23.5818 7.59966 23.612C7.60567 23.6422 7.60867 23.6727 7.60867 23.7035V35.9826C7.60867 36.0134 7.60567 36.0439 7.59966 36.0742C7.59365 36.1044 7.58476 36.1337 7.57297 36.1621C7.56118 36.1906 7.54673 36.2176 7.52961 36.2433C7.5125 36.2689 7.49305 36.2925 7.47128 36.3144C7.4495 36.3361 7.4258 36.3555 7.40018 36.3726C7.37457 36.3898 7.34754 36.4042 7.31908 36.416C7.29062 36.4278 7.26128 36.4367 7.23107 36.4427C7.20086 36.4487 7.17036 36.4517 7.13955 36.4517H1.46912C1.43832 36.4517 1.40781 36.4487 1.3776 36.4427C1.34739 36.4367 1.31806 36.4278 1.2896 36.416C1.26114 36.4042 1.2341 36.3898 1.20849 36.3726C1.18288 36.3555 1.15918 36.3361 1.1374 36.3144C1.11562 36.2925 1.09617 36.2689 1.07906 36.2433C1.06195 36.2176 1.0475 36.1906 1.03571 36.1621C1.02392 36.1337 1.01502 36.1044 1.00901 36.0742C1.003 36.0439 1 36.0134 1 35.9826V23.7035C1 23.6727 1.003 23.6422 1.00901 23.612C1.01502 23.5818 1.02392 23.5524 1.03571 23.524C1.0475 23.4955 1.06195 23.4685 1.07906 23.4429C1.09617 23.4173 1.11562 23.3936 1.1374 23.3718C1.15918 23.35 1.18288 23.3305 1.20849 23.3134C1.2341 23.2963 1.26114 23.2819 1.2896 23.2701C1.31806 23.2583 1.34739 23.2494 1.3776 23.2434C1.40781 23.2374 1.43832 23.2344 1.46912 23.2344Z"
                    stroke="#2C007D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.60938 34.077C24.9572 39.8595 19.1746 39.8595 39.0006 29.9466C37.8596 28.4093 35.8793 27.7492 34.0441 28.2944L26.9117 30.5529"
                    stroke="#2C007D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.60938 24.8867H12.5658C16.4535 24.8867 19.1746 28.191 20.0006 28.191H25.7833C27.4354 28.191 27.4354 31.4954 25.7833 31.4954H16.6963"
                    stroke="#2C007D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M34.8307 18.3652L29.6077 15.9278C29.2596 15.9278 28.9114 15.9278 28.9114 15.9278L24.0366 18.3652L24.733 12.7941C24.733 12.4458 24.733 12.0976 24.3849 12.0976L20.5547 8.2675L26.1258 7.22293C26.474 7.22293 26.474 6.87472 26.8222 6.87472L29.2596 2L32.0451 6.87472C32.0451 7.22293 32.3933 7.22293 32.7415 7.22293L37.9644 8.2675L34.1343 12.4458C33.7861 12.7941 33.7861 12.7941 33.7861 13.1423L34.8307 18.3652Z"
                    fill="white"
                    stroke="#F15C24"
                    stroke-width="1.5"
                  />
                </svg>
                <h6>Compliant with Industry Standards</h6>
                <p>
                  Go the extra mile! Give your customers access to rewards and
                  loyalty programs, personalized to their interests
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
