import React from "react";

function YU11() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="461"
      height="239"
      fill="none"
      viewBox="0 0 461 239"
    >
      <path
        className='yu-path'
        stroke="url(#paint0_linear_3806_10705)"
        strokeWidth="1.5"
        d="M0 60l190.5.001c4 0 13.4 1.4 13 15v17c0 4.333 2.4 13 12 13L356 105c6.5 0 10 5.5 10 13.5V219c-.333 6.167 2.3 18.5 15.5 18.5H438c7.333.667 22-1 22-13V116c.167-29.667.4-92.5 0-102.5S447.5 1 441.5 1h-226c-7.2 0-11 6.333-12 9.5v23c0 12.4-8.667 15.5-13 15.5H0"
        pathLength="1"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_3806_10705"
          x1="230.11"
          x2="230.11"
          y1="1"
          y2="168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B66FF"></stop>
          <stop offset="1" stopColor="#13ECE2"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default YU11;
