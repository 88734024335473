import React from "react";
import logo from "../../../assets/images/montralogo.png";
import supplychain from "../../../assets/images/supplychain.svg";
import supplychainimg from "../../../assets/images/supplychainimg.svg";
import montralogosupplychain from "../../../assets/images/montralogosupplychain.svg";


const Main = () => {
  return (
    <>
      <div className="supplychain">
        <div className="row">
          <div className="col">
            <h6>Solutions</h6>
            <img src={montralogosupplychain} alt="image" />
            <h3>Streamline B2B Payouts With Dedicated</h3>
            <h2>Supply Chain & Payment Processing Solution</h2>
            <p>
              Unlock powerful supply chain payment capabilities on a fully
              digital platform that enables 
            </p>
            <ul>
              <li>Collection of B2B payments digitally</li>
              <li>Collection of Credit based sales</li>
              <li>Tracking of  Due & Collected payments</li>
              <li>
                Launching & managing digital sales promotions for Primary,
                Secondary as well as Tertiary sales
              </li>
              <li>
                Distribution analytics thereby  helping in assessing SKU level
                stock availability in the outlets under direct distribution as
                well those where Montra App is being used for selling the SKUs
                to outlets under indirect distribution
              </li>
            </ul>
            <button>Explore Montra Payment Gateway <i class="bi bi-chevron-right"></i></button>
            <img className="supplychain-lastimg" src={supplychainimg} alt="image" />
          </div>
          <div className="col supplychain-right">
            <img src={supplychain} alt="image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
