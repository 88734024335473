import React from "react";

function YU7() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="330"
      height="161"
      fill="none"
      viewBox="0 0 330 161"
    >
      <path
        className='yu-path'
        stroke="url(#paint0_radial_3450_10409)"
        strokeWidth="1.5"
        d="M1 161V21.5C1.5 14.667 7.2 1 26 1h304"
        pathLength="1"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_3450_10409"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-137.291 98.585 8.146) scale(159.226 327.409)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7600"></stop>
          <stop offset="1" stopColor="#635BFF"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU7;
