import React from "react";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
import chathero1 from "../../../assets/images/chathero1.svg";
import chathero2 from "../../../assets/images/chathero2.svg";

const Hero = () => {
  return (
    <>
      <div className="chat-hero">
        <div className="row">
          <div className="col finance-hero-left">
            <h1>
              <span>Free and secure calls and messages</span> to anyone,
              anywhere
            </h1>
            <p>
              Simple, reliable, private messaging and calling for free*. Montra
              Chat comes with CHAT PAY feature to enable you to make payments or
              request payments while you are chatting with someone
            </p>
            <img className="finance-hero-img" src={chathero2} alt="" />
            <div className="app-download">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
          </div>
          <div className="col finance-hero-right">
            <img src={chathero1} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
