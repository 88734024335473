import React from "react";
import Navbar from "../../Navbar/Businessnav";
import Footer from "../../Footer/Productfooter";
import "./style.css";
import Hero from "./Hero";
import Body from "./Body";

const Main = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Body />
      <Footer />
    </>
  );
};

export default Main;
